import React, { useState, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import {
	Button,
	ButtonGroup,
	IconButton,
	Dialog,
	DialogActions,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TextField,
	Checkbox,
	withStyles,
} from '@material-ui/core';
import {
	Settings as SettingsIcon,
	RemoveRounded as AlarmIcon,
	DoneRounded as NoAlarmIcon,
	CheckCircleOutlined as ConfirmedIcon,
	ErrorOutline as NotConfirmedIcon,
	CheckCircle as SmallConfirmedIcon,
	RemoveCircle as SmallNotConfirmedIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { format as formatDate } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as colors from '../colors';
import { PAGES, PROPERTY_TABS } from '../constants';
import { drawTruncatedStr, getFormattedNumber, isValueInsideThresholds } from '../utility-functions';
import { STORE, getStateVariables } from '../redux/selectors';
import { setSensors, setSensorGroups, setDeviations, updateSensors, updateSensorGroups, updateDeviations } from '../redux/actionCreators';
import SelectionTable from './SelectionTable';
import DialogWrapper from './DialogWrapper';
import { SensorGraph } from './SensorGraph';
import i18n from '../i18n';

const GET_DIGITALTWIN_TAGS = gql`
	query ($filter: DigitalTwinTagFilter!) {
		getDigitalTwinTags(filter: $filter) {
			digitaltwintagid
			label
			description
			mediaurl
			sensorids
			sensorgroupids
			deviationids
			positionx
			positiony
			positionz
			normalx
			normaly
			normalz
		}
	}
`;
const ADD_DIGITALTWIN_PERSPECTIVE = gql`
	mutation ($digitaltwinid: ID!, $sweep: String!, $rotationx: Float!, $rotationy: Float!, $zoom: Float!) {
		addDigitalTwinPerspective(digitaltwinid: $digitaltwinid, sweep: $sweep, rotationx: $rotationx, rotationy: $rotationy, zoom: $zoom) {
			digitaltwinperspectiveid
		}
	}
`;
const ADD_DIGITALTWIN_TAG = gql`
	mutation (
		$digitaltwinid: ID!
		$label: String
		$description: String
		$mediaurl: String
		$sensorids: [Int!]
		$sensorgroupids: [Int!]
		$deviationids: [Int!]
		$positionx: Float!
		$positiony: Float!
		$positionz: Float!
		$normalx: Float!
		$normaly: Float!
		$normalz: Float!
	) {
		addDigitalTwinTag(
			digitaltwinid: $digitaltwinid
			label: $label
			description: $description
			mediaurl: $mediaurl
			sensorids: $sensorids
			sensorgroupids: $sensorgroupids
			deviationids: $deviationids
			positionx: $positionx
			positiony: $positiony
			positionz: $positionz
			normalx: $normalx
			normaly: $normaly
			normalz: $normalz
		) {
			digitaltwintagid
			label
			description
			mediaurl
			sensorids
			sensorgroupids
			deviationids
			positionx
			positiony
			positionz
			normalx
			normaly
			normalz
		}
	}
`;
const SET_DIGITALTWIN_TAGS = gql`
	mutation (
		$digitaltwintagids: [ID!]!
		$label: String
		$description: String
		$mediaurl: String
		$sensorids: [Int!]
		$sensorgroupids: [Int!]
		$deviationids: [Int!]
	) {
		setDigitalTwinTags(
			digitaltwintagids: $digitaltwintagids
			label: $label
			description: $description
			mediaurl: $mediaurl
			sensorids: $sensorids
			sensorgroupids: $sensorgroupids
			deviationids: $deviationids
		) {
			digitaltwintagid
			label
			description
			mediaurl
			sensorids
			sensorgroupids
			deviationids
		}
	}
`;
const REMOVE_DIGITALTWIN_TAGS = gql`
	mutation ($digitaltwintagids: [ID!]!) {
		removeDigitalTwinTags(digitaltwintagids: $digitaltwintagids) {
			digitaltwintagid
		}
	}
`;
const SET_SENSORS = gql`
	mutation ($sensorids: [ID!]!, $digitaltwintagid: Int, $digitaltwinperspectiveid: Int) {
		setSensors(sensorids: $sensorids, digitaltwintagid: $digitaltwintagid, digitaltwinperspectiveid: $digitaltwinperspectiveid) {
			sensorid
			digitaltwintagid
			digitaltwinperspectiveid
		}
	}
`;
const SET_SENSORGROUPS = gql`
	mutation ($sensorgroupids: [ID!]!, $digitaltwintagid: Int, $digitaltwinperspectiveid: Int) {
		setSensorGroups(
			sensorgroupids: $sensorgroupids
			digitaltwintagid: $digitaltwintagid
			digitaltwinperspectiveid: $digitaltwinperspectiveid
		) {
			sensorgroupid
			digitaltwintagid
			digitaltwinperspectiveid
		}
	}
`;
const SET_DEVIATIONS = gql`
	mutation ($deviationids: [ID!]!, $digitaltwintagid: Int, $digitaltwinperspectiveid: Int) {
		setDeviations(
			deviationids: $deviationids
			digitaltwintagid: $digitaltwintagid
			digitaltwinperspectiveid: $digitaltwinperspectiveid
		) {
			deviationid
			digitaltwintagid
			digitaltwinperspectiveid
		}
	}
`;

const POSITION_SELECTION_TIME_MS = 1500;
const POSITION_SELECTION_DEADZONE = 5;
const MP_IFRAME_ID = 'mp-iframe';
const DIGITALTWIN_TYPE = Object.freeze({ tag: 1, perspective: 2 });
const SETTINGS = {
	show: { id: 'show' },
	sensors: { id: 'sensors', show: 'sensors_show' },
	sensorGroups: { id: 'sensorGroups', show: 'sensorGroups_show' },
	deviations: { id: 'deviations', show: 'deviations_show' },
	category: { id: 'category', createInfoPoint: 1, editInfoPoint: 2, removeInfoPoint: 3, createFlyIn: 4 },
	positionSelection: {
		isSelecting: 'positionSelectionIsSelecting',
		timeoutId: 'positionSelectionTimeoutId',
		subscription: 'positionSelectionSubscription',
		pollCount: 'positionSelectionPollCount',
	},
	flyInType: {
		id: 'flyInType',
		perspective: 1,
		tag: 2,
	},
	textInput: {
		label: 'textInputLabel',
		description: 'textInputDescription',
		mediaUrl: 'textInputMediaUrl',
	},
};

function deviationSort(a, b) {
	return (!a.dateconfirmed && b.dateconfirmed) ||
		(!a.dateconfirmed && !b.dateconfirmed && a.datecreated > b.datecreated) ||
		(a.dateconfirmed && b.dateconfirmed && a.dateconfirmed > b.dateconfirmed)
		? -1
		: 1;
}

function getMediaType(_url, sdk) {
	const url = _url?.toLowerCase();
	if (!url) return sdk.Mattertag.MediaType.NONE;
	else if (url.endsWith('.png') || url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.gif'))
		return sdk.Mattertag.MediaType.PHOTO;
	else if (url.includes('youtube.com/') || url.includes('youtu.be/') || url.includes('vimeo.com/')) return sdk.Mattertag.MediaType.VIDEO;
	else return sdk.Mattertag.MediaType.RICH;
}

const StyledAccordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(Accordion);
const StyledAccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		fontSize: '135%',
		fontWeight: 400,
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(AccordionSummary);

/**
 * Creates tags inside the digital-twin
 * @param {object} tags : Info about tag to add
 * @param {object} sdk : SDK control-object
 * @returns {Promise<object[]>} : Promise of `tags` with SIDs added
 */
async function createTagsInDigitalTwin(tags, sdk) {
	return Promise.all(
		(tags || []).map(async tag => ({
			...tag,
			sid: (
				await sdk.Mattertag.add([
					{
						// SDK throws error when `null` is used
						label: tag.label || undefined,
						description: tag.description || undefined,
						media: { src: tag.mediaurl || undefined, type: getMediaType(tag.mediaurl, sdk) },
						anchorPosition: { x: tag.positionx, y: tag.positiony, z: tag.positionz },
						stemVector: { x: tag.normalx * 0.3, y: tag.normaly * 0.3, z: tag.normalz * 0.3 },
						color: {
							r: 0.2,
							g: 0.27,
							b: 0.49,
						},
					},
				])
			)[0],
		}))
	);
}

function _injectHtmlIntoTags(
	tags,
	sdk,
	sensors,
	sensorGroups,
	deviations,
	onSensorClick,
	onDeviationClick,
	currentTab,
	injectEmpty = false
) {
	for (const tag of tags) {
		if (!tag.sensorids?.length && !tag.sensorgroupids?.length && !tag.deviationids?.length)
			if (injectEmpty) sdk.Mattertag.injectHTML(tag.sid, '<div />');
			else continue;

		const tagSensors = sensors.filter(sen => tag.sensorids?.includes(Number(sen.sensorid)));
		const tagGroups = sensorGroups
			.filter(grp => tag.sensorgroupids?.includes(Number(grp.sensorgroupid)))
			.map(grp => {
				const sens = sensors.filter(sen => sen.sensorgroupid === Number(grp.sensorgroupid));
				return {
					...grp,
					sensors: sens,
					hasActiveAlarm: sens.some(sen => !isValueInsideThresholds(sen)),
					sensorAverage:
						sens[0]?.unit && sens.every(sen => sen.unit === sens[0].unit)
							? getFormattedNumber(sens.reduce((sum, cur) => sum + cur.value, 0) / sens.length) + ' ' + (sens[0].unit || '')
							: undefined,
				};
			});
		const tagDeviations = deviations.filter(dev => tag.deviationids?.includes(Number(dev.deviationid)));

		const alarmIcon = renderToString(<AlarmIcon className='groupIcon' />);
		const noAlarmIcon = renderToString(<NoAlarmIcon className='groupIcon' />);
		const confirmedIcon = renderToString(<ConfirmedIcon className='deviationIcon deviationIconConfirmed' />);
		const notConfirmedIcon = renderToString(<NotConfirmedIcon className='deviationIcon deviationIconNotConfirmed' />);
		const html = `
			<div>
				${tagGroups
					.map(
						grp =>
							`<div class="groupContainer">
									<div class="groupIconBox ${grp.hasActiveAlarm ? 'groupIconBoxAlarm' : 'groupIconBoxNormal'}">
										<div class="groupIcon">
											${grp.hasActiveAlarm ? alarmIcon : noAlarmIcon}
										</div>
									</div>
									<div class="groupLabelBox">
										<div class="groupLabel">${grp.name}</div>
										<div class="groupUpdated">
											Uppdaterad: ${formatDate(
												grp.sensors.reduce((max, cur) => Math.max(max, cur.timestamp), new Date('2020')),
												'yyyy-MM-dd HH:mm'
											)}
										</div>
									</div>
									<div class="groupInfoBox">
										<div class="groupInfoTextColumn">
												${i18n.t('threeSixtyView.average')}<br />${grp.sensorAverage ? i18n.t('threeSixtyView.average') : ''}
										</div>
										<div class="groupInfoValueColumn">
												${grp.sensors.length}<br />${grp.sensorAverage ? grp.sensorAverage : ''}
										</div>
									</div>
								</div>
							</div>`
					)
					.join('')}
			</div>
			<div id="sensors">
				${tagSensors
					.map(
						sen =>
							`<div class="sensorContainer">
								<div class="sensorValueBox ${isValueInsideThresholds(sen) ? 'sensorValueBoxNormal' : 'sensorValueBoxAlarm'}" sensorid="${sen.sensorid}">
									<div class="sensorValueWrapper">
										<div class="sensorValue">${typeof sen.value === 'number' ? getFormattedNumber(sen.value) : '-'}</div>
										<div class="sensorUnit">${sen.unit || ''}</div>
									</div>
								</div>
								<div>
									<h3 class="sensorLabel">${sen.name}</h3>
									<div class="sensorUpdated">${i18n.t('threeSixtyView.updated')} ${formatDate(sen.timestamp, 'yyyy-MM-dd HH:mm')}</div>
									<div class="sensorThresholds">${i18n.t('threeSixtyView.thresholds')} ${sen.lowerthreshold || '-'} / ${sen.upperthreshold || '-'}</div>
								</div>
							</div>`
					)
					.join('')}
			</div>
			<div>
				${tagDeviations
					.sort(deviationSort)
					.map(
						dev =>
							`<div class="deviationContainer" deviationid="${dev.deviationid}">
								<div class="deviationIconBox">
									${dev.dateconfirmed ? confirmedIcon : notConfirmedIcon}
								</div>
								<div class="deviationInfoBox">
									<div class="deviationTitle">${dev.title}</div>
									<div class="deviationText">${dev.description}</div>
								</div>
								<div class="deviationTextBox">
									<div class="deviationText">${i18n.t('threeSixtyView.created')} ${formatDate(dev.datecreated, 'MMM dd HH:mm')}</div>
									<div class="deviationText">${
										dev.dateconfirmed
											? `${i18n.t('threeSixtyView.acknowledged')} ${formatDate(dev.dateconfirmed, 'MMM dd HH:mm')}`
											: ''
									}</div>
								</div>
							</div>`
					)
					.join('')}
			</div>

			<script>
				const sensors = document.getElementsByClassName("sensorValueBox");
				for (const sen of sensors)
					sen.addEventListener("click", () => window.send("sensorClick", sen.getAttribute("sensorid")));
				const deviations = document.getElementsByClassName("deviationContainer");
				for (const dev of deviations)
					dev.addEventListener("click", () => window.send("deviationClick", dev.getAttribute("deviationid")));
			</script>

			<style>
				body {
					margin: 0.4rem 0 0;
					font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
				}
				#sensors {
					display: flex;
					flex-wrap: wrap;
				}
				.sensorContainer {
					display: grid;
					grid-template-columns: 3.5rem auto;
					position: relative;
					margin: 0 0.6rem 0.6rem 0;
					width: 13rem;
					height: 3.5rem;
					background: #fff;
					border-radius: 0.25rem;
					box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
				}
				.sensorValueBox {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 0.25rem 0px 0px 0.25rem;
					cursor: pointer;
				}
				.sensorValueBoxNormal {
					background-image: linear-gradient(150deg, rgba(105, 186, 58, 1) 0%, rgba(56, 145, 18, 1) 100%);
				}
				.sensorValueBoxAlarm {
					background-image: linear-gradient(150deg, rgb(255, 74, 81) 0%, rgb(181, 0, 3) 100%);
				}
				.sensorValueWrapper {
					color: #fffd;
					font-weight: 500;
					filter: drop-shadow(rgba(0, 0, 0, 0.4) 0px 0px 4px);
					text-align: center;
				}
				.sensorValue {
					font-size: 120%;
				}
				.sensorUnit {
					font-size: 75%;
					opacity: 0.7;
					margin: -0.15rem 0px 0px 0.1rem;
				}
				.sensorLabel {
					margin: 0.35rem 0px 0px 0.6rem;
					font-size: 75%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 8.5rem;
				}
				.sensorUpdated {
					margin: 0.0rem 0px 0px 0.6rem;
					color: #0008;
					font-size: 62%;
				}
				.sensorThresholds {
					margin: 0.1rem 0px 0px 0.6rem;
					color: #0008;
					font-size: 62%;
				}
				.groupContainer {
					background: #fff;
					position: relative;
					display: grid;
					grid-template-columns: 5rem 44% auto;
					width: 26.6rem;
					height: 3.5rem;
					margin-bottom: 0.6rem;
					border-radius: 0.25rem;
					box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
				}
				.groupIconBox {
					border-radius: 0.25rem 0px 0px 0.25rem;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.groupIconBoxNormal {
					background-image: linear-gradient(150deg, rgb(105, 186, 58) 0%, rgb(56, 145, 18) 100%);
				}
				.groupIconBoxAlarm {
					background-image: linear-gradient(150deg, rgb(255, 74, 81) 0%, rgb(181, 0, 3) 100%);
				}
				.groupIcon {
					transform: scale(1.5);
					color: #fffc;
					filter: drop-shadow(rgba(0, 0, 0, 0.4) 0px 0px 4px);
					fill: currentColor;
					width: 1rem;
					margin-top: 0.12rem;
				}
				.groupLabelBox {
					margin: 0.4rem 0px 0px 0.9rem;
				}
				.groupLabel {
					font-weight: 500;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 0;
				}
				.groupUpdated {
					margin-top: 0.1rem;
					color: #0008;
					font-size: 75%;
				}
				.groupInfoBox {
					display: grid;
					grid-template-columns: 5rem auto;
					margin: 0.4rem 0px 0px 1.2rem;
					color: #0008;
					font-size: 75%;
					line-height: 1.3rem;
				}
				.groupInfoTextColumn {
					color: #666;
				}
				.groupInfoValueColumn {
					width: max-content;
					color: #444;
				}
				.deviationContainer {
					cursor: ${currentTab !== PROPERTY_TABS.deviations.id ? 'pointer' : 'initial'};
					background: #fff;
					display: grid;
					grid-template-columns: 5rem 12.5rem auto;
					width: 26.6rem;
					height: 3.5rem;
					margin-bottom: 0.6rem;
					border-radius: 0.25rem;
					box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
				}
				.deviationIconBox {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.deviationIcon {
					transform: scale(2);
					filter: drop-shadow(#0005 0 0 4px);
					fill: currentColor;
					width: 1rem;
				}
				.deviationIconConfirmed {
					color: ${colors.success}cc
				}
				.deviationIconNotConfirmed {
					color: ${colors.failure}cc
				}
				.deviationInfoBox {
					margin: 0.42rem 0 0 0.2rem;
				}
				.deviationTitle {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.deviationText {
					margin-top: 0.1rem;
					color: #0008;
					font-size: 75%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.deviationTextBox {
					margin: 0.7rem 0 0 0.5rem;
				}
			</style>
		`;

		sdk.Mattertag.injectHTML(tag.sid, html, {
			size: {
				w: tagSensors.length === 1 && !tagGroups.length && !tagDeviations.length ? 218 : 436,
				h: 6.4 + Math.min(7, Math.ceil(tagSensors.length / 2) + tagGroups.length + tagDeviations.length) * 65.6,
			},
		}).then(messenger => {
			messenger.on('sensorClick', sensorid => onSensorClick(sensors.find(sen => sen.sensorid === sensorid) || {}));
			messenger.on('deviationClick', deviationid => onDeviationClick(deviations.find(dev => dev.deviationid === deviationid) || {}));
		});
	}
}

/**
 * Renders a 360-model with an optional settings-panel
 * @param {object} digitalTwin : Contains info about a digital-twin
 * @param {{tagId: number, perspective: object}} selection : Info about what tag or perspective should be focused when the 360-view is loaded
 */
function ThreeSixtyView(props) {
	const [sdk, setSdk] = useState();
	const [tags, setTags] = useState([]);
	const [settings, setSettings] = useState({});
	const [selectedTagId, setSelectedTagId] = useState(); // Cannot be merged into `settings` because of disjoint states in callbacks
	const previousSelectedTagId = useRef([]).current; // -||-
	const [tmpTagId, setTmpTagId] = useState(); // -||-
	const [selectedPoint, setSelectedPoint] = useState(); // -||-
	const [showSensorGraph, setShowSensorGraph] = useState(false);
	const [selectedSensor, setSelectedSensor] = useState();
	const objectQueue = useRef([]).current;
	const inputRefs = useRef({
		[SETTINGS.category.createInfoPoint]: {
			label: React.createRef(),
			description: React.createRef(),
			mediaUrl: React.createRef(),
			addFlyInCheckbox: React.createRef(),
		},
		[SETTINGS.category.editInfoPoint]: {
			label: React.createRef(),
			description: React.createRef(),
			mediaUrl: React.createRef(),
			addFlyInCheckbox: React.createRef(),
		},
	}).current;

	useQuery(GET_DIGITALTWIN_TAGS, {
		skip: !sdk,
		variables: { filter: { digitaltwinids: [props.digitalTwin.digitaltwinid] } },
		onCompleted: ({ getDigitalTwinTags }) =>
			createTagsInDigitalTwin(getDigitalTwinTags, sdk).then(newTags => {
				setTags([...tags, ...newTags]);
				injectHtmlIntoTags(newTags);
				// Move towards selected fly-in tag
				if (props.selection?.tagId)
					sdk.Mattertag.navigateToTag(
						newTags.find(tag => tag.digitaltwintagid === props.selection.tagId)?.sid,
						sdk.Mattertag.Transition.FLY
					);
			}),
		fetchPolicy: 'no-cache', // Required for refetches when the component is loaded a second time
	});
	const [addDigitalTwinTag] = useMutation(ADD_DIGITALTWIN_TAG, {
		onCompleted: ({ addDigitalTwinTag }) => {
			assignObjects(DIGITALTWIN_TYPE.tag, addDigitalTwinTag?.digitaltwintagid);
			createTagsInDigitalTwin([addDigitalTwinTag], sdk).then(([tag]) => {
				setTags([...tags, tag]);
				injectHtmlIntoTags([tag]);
				sdk.Mattertag.navigateToTag(tag.sid, sdk.Mattertag.Transition.FLY);
			});
		},
	});
	const [addDigitalTwinPerspective] = useMutation(ADD_DIGITALTWIN_PERSPECTIVE, {
		onCompleted: ({ addDigitalTwinPerspective }) =>
			assignObjects(DIGITALTWIN_TYPE.perspective, addDigitalTwinPerspective?.digitaltwinperspectiveid),
	});
	const [setDigitalTwinTags] = useMutation(SET_DIGITALTWIN_TAGS, {
		onCompleted: ({ setDigitalTwinTags }) => {
			assignObjects(DIGITALTWIN_TYPE.tag, setDigitalTwinTags?.[0]?.digitaltwintagid);
			const tag = tags.find(tag => tag.digitaltwintagid === setDigitalTwinTags?.[0]?.digitaltwintagid);
			if (tag) {
				for (const key in setDigitalTwinTags[0]) tag[key] = setDigitalTwinTags[0][key];
				resetTag(tag);
			}
		},
	});
	const [setSensors] = useMutation(SET_SENSORS, {
		onCompleted: ({ setSensors }) => props.updateSensors(setSensors),
	});
	const [setSensorGroups] = useMutation(SET_SENSORGROUPS, {
		onCompleted: ({ setSensorGroups }) => props.updateSensorGroups(setSensorGroups),
	});
	const [setDeviations] = useMutation(SET_DEVIATIONS, {
		onCompleted: ({ setDeviations }) => props.updateDeviations(setDeviations),
	});
	const [removeDigitalTwinTags] = useMutation(REMOVE_DIGITALTWIN_TAGS, {
		onCompleted: ({ removeDigitalTwinTags }) =>
			sdk.Mattertag.remove(tags.find(tag => tag.digitaltwintagid === removeDigitalTwinTags?.[0]?.digitaltwintagid)?.sid),
	});
	const routerHistory = useHistory();

	const tmpSettings = {};

	const { t } = useTranslation();

	useEffect(() => {
		initSdk();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (sdk) {
			sdk.Mattertag.remove(tmpTagId).catch(() => null);
			// De-select any potentially selected tag to force user to re-select tag after changing category
			if (settings[SETTINGS.category.id]) for (const tag of tags) sdk.Mattertag.preventAction(tag.sid, {});
		}
		setSettings({
			[SETTINGS.show.id]: settings[SETTINGS.show.id],
			[SETTINGS.category.id]: settings[SETTINGS.category.id],
		});
		setSelectedTagId();
		setTmpTagId();
		setSelectedPoint();
		if (inputRefs[SETTINGS.category.createInfoPoint].label.current)
			inputRefs[SETTINGS.category.createInfoPoint].label.current.value = '';
		if (inputRefs[SETTINGS.category.createInfoPoint].description.current)
			inputRefs[SETTINGS.category.createInfoPoint].description.current.value = '';
		if (inputRefs[SETTINGS.category.createInfoPoint].mediaUrl.current)
			inputRefs[SETTINGS.category.createInfoPoint].mediaUrl.current.value = '';
		if (inputRefs[SETTINGS.category.editInfoPoint].label.current) inputRefs[SETTINGS.category.editInfoPoint].label.current.value = '';
		if (inputRefs[SETTINGS.category.editInfoPoint].description.current)
			inputRefs[SETTINGS.category.editInfoPoint].description.current.value = '';
		if (inputRefs[SETTINGS.category.editInfoPoint].mediaUrl.current)
			inputRefs[SETTINGS.category.editInfoPoint].mediaUrl.current.value = '';
		// eslint-disable-next-line
	}, [settings[SETTINGS.category.id]]);

	useEffect(() => {
		if (selectedTagId && selectedTagId !== previousSelectedTagId[0]) {
			resetTag(tags.find(tag => tag.sid === previousSelectedTagId[0]));
			previousSelectedTagId[0] = selectedTagId;
		}

		if (settings[SETTINGS.category.id] === SETTINGS.category.editInfoPoint) {
			const tag = tags.find(tag => tag.sid === selectedTagId);
			if (!tag) return;

			if (inputRefs[SETTINGS.category.editInfoPoint].label.current)
				inputRefs[SETTINGS.category.editInfoPoint].label.current.value = tag.label || '';
			if (inputRefs[SETTINGS.category.editInfoPoint].description.current)
				inputRefs[SETTINGS.category.editInfoPoint].description.current.value = tag.description || '';
			if (inputRefs[SETTINGS.category.editInfoPoint].mediaUrl.current)
				inputRefs[SETTINGS.category.editInfoPoint].mediaUrl.current.value = tag.mediaurl || '';
			setSettings({
				...settings,
				[SETTINGS.sensors.id]: props.sensors.filter(sen => tag.sensorids?.includes(Number(sen.sensorid))),
				[SETTINGS.sensorGroups.id]: props.sensorGroups.filter(grp => tag.sensorgroupids?.includes(Number(grp.sensorgroupid))),
				[SETTINGS.deviations.id]: props.deviations.filter(dev => tag.deviationids?.includes(Number(dev.deviationid))),
			});
		}
		// eslint-disable-next-line
	}, [selectedTagId]);

	useEffect(() => {
		if (settings[SETTINGS.positionSelection.subscription]) settings[SETTINGS.positionSelection.subscription].cancel();
		if (settings[SETTINGS.positionSelection.isSelecting])
			setSettings({
				...settings,
				[SETTINGS.positionSelection.subscription]: sdk.Pointer.intersection.subscribe(int => {
					tmpSettings[SETTINGS.positionSelection.pollCount] = (tmpSettings[SETTINGS.positionSelection.pollCount] || 0) + 1;
					if (tmpSettings[SETTINGS.positionSelection.pollCount] <= POSITION_SELECTION_DEADZONE) return;

					clearTimeout(tmpSettings[SETTINGS.positionSelection.timeoutId]);
					tmpSettings[SETTINGS.positionSelection.timeoutId] = setTimeout(() => setSelectedPoint(int), POSITION_SELECTION_TIME_MS);
				}),
			});
		// eslint-disable-next-line
	}, [settings[SETTINGS.positionSelection.isSelecting]]);

	useEffect(() => {
		if (selectedPoint && settings[SETTINGS.positionSelection.isSelecting]) {
			sdk.Mattertag.remove(tmpTagId).catch(() => null);
			sdk.Mattertag.add([
				{
					label: settings[SETTINGS.textInput.label],
					description: settings[SETTINGS.textInput.description],
					media: { src: settings[SETTINGS.textInput.mediaUrl], type: getMediaType(settings[SETTINGS.textInput.mediaUrl], sdk) },
					anchorPosition: selectedPoint.position,
					stemVector: { x: selectedPoint.normal.x * 0.3, y: selectedPoint.normal.y * 0.3, z: selectedPoint.normal.z * 0.3 },
					color: {
						r: 0.5,
						g: 0.5,
						b: 0.5,
					},
				},
			]).then(([newSid]) => {
				sdk.Mattertag.navigateToTag(newSid, sdk.Mattertag.Transition.FLY);
				setSelectedTagId(newSid);
				setTmpTagId(newSid);
				setSettings({
					...settings,
					[SETTINGS.positionSelection.isSelecting]: false,
				});
			});
		}
		// eslint-disable-next-line
	}, [selectedPoint]);

	function initSdk() {
		const iframe = document.getElementById(MP_IFRAME_ID);
		if (iframe)
			iframe.addEventListener(
				'load',
				() =>
					window.MP_SDK.connect(iframe, process.env.REACT_APP_MATTERPORT_SDK_KEY, '3.5')
						.then(sdk => {
							setSdk(sdk);
							sdk.on(sdk.Mattertag.Event.CLICK, tagSid => setSelectedTagId(tagSid));
							// Remove all preexisting tags
							sdk.Mattertag.getData().then(tags => sdk.Mattertag.remove(tags.map(tag => tag.sid)));
							// Move towards selected perspective fly-in position
							if (props.selection?.perspective)
								sdk.Sweep.moveTo(props.selection.perspective.sweep, {
									rotation: { x: props.selection.perspective.rotationx, y: props.selection.perspective.rotationy },
									transition: sdk.Sweep.Transition.FLY,
									transitionTime: 2000,
								}).then(() => {
									// Smooth zooming
									const finalZoom = props.selection.perspective.zoom;
									function getZoomSpeed(currentZoom) {
										const halfTotalZoom = (finalZoom - 1) / 2;
										const halfZoomPoint = 1 + halfTotalZoom;
										const halfPointOffset = Math.abs(halfZoomPoint - currentZoom) / halfTotalZoom;
										return 0.005 + 0.05 * (1 - Math.pow(halfPointOffset, 2));
									}
									function zoom(currentZoomLevel, zoomIn) {
										// Timeout reduces stutter
										setTimeout(
											() =>
												sdk.Camera.zoomBy((zoomIn ? 1 : -1) * getZoomSpeed(currentZoomLevel)).then(zoomLevel => {
													// Stop zooming if user is zooming in the opposite direction
													if (
														(zoomIn && zoomLevel < currentZoomLevel) ||
														(!zoomIn && zoomLevel > currentZoomLevel)
													)
														return;
													else if ((zoomIn && zoomLevel < finalZoom) || (!zoomIn && zoomLevel > finalZoom))
														zoom(zoomLevel, zoomIn);
												}),
											30
										);
									}
									const sub = sdk.Camera.zoom.subscribe(z => {
										sub.cancel();
										if (z.level !== finalZoom) zoom(z.level, z.level < finalZoom);
									});
								});
						})
						.catch(e => console.error('Failed to connect with Matterport SDK:', e)),
				true
			);
		else setTimeout(() => initSdk(), 50);
	}
	function assignObjects(digitalTwinType, id) {
		if (!objectQueue.length) return;
		const { sensors, sensorGroups, deviations } = objectQueue.shift();
		for (const sen of sensors || [])
			setSensors({
				variables: {
					sensorids: sen.sensorid,
					[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwintagid' : 'digitaltwinperspectiveid']: Number(id),
					[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwinperspectiveid' : 'digitaltwintagid']:
						sen[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwinperspectiveid' : 'digitaltwintagid'] === null
							? undefined
							: null,
				},
			});
		for (const grp of sensorGroups || [])
			setSensorGroups({
				variables: {
					sensorgroupids: grp.sensorgroupid,
					[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwintagid' : 'digitaltwinperspectiveid']: Number(id),
					[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwinperspectiveid' : 'digitaltwintagid']:
						grp[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwinperspectiveid' : 'digitaltwintagid'] === null
							? undefined
							: null,
				},
			});
		for (const dev of deviations || [])
			setDeviations({
				variables: {
					deviationids: dev.deviationid,
					[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwintagid' : 'digitaltwinperspectiveid']: Number(id),
					[digitalTwinType === DIGITALTWIN_TYPE.tag ? 'digitaltwinperspectiveid' : 'digitaltwintagid']: null,
				},
			});
	}
	function injectHtmlIntoTags(tags, injectEmpty = false) {
		return _injectHtmlIntoTags(
			tags,
			sdk,
			props.sensors,
			props.sensorGroups,
			props.deviations,
			sensor => {
				setSelectedSensor(sensor);
				setShowSensorGraph(true);
			},
			dev =>
				props.currentTab !== PROPERTY_TABS.deviations.id &&
				routerHistory.push(`/${PAGES.properties.id}/${dev.locationid}/${PROPERTY_TABS.deviations.id}`),
			props.currentTab,
			injectEmpty
		);
	}
	function updateSelectedTagHtml(sensors, sensorgroups, deviations, specifiedTag = undefined, reselectTag = true) {
		sdk.Mattertag.getData().then(tags => {
			const tag = specifiedTag || tags.find(tag => tag.sid === selectedTagId);
			if (!tag) return;
			injectHtmlIntoTags(
				[
					{
						...tag,
						sensorids: sensors?.map(sen => Number(sen.sensorid)) || tag.sensorids,
						sensorgroupids: sensorgroups?.map(grp => Number(grp.sensorgroupid)) || tag.sensorgroupids,
						deviationids: deviations?.map(dev => Number(dev.deviationid)) || tag.deviationids,
					},
				],
				true
			);
			if (reselectTag) {
				sdk.Mattertag.preventAction(tag.sid, {});
				setTimeout(() => sdk.Mattertag.navigateToTag(tag.sid, sdk.Mattertag.Transition.FLY), 1100); // Timeout required for HTML-changes to show up
			}
		});
	}
	function resetTag(tag) {
		if (!tag) return;
		sdk.Mattertag.editBillboard(tag.sid, {
			label: tag.label || '',
			description: tag.description || '',
			media: {
				type: getMediaType(tag.mediaurl, sdk),
				src: tag.mediaurl || '',
			},
		});
		updateSelectedTagHtml(undefined, undefined, undefined, tag, false);
	}

	const tagInputFieldsRendition = category => (
		<>
			<TextField
				label={t('threeSixtyView.title')}
				placeholder='...'
				multiline
				onChange={({ target }) => {
					sdk.Mattertag.editBillboard(selectedTagId, {
						label: target.value,
					});
					setSettings({ ...settings, [SETTINGS.textInput.label]: target.value });
				}}
				inputRef={inputRefs[category].label}
				InputLabelProps={{
					shrink: true,
				}}
				style={{ marginTop: '1rem' }}
			/>
			<TextField
				label={t('threeSixtyView.textContents')}
				placeholder='...'
				multiline
				onChange={({ target }) => {
					sdk.Mattertag.editBillboard(selectedTagId, {
						description: target.value,
					});
					setSettings({ ...settings, [SETTINGS.textInput.description]: target.value });
				}}
				inputRef={inputRefs[category].description}
				InputLabelProps={{
					shrink: true,
				}}
				style={{ marginTop: '1rem' }}
			/>
			<TextField
				label={t('threeSixtyView.mediaLink')}
				placeholder='...'
				multiline
				onChange={({ target }) => {
					sdk.Mattertag.editBillboard(selectedTagId, {
						media: {
							src: target.value,
							type: getMediaType(target.value, sdk),
						},
					});
					setSettings({ ...settings, [SETTINGS.textInput.mediaUrl]: target.value });
				}}
				inputRef={inputRefs[category].mediaUrl}
				InputLabelProps={{
					shrink: true,
				}}
				style={{ marginTop: '1rem' }}
			/>
		</>
	);
	const getSensorAndGroupSelectionsRendition = (flyInCheckboxDefaultVal, category) => (
		<>
			<div style={{ display: 'grid', gridTemplateColumns: '13rem 1rem', marginTop: '1rem' }}>
				{(settings[SETTINGS.sensors.id] || []).length}{' '}
				{t('threeSixtyView.selectedSensor_any', { count: (settings[SETTINGS.sensors.id] || []).length })}
				<Button
					onClick={() => setSettings({ ...settings, [SETTINGS.sensors.show]: true })}
					variant='outlined'
					size='small'
					color='colors.text'
					style={{ left: '0.8rem', top: '-0.07rem', padding: '0' }}
				>
					{t('generic.select')}
				</Button>
			</div>
			<div style={{ display: 'grid', gridTemplateColumns: '13rem 1rem', marginTop: '0.2rem' }}>
				{(settings[SETTINGS.sensorGroups.id] || []).length}{' '}
				{t('threeSixtyView.selectedSensorGroup_any', { count: (settings[SETTINGS.sensorGroups.id] || []).length })}
				<Button
					onClick={() => setSettings({ ...settings, [SETTINGS.sensorGroups.show]: true })}
					variant='outlined'
					size='small'
					color='colors.text'
					style={{ left: '0.8rem', top: '-0.07rem', padding: '0' }}
				>
					{t('generic.select')}
				</Button>
			</div>
			<div style={{ display: 'grid', gridTemplateColumns: '13rem 1rem', marginTop: '0.2rem' }}>
				{(settings[SETTINGS.deviations.id] || []).length}{' '}
				{t('threeSixtyView.selectedDeviation_any', { count: (settings[SETTINGS.deviations.id] || []).length })}
				<Button
					onClick={() => setSettings({ ...settings, [SETTINGS.deviations.show]: true })}
					variant='outlined'
					size='small'
					color='colors.text'
					style={{ left: '0.8rem', top: '-0.07rem', padding: '0' }}
				>
					{t('generic.select')}
				</Button>
			</div>
			{flyInCheckboxDefaultVal !== undefined && (
				<div style={{ display: 'flex', fontSize: '92%', color: '#000d', alignItems: 'center', marginTop: '0.2rem' }}>
					<Checkbox
						inputRef={inputRefs[category].addFlyInCheckbox}
						defaultChecked={flyInCheckboxDefaultVal}
						color='colors.text'
						disabled={
							!settings[SETTINGS.sensors.id]?.length &&
							!settings[SETTINGS.sensorGroups.id]?.length &&
							!settings[SETTINGS.deviations.id]?.length
						}
						style={{ padding: '0', left: '-3px' }}
					/>
					{t('threeSixtyView.createFlyIn')}
				</div>
			)}
		</>
	);
	const infopointSelectionRendition = (
		<>
			<h3 style={{ fontWeight: 400, marginTop: '0.2rem' }}>{t('threeSixtyView.infoPoint')}:</h3>
			<div style={{ fontSize: '95%' }}>
				{selectedTagId
					? (() => {
							const tag = tags.find(tag => tag.sid === selectedTagId) || {};
							return drawTruncatedStr(
								tag.label || tag.description || tag.mediaUrl || t('threeSixtyView.namelessInfoPoint'),
								40
							);
						})()
					: t('threeSixtyView.selectByClick3dView')}
			</div>
		</>
	);

	return (
		<>
			<div style={{ display: 'flex', height: '42rem' }}>
				<div
					style={{
						width: `calc(60rem - ${settings[SETTINGS.show.id] ? '21rem' : '0rem'})`,
						height: 'inherit',
						position: 'relative',
					}}
				>
					<iframe
						title='360'
						frameBorder='0'
						allowFullScreen={true}
						src={
							'https://my.matterport.com/show/?m=' +
							props.digitalTwin.model +
							'&play=1&brand=0&f=0&qs=' +
							(props.selection ? 1 : 0) +
							'&applicationKey=' +
							process.env.REACT_APP_MATTERPORT_SDK_KEY
						}
						id={MP_IFRAME_ID}
						style={{
							width: 'inherit',
							height: 'inherit',
							boxShadow: '0 0 1rem #000',
							borderRadius: '0.25rem',
						}}
					/>

					{/* Settings button */}
					<div style={{ position: 'absolute', top: '0', right: '0', background: '#00000073', borderTopRightRadius: '0.29rem' }}>
						<IconButton
							onClick={() => setSettings({ [SETTINGS.show.id]: !settings[SETTINGS.show.id] })}
							style={{ color: '#fffd' }}
						>
							<SettingsIcon />
						</IconButton>
					</div>

					{/* Position selection guide */}
					<div
						style={{
							visibility: settings[SETTINGS.positionSelection.isSelecting] ? 'visible' : 'hidden',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -500%)',
							background: '#fff',
							borderRadius: '0.25rem',
							whiteSpace: 'nowrap',
							padding: '0.6rem 0.8rem',
							boxShadow: '0 0 1rem #000',
						}}
					>
						{t('threeSixtyView.holdMouseStillToSelect')}
					</div>
				</div>

				{settings[SETTINGS.show.id] && (
					<div
						style={{
							width: '20rem',
							marginLeft: '1rem',
							background: '#fff',
							boxShadow: '0 0 1rem #000',
							borderRadius: '0.25rem',
							overflow: 'hidden',
						}}
					>
						{/* Add datapoint */}
						<StyledAccordion
							square
							expanded={settings[SETTINGS.category.id] === SETTINGS.category.createInfoPoint}
							onChange={(e, open) =>
								setSettings({
									...settings,
									[SETTINGS.category.id]: open ? SETTINGS.category.createInfoPoint : undefined,
								})
							}
						>
							<StyledAccordionSummary>{t('threeSixtyView.addInfoPoint')}</StyledAccordionSummary>
							<AccordionDetails style={{ display: 'grid' }}>
								<Button
									onClick={() => {
										setSettings({
											...settings,
											[SETTINGS.positionSelection.isSelecting]: !settings[SETTINGS.positionSelection.isSelecting],
										});
										sdk.Camera.rotate(0.001, 0.001, { speed: Number.MAX_VALUE });
									}}
									variant='outlined'
									size='small'
									color='colors.text'
									style={{ marginTop: '0.5rem' }}
								>
									{settings[SETTINGS.positionSelection.isSelecting]
										? t('generic.cancel')
										: selectedPoint
											? t('threeSixtyView.pickAnotherPosition')
											: t('threeSixtyView.choosePosition')}
								</Button>
								{tagInputFieldsRendition(SETTINGS.category.createInfoPoint)}
								{getSensorAndGroupSelectionsRendition(true, SETTINGS.category.createInfoPoint)}
								<Button
									onClick={() => {
										if (inputRefs[SETTINGS.category.createInfoPoint].addFlyInCheckbox.current?.checked)
											objectQueue.push({
												sensors: settings[SETTINGS.sensors.id],
												sensorGroups: settings[SETTINGS.sensorGroups.id],
												deviations: settings[SETTINGS.deviations.id],
											});
										addDigitalTwinTag({
											variables: {
												digitaltwinid: props.digitalTwin.digitaltwinid,
												label: settings[SETTINGS.textInput.label],
												description: settings[SETTINGS.textInput.description],
												mediaurl: settings[SETTINGS.textInput.mediaUrl],
												sensorids: settings[SETTINGS.sensors.id]?.map(sen => Number(sen.sensorid)),
												sensorgroupids: settings[SETTINGS.sensorGroups.id]?.map(grp => Number(grp.sensorgroupid)),
												deviationids: settings[SETTINGS.deviations.id]?.map(dev => Number(dev.deviationid)),
												positionx: selectedPoint.position.x,
												positiony: selectedPoint.position.y,
												positionz: selectedPoint.position.z,
												normalx: selectedPoint.normal.x,
												normaly: selectedPoint.normal.y,
												normalz: selectedPoint.normal.z,
											},
										});
										setSettings({ ...settings, [SETTINGS.category.id]: undefined });
										sdk.Mattertag.remove(selectedTagId).catch(() => null);
									}}
									disabled={
										!selectedPoint ||
										(!settings[SETTINGS.textInput.label] &&
											!settings[SETTINGS.textInput.description] &&
											!settings[SETTINGS.textInput.mediaUrl] &&
											!settings[SETTINGS.sensors.id]?.length &&
											!settings[SETTINGS.sensorGroups.id]?.length &&
											!settings[SETTINGS.deviations.id]?.length)
									}
									variant='outlined'
									color='colors.text'
									style={{ marginTop: '1rem', height: '2.2rem' }}
								>
									{t('generic.save')}
								</Button>
							</AccordionDetails>
						</StyledAccordion>

						{/* Update datapoint */}
						<StyledAccordion
							square
							expanded={settings[SETTINGS.category.id] === SETTINGS.category.editInfoPoint}
							onChange={(e, open) =>
								setSettings({
									...settings,
									[SETTINGS.category.id]: open ? SETTINGS.category.editInfoPoint : undefined,
								})
							}
						>
							<StyledAccordionSummary>{t('threeSixtyView.editInfoPoint')}</StyledAccordionSummary>
							<AccordionDetails style={{ display: 'grid' }}>
								{infopointSelectionRendition}
								{tagInputFieldsRendition(SETTINGS.category.editInfoPoint)}
								{getSensorAndGroupSelectionsRendition(false, SETTINGS.category.editInfoPoint)}
								<Button
									onClick={() => {
										const tag = tags.find(tag => tag.sid === selectedTagId);
										if (tag) {
											if (inputRefs[SETTINGS.category.editInfoPoint].addFlyInCheckbox.current?.checked)
												objectQueue.push({
													sensors: settings[SETTINGS.sensors.id],
													sensorGroups: settings[SETTINGS.sensorGroups.id],
													deviations: settings[SETTINGS.deviations.id],
												});
											setDigitalTwinTags({
												variables: {
													digitaltwintagids: [tag.digitaltwintagid],
													label: settings[SETTINGS.textInput.label],
													description: settings[SETTINGS.textInput.description],
													mediaurl: settings[SETTINGS.textInput.mediaUrl],
													sensorids:
														JSON.stringify(
															settings[SETTINGS.sensors.id]?.map(sen => Number(sen.sensorid)) || []
														) === JSON.stringify(tag.sensorids || [])
															? undefined
															: settings[SETTINGS.sensors.id]?.map(sen => Number(sen.sensorid)),
													sensorgroupids:
														JSON.stringify(
															settings[SETTINGS.sensorGroups.id]?.map(grp => Number(grp.sensorgroupid)) || []
														) === JSON.stringify(tag.sensorgroupids || [])
															? undefined
															: settings[SETTINGS.sensorGroups.id]?.map(grp => Number(grp.sensorgroupid)),
													deviationids:
														JSON.stringify(
															settings[SETTINGS.deviations.id]?.map(dev => Number(dev.deviationid)) || []
														) === JSON.stringify(tag.deviationids || [])
															? undefined
															: settings[SETTINGS.deviations.id]?.map(dev => Number(dev.deviationid)),
												},
											});
										}
										setSettings({ ...settings, [SETTINGS.category.id]: undefined });
									}}
									disabled={!selectedTagId}
									variant='outlined'
									color='colors.text'
									style={{ marginTop: '1rem', height: '2.2rem' }}
								>
									{t('generic.save')}
								</Button>
							</AccordionDetails>
						</StyledAccordion>

						{/* Remove datapoint */}
						<StyledAccordion
							square
							expanded={settings[SETTINGS.category.id] === SETTINGS.category.removeInfoPoint}
							onChange={(e, open) =>
								setSettings({
									...settings,
									[SETTINGS.category.id]: open ? SETTINGS.category.removeInfoPoint : undefined,
								})
							}
						>
							<StyledAccordionSummary>{t('threeSixtyView.deleteInfoPoint')}</StyledAccordionSummary>
							<AccordionDetails style={{ display: 'grid' }}>
								{infopointSelectionRendition}
								<Button
									onClick={() => {
										const tag = tags.find(tag => tag.sid === selectedTagId);
										if (tag)
											removeDigitalTwinTags({
												variables: {
													digitaltwintagids: [tag.digitaltwintagid],
												},
											});
										setSettings({ ...settings, [SETTINGS.category.id]: undefined });
									}}
									disabled={!selectedTagId}
									variant='outlined'
									color='colors.text'
									style={{ marginTop: '1rem', height: '2.2rem' }}
								>
									{t('threeSixtyView.deleteInfoPoint')}
								</Button>
							</AccordionDetails>
						</StyledAccordion>

						{/* Save fly-in position */}
						<StyledAccordion
							square
							expanded={settings[SETTINGS.category.id] === SETTINGS.category.createFlyIn}
							onChange={(e, open) =>
								setSettings({
									...settings,
									[SETTINGS.category.id]: open ? SETTINGS.category.createFlyIn : undefined,
								})
							}
						>
							<StyledAccordionSummary>{t('threeSixtyView.saveFlyInPos')}</StyledAccordionSummary>
							<AccordionDetails style={{ display: 'grid' }}>
								<ButtonGroup color='colors.text' style={{ margin: '0.6rem 0' }}>
									<Button
										variant={
											settings[SETTINGS.flyInType.id] === SETTINGS.flyInType.perspective ? 'contained' : 'outlined'
										}
										onClick={() =>
											setSettings({ ...settings, [SETTINGS.flyInType.id]: SETTINGS.flyInType.perspective })
										}
									>
										{t('threeSixtyView.cameraPos')}
									</Button>
									<Button
										variant={settings[SETTINGS.flyInType.id] === SETTINGS.flyInType.tag ? 'contained' : 'outlined'}
										onClick={() => setSettings({ ...settings, [SETTINGS.flyInType.id]: SETTINGS.flyInType.tag })}
									>
										{t('threeSixtyView.infoPoint')}
									</Button>
								</ButtonGroup>

								{settings[SETTINGS.flyInType.id] && (
									<>
										{settings[SETTINGS.flyInType.id] === SETTINGS.flyInType.tag && infopointSelectionRendition}
										{getSensorAndGroupSelectionsRendition()}
										<Button
											onClick={() => {
												objectQueue.push({
													sensors: settings[SETTINGS.sensors.id],
													sensorGroups: settings[SETTINGS.sensorGroups.id],
													deviations: settings[SETTINGS.deviations.id],
												});
												if (settings[SETTINGS.flyInType.id] === SETTINGS.flyInType.perspective) {
													Promise.all([sdk.Camera.getPose(), sdk.Camera.zoomBy(0)]).then(poseAndZoom =>
														addDigitalTwinPerspective({
															variables: {
																digitaltwinid: props.digitalTwin.digitaltwinid,
																sweep: poseAndZoom[0].sweep,
																rotationx: poseAndZoom[0].rotation.x,
																rotationy: poseAndZoom[0].rotation.y,
																zoom: poseAndZoom[1],
															},
														})
													);
												} else {
													const tag = tags.find(tag => tag.sid === selectedTagId);
													if (tag) assignObjects(DIGITALTWIN_TYPE.tag, tag.digitaltwintagid);
													else objectQueue.pop();
												}
												setSettings({ [SETTINGS.category.id]: undefined });
											}}
											disabled={
												(!settings[SETTINGS.sensors.id]?.length &&
													!settings[SETTINGS.sensorGroups.id]?.length &&
													!settings[SETTINGS.deviations.id]?.length) ||
												(settings[SETTINGS.flyInType.id] === SETTINGS.flyInType.tag && !selectedTagId)
											}
											variant='outlined'
											color='colors.text'
											style={{ marginTop: '1rem', height: '2.2rem' }}
										>
											{t('generic.save')}
										</Button>
									</>
								)}
							</AccordionDetails>
						</StyledAccordion>

						{/* Sensor selection popup */}
						<Dialog
							open={Boolean(settings[SETTINGS.sensors.show])}
							onClose={() => setSettings({ ...settings, [SETTINGS.sensors.show]: false })}
							maxWidth={false}
						>
							<SelectionTable
								localization={{
									title: t('threeSixtyView.selectSensors'),
									nRowsSelected: t('threeSixtyView.numberOfselectedSensors') + '{0}',
								}} // TODO: Find a better way to do this.
								data={props.sensors
									.filter(sen => sen.locationid === props.currentProperty)
									.map(sen => ({
										...sen,
										sensorGroupName:
											props.sensorGroups.find(grp => Number(grp.sensorgroupid) === sen.sensorgroupid)?.name || '',
									}))}
								dataId='sensorid'
								preselectedIds={settings[SETTINGS.sensors.id]?.map(sen => sen.sensorid)}
								onSelectionChange={sens => (tmpSettings[SETTINGS.sensors.id] = sens)}
								columns={[
									{ title: t('generic.name'), field: 'name' },
									{
										title: t('generic.unit'),
										field: 'unit',
									},
									{ title: t('threeSixtyView.group'), field: 'sensorGroupName' },
								]}
								style={{ minWidth: '40rem', boxShadow: 'none' }}
								tableProps={{ maxColumnLength: '28' }}
							/>

							<DialogActions>
								<Button color='colors.text' onClick={() => setSettings({ ...settings, [SETTINGS.sensors.show]: false })}>
									{t('generic.cancel')}
								</Button>
								<Button
									color='colors.text'
									onClick={() => {
										setSettings({
											...settings,
											[SETTINGS.sensors.show]: false,
											[tmpSettings[SETTINGS.sensors.id] && SETTINGS.sensors.id]: tmpSettings[SETTINGS.sensors.id],
										});
										updateSelectedTagHtml(
											tmpSettings[SETTINGS.sensors.id],
											settings[SETTINGS.sensorGroups.id],
											settings[SETTINGS.deviations.id]
										);
									}}
								>
									{t('generic.confirm')}
								</Button>
							</DialogActions>
						</Dialog>

						{/* SensorGroup selection popup */}
						<Dialog
							open={Boolean(settings[SETTINGS.sensorGroups.show])}
							onClose={() => setSettings({ ...settings, [SETTINGS.sensorGroups.show]: false })}
							maxWidth={false}
						>
							<SelectionTable
								localization={{
									title: t('threeSixtyView.selectSensorGroups'),
									nRowsSelected: t('threeSixtyView.numberOfSelectedSensorGroups') + '{0}',
								}}
								data={props.sensorGroups
									.filter(grp => grp.locationid === props.currentProperty)
									.map(grp => ({
										...grp,
										sensorCount: props.sensors.filter(sen => sen.sensorgroupid === Number(grp.sensorgroupid)).length,
									}))}
								dataId='sensorgroupid'
								preselectedIds={settings[SETTINGS.sensorGroups.id]?.map(grp => grp.sensorgroupid)}
								onSelectionChange={grps => (tmpSettings[SETTINGS.sensorGroups.id] = grps)}
								columns={[
									{ title: t('generic.name'), field: 'name' },
									{ title: t('threeSixtyView.sensorCount'), field: 'sensorCount' },
								]}
								style={{ minWidth: '40rem', boxShadow: 'none' }}
								tableProps={{ maxColumnLength: '28' }}
							/>

							<DialogActions>
								<Button
									color='colors.text'
									onClick={() => setSettings({ ...settings, [SETTINGS.sensorGroups.show]: false })}
								>
									{t('generic.cancel')}
								</Button>
								<Button
									color='colors.text'
									onClick={() => {
										setSettings({
											...settings,
											[SETTINGS.sensorGroups.show]: false,
											[tmpSettings[SETTINGS.sensorGroups.id] && SETTINGS.sensorGroups.id]:
												tmpSettings[SETTINGS.sensorGroups.id],
										});
										updateSelectedTagHtml(
											settings[SETTINGS.sensors.id],
											tmpSettings[SETTINGS.sensorGroups.id],
											settings[SETTINGS.deviations.id]
										);
									}}
								>
									{t('generic.confirm')}
								</Button>
							</DialogActions>
						</Dialog>

						{/* Deviation selection popup */}
						<Dialog
							open={Boolean(settings[SETTINGS.deviations.show])}
							onClose={() => setSettings({ ...settings, [SETTINGS.deviations.show]: false })}
							maxWidth={false}
						>
							<SelectionTable
								localization={{
									title: t('threeSixtyView.selectDeviations'),
									nRowsSelected: t('threeSixtyView.numberOfSelectedDeviations') + '{0}',
								}}
								data={props.deviations
									.filter(dev => dev.locationid === String(props.currentProperty))
									.sort(deviationSort)
									.map(dev => ({
										...dev,
										datecreated: dev.datecreated && formatDate(dev.datecreated, 'MMM dd HH:mm'),
										dateconfirmed: dev.dateconfirmed && formatDate(dev.dateconfirmed, 'MMM dd HH:mm'),
									}))}
								dataId='deviationid'
								preselectedIds={settings[SETTINGS.deviations.id]?.map(dev => dev.deviationid)}
								onSelectionChange={devs => (tmpSettings[SETTINGS.deviations.id] = devs)}
								columns={[
									{
										title: t('threeSixtyView.status'),
										render: row =>
											row.dateconfirmed ? (
												<SmallConfirmedIcon style={{ color: colors.success, padding: '0.1rem 0 0' }} />
											) : (
												<SmallNotConfirmedIcon style={{ color: colors.failure, padding: '0.1rem 0 0' }} />
											),
										customSort: deviationSort,
									},
									{ title: t('generic.label'), field: 'title' },
									{ title: t('generic.description'), field: 'description' },
									{ title: t('generic.created'), field: 'datecreated' },
									{ title: t('generic.acknowledged_one'), field: 'dateconfirmed' },
								]}
								style={{ minWidth: '40rem', boxShadow: 'none' }}
								tableProps={{ maxColumnLength: '44' }}
							/>

							<DialogActions>
								<Button color='colors.text' onClick={() => setSettings({ ...settings, [SETTINGS.deviations.show]: false })}>
									{t('generic.cancel')}
								</Button>
								<Button
									color='colors.text'
									onClick={() => {
										setSettings({
											...settings,
											[SETTINGS.deviations.show]: false,
											[tmpSettings[SETTINGS.deviations.id] && SETTINGS.deviations.id]:
												tmpSettings[SETTINGS.deviations.id],
										});
										updateSelectedTagHtml(
											settings[SETTINGS.sensors.id],
											settings[SETTINGS.sensorGroups.id],
											tmpSettings[SETTINGS.deviations.id]
										);
									}}
								>
									{t('generic.confirm')}
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				)}
			</div>

			<DialogWrapper
				dialogProps={{
					open: showSensorGraph,
					onClose: () => setShowSensorGraph(false),
				}}
			>
				<SensorGraph sensorInfo={selectedSensor} showTitle isVisible={showSensorGraph} />
			</DialogWrapper>
		</>
	);
}

export default connect(getStateVariables(STORE.sensors, STORE.sensorGroups, STORE.deviations, STORE.currentProperty, STORE.currentTab), {
	setSensors,
	setSensorGroups,
	setDeviations,
	updateSensors,
	updateSensorGroups,
	updateDeviations,
})(ThreeSixtyView);
