import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Dialog, IconButton, Backdrop, LinearProgress } from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { STORE, getStateVariables } from '../redux/selectors';
import { updateCameras } from '../redux/actionCreators';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';

const SET_CAMERAS = gql`
	mutation ($cameraids: [ID!]!, $locationid: ID, $label: String, $rtspaddress: String) {
		setCameras(cameraids: $cameraids, locationid: $locationid, label: $label, rtspaddress: $rtspaddress) {
			cameraid
			locationid
			label
			rtspaddress
		}
	}
`;
const ADD_CAMERA = gql`
	mutation ($locationid: ID, $label: String!, $rtspaddress: String!) {
		addCamera(locationid: $locationid, label: $label, rtspaddress: $rtspaddress) {
			cameraid
			locationid
			label
			rtspaddress
		}
	}
`;

const POPUP_TYPES = Object.freeze({ add: 1, edit: 2 });
const DISCRETE_CLICK_ID = 'discrete-click';

/**
 * A table for cameras that allows them to be filtered and bulk-edited
 */
function CameraAdministration(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [selectedCameras, setSelectedCameras] = useState([]);
	const [camerasUnderEdit, setCamerasUnderEdit] = useState([]);
	const [editPopupType, setEditPopupType] = useState();
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [showCamera, setShowCamera] = useState(false);
	const [shownCamera, setShownCamera] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [clearSelectionsTrigger, setClearSelectionsTrigger] = useState(0);

	const { t } = useTranslation();

	const [setCameras] = useMutation(SET_CAMERAS, {
		onCompleted: ({ setCameras }) => {
			props.updateCameras(setCameras);
			// Call the function to clear the selections
			triggerClearSelections();
		},
	});
	const [addCamera] = useMutation(ADD_CAMERA, {
		onCompleted: ({ addCamera }) => {
			Swal.fire(t('userAdmin.success'), t('cameraAdmin.cameraAdded'), 'success');
			props.updateCameras([addCamera])
		},
	});

	const filteredCameras = selectedProperties.length
		? props.cameras.filter(cam => selectedProperties.some(pro => pro.value === Number(cam.locationid)))
		: props.cameras;
	const propertyOptions = [
		...props.properties.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` })),
		{ value: null, label: t('generic.noPropOption') },
	];

	// loading cameras
	useEffect(() => {
		if (props.cameras.length) setIsLoading(false);
	}, [props.cameras]);

	// eslint-disable-next-line
	useEffect(() => shownCamera && setShowCamera(true), [shownCamera]);

	// Function to trigger clearing of selections
	const  triggerClearSelections = () => setClearSelectionsTrigger(prev => prev + 1); 
	return (
		<>
			<div style={{ margin: '1rem 0 0 1rem' }}>
				<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
					<SelectionTable
						localization={{ title: t('generic.camera_other'), nRowsSelected: t('cameraAdmin.tableSelTitle') }}
						data={filteredCameras
							.map(cam => ({
								...cam,
								propertyLabel: propertyOptions.find(pro => pro.value && pro.value === Number(cam.locationid))?.label || '',
							}))
							.sort((a, b) =>
								a.propertyLabel < b.propertyLabel || (a.propertyLabel === b.propertyLabel && a.label < b.label) ? -1 : 1
							)}
						dataId='cameraid'
						onSelectionChange={cams => setSelectedCameras(cams)}
						/* onRowClick={(cam, e) => {
							if (e._dispatchInstances.some(dispatch => dispatch.stateNode?.id === DISCRETE_CLICK_ID)) return;

							setCamerasUnderEdit([cam]);
							setEditPopupType(POPUP_TYPES.edit);
							setShowEditPopup(!showEditPopup);
						}} */
					clearSelectionsTrigger={clearSelectionsTrigger}
						columns={[
							{
								title: t('generic.name'),
								field: 'label',
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.label}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setCameras({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													cameraids: [rowData.cameraid],
													label: e.target.value,
												},
											});
										}}
									/>
								),
							},
							{
								title: t('generic.property'),
								field: 'propertyLabel',
							},
							{
								title: t('generic.address'),
								field: 'rtspaddress',
								maxLength: 25,
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.rtspaddress}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setCameras({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													cameraids: [rowData.cameraid],
													rtspaddress: e.target.value,
												},
											});
										}}
									/>
								),
							},
							{
								title: '',
								align: 'right',
								//sorting: false,
								render: row => (
									<IconButton onClick={() => setShownCamera(row)} style={{ padding: '0.3rem' }} id={DISCRETE_CLICK_ID}>
										<ShowIcon style={{ width: '1.2rem', height: '1.2rem', color: '#666' }} />
									</IconButton>
								),
							},
						]}
						tableProps={{ maxColumnLength: 60 }}
					/>

					<SelectionSidebar
						buttons={[
							{
								label: t('cameraAdmin.addCamera'),
								onClick: () => {
									setEditPopupType(POPUP_TYPES.add);
									setShowEditPopup(!showEditPopup);
								},
							},
							{
								label: t('genericAdmin.editSelected'),
								onClick: () => {
									setCamerasUnderEdit(selectedCameras);
									setEditPopupType(POPUP_TYPES.edit);
									setShowEditPopup(!showEditPopup);
								},
								disabled: !selectedCameras.length,
							},
							/* {
								label: t('genericAdmin.deleteSelected'),
								onClick: () => null,
								disabled: !selectedCameras.length,
							}, */
						]}
						filters={propertyOptions}
						updateSelectedFilters={props => setSelectedProperties(props || [])}
						localization={{
							filterTitle: `${t('cameraAdmin.filterCameras')}:`,
							filterPlaceholder: t('genericAdmin.selectPropsPrompt'),
						}}
						style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
					/>
				</div>

				<EditPopup
					text={
						editPopupType === POPUP_TYPES.edit
							? {
									title:
										camerasUnderEdit.length === 1
											? t('cameraAdmin.editCamera') + (camerasUnderEdit[0].label || t('genericAdmin.withoutName'))
											: t('generic.edit') + ' ' + camerasUnderEdit.length + t('cameraAdmin.selectedCameras'),
									subtitle:
										t('genericAdmin.selectValuesFor') +
										' ' +
										t('cameraAdmin.theSelectedCamera', { count: camerasUnderEdit.length }), // Check that this is the correct way to do it.
								}
							: {
									title: t('cameraAdmin.addCamera'),
									subtitle: t('cameraAdmin.addNewValuesToSelectedCamera'),
								}
					}
					fields={
						editPopupType === POPUP_TYPES.edit
							? [
									{
										id: 'label',
										label: t('generic.name'),
										placeholder: camerasUnderEdit[0].label ? camerasUnderEdit[0].label : '...',
										disabled: camerasUnderEdit.length > 1,
									},
									{
										id: 'locationid',
										label: t('generic.property'),
										placeholder:
											camerasUnderEdit.length === 1 && camerasUnderEdit[0].locationid
												? propertyOptions.find(pro => pro.value === Number(camerasUnderEdit[0].locationid))?.label
												: '...',
										options: propertyOptions,
									},
									{
										id: 'rtspaddress',
										label: t('cameraAdmin.RTSPAddress'),
										placeholder: camerasUnderEdit[0].rtspaddress,
										disabled: camerasUnderEdit.length > 1,
									},
								]
							: [
									{
										id: 'label',
										label: t('generic.name'),
										required: true,
									},
									{
										id: 'locationid',
										label: t('generic.property'),
										options: propertyOptions,
									},
									{
										id: 'rtspaddress',
										label: t('cameraAdmin.RTSPAddress'),
										required: true,
									},
								]
					}
					isOpen={showEditPopup}
					onClose={() => setShowEditPopup(!showEditPopup)}
					onSave={opts => {
						if (editPopupType === POPUP_TYPES.edit) {
							setCameras({
								variables: {
									cameraids: camerasUnderEdit.map(cam => cam.cameraid),
									locationid: opts.locationid,
									label: opts.label,
									rtspaddress: opts.rtspaddress,
								},
							});
							Swal.fire(t('userAdmin.success'), t('cameraAdmin.cameraInfo'), 'success');
						} else {
							addCamera({
								variables: {
									locationid: opts.locationid,
									label: opts.label,
									rtspaddress: opts.rtspaddress,
								},
							});
						}
						setShowEditPopup(!showEditPopup);
					}}
				/>
			</div>

			<Dialog
				open={showCamera}
				onClose={() => setShowCamera(false)}
				onExited={() => setShownCamera()}
				PaperComponent={props => <>{props.children}</>}
			>
				{/* <CameraView camera={shownCamera} /> */}
			</Dialog>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loadingCameras')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</>
	);
}

export default connect(getStateVariables(STORE.properties, STORE.cameras), { updateCameras })(CameraAdministration);
