import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Snackbar } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import ChartToolbar from '../Toolbar/ChartsToolbar';
import { createWidget } from '../Widget/WidgetFactory';
import { Grid } from '../Widget/WidgetGrid';

import { CENTER_CONTENT_STYLE } from '../../../constants';
import { websiteBg } from '../../../colors';
import { gql, useMutation, useQuery } from '@apollo/client';
import { WIDGET_DEFAULT_OPTIONS } from '../Widget/WidgetDefault';
import { Alert } from '@material-ui/lab';

const UPDATE_WIDGETS = gql`
	mutation ($widgets: [WidgetInp]!, $tabId: Int!) {
		updateWidget(widgetlist: $widgets, tabid: $tabId) {
			id
			rowindex
			columnindex
		}
	}
`;

const GET_WIDGETS = gql`
	query ($tabId: Int!) {
		getWidgetList(tabId: $tabId) {
			id
			charttabid
			includeinreport
			rowindex
			columnindex
			height
			width
			minheight
			minwidth
			maxheight
			maxwidth
			widgetmetadata
			sensors {
				sensorid
				locationid
			}
			widgetname
		}
	}
`;

function debounce(mainFunction, delay) {
	let timer;

	return function (...args) {
		clearTimeout(timer);

		timer = setTimeout(() => {
			mainFunction(...args);
		}, delay);
	};
}

function editModeChangeReducer(state, action) {
	switch (action.type) {
		case 'exit':
			return {
				...state,
				triedToExit: true,
			};
		case 'change':
			return {
				...state,
				hasChanged: true,
			};
		case 'reset':
			return {
				hasChanged: false,
				triedToExit: false,
			};
		default:
			return state;
	}
}

export { GET_WIDGETS };

export function TabPanel({ selectedTabIdx, items, tab, tabIndex, updateTabCallback, ...other }) {
	const [widgets, setWidgets] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [editModeChange, editModeChangeDispatch] = useReducer(editModeChangeReducer, {
		hasChanged: false,
		triedToExit: false,
	});
	const [normalize, setNormalize] = useState(false);
	const [includeInReport, setIncludeInReport] = useState(tab.includeInReport);
	const [selectedProperties, setSelectedProperties] = useState(tab.properties);
	const [currentAutoUpdateInterval, setCurrentAutoUpdateInterval] = useState(tab.updateInterval);
	const [currentDatetimeRange, setCurrentDatetimeRange] = useState({
		startDate: tab.startDate,
		endDate: tab.endDate,
		offset: tab.offset,
		selected: tab.selected,
	});
	const [snackbarInfo, setSnackbarInfo] = useState({
		open: false,
		message: '',
		severity: 'warning',
		duration: 6000,
		onClose: () => null,
	});

	const fullscreenElementRef = useRef(null);
	const grid = useRef(null); // GridStack ref

	const { t } = useTranslation();

	const [updateWidgets] = useMutation(UPDATE_WIDGETS, {
		onCompleted: ({ updateWidget }) => {
			setWidgets(
				widgets.map(widget => {
					if (widget.id === null) {
						widget.id = updateWidget.find(
							updateW => updateW.rowindex === widget.options.y && updateW.columnindex === widget.options.x
						).id;
					}
					return widget;
				})
			);
			editModeChangeDispatch({
				type: 'reset',
			});
		},
	});

	const { loading: widgetLoading, error: widgetError } = useQuery(GET_WIDGETS, {
		variables: {
			tabId: tab.id,
		},
		fetchPolicy: 'cache-and-network',
		onCompleted: widgetData => {
			const newWidgets = widgetData.getWidgetList;
			const newWidgetData = newWidgets.map(widget => ({
				key: `widget-${widget.id}`,
				id: widget.id,
				element: widget.widgetname,
				props: widget.widgetmetadata,
				includeInReport: widget.includeinreport,
				sensors: widget.sensors.map(sensor => ({ ...sensor, sensorid: parseInt(sensor.sensorid) })),
				options: {
					w: widget.width,
					h: widget.height,
					x: widget.columnindex,
					y: widget.rowindex,
					minW: widget.minwidth,
					maxW: widget.maxwidth,
					minH: widget.minheight,
					maxH: widget.maxheight,
				},
			}));
			if (newWidgetData.length === 0) {
				setEditMode(true);
			}

			setWidgets(newWidgetData);
		},
	});

	useEffect(() => {
		const handleBeforeUnload = event => {
			if (editModeChange.hasChanged) {
				event.returnValue = null;
				editModeChangeDispatch({
					type: 'exit',
				});
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [editModeChange]);

	const toggleFullscreen = () => {
		const fullscreenElement = fullscreenElementRef.current;
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else if (fullscreenElement && fullscreenElement.requestFullscreen) {
			fullscreenElement.requestFullscreen().catch(error => {
				console.error('Fullscreen request denied:', error);
			});
		}
	};

	const handlePropertiesSelection = selectedData => {
		setSelectedProperties(selectedData);
		updateTabCallback(tabIndex, { properties: selectedData });
	};

	const timeSelectCallback = selectedItem => {
		setCurrentDatetimeRange(selectedItem);
		updateTabCallback(tabIndex, {
			endDate: selectedItem.endDate,
			startDate: selectedItem.startDate,
			offset: selectedItem.offset,
		});
	};

	const autoUpdateCallback = ({ interval }) => {
		if (interval === currentAutoUpdateInterval) return;
		setCurrentAutoUpdateInterval(interval);
		updateTabCallback(tabIndex, {
			updateInterval: interval,
		});
	};

	const changeSensorsCallback = (sensors, widgetKey) => {
		const newWidgets = [...widgets];
		const currentWidget = newWidgets.find(widget => widget.key === widgetKey);
		currentWidget.sensors = sensors;
		setWidgets(newWidgets);
		editModeChangeDispatch({
			type: 'change',
		});
	};

	const updateChildTitle = (newValue, widgetId) => {
		const copyItems = [...widgets];
		let currentWidget = copyItems.find(widget => widget.id === widgetId);
		currentWidget.props = { ...currentWidget.props, Title: newValue };

		setWidgets(copyItems);
		saveOptions();
	};

	const deleteWidgetCallback = widgetKey => {
		const survivingWidgets = widgets.filter(widget => widget.key !== widgetKey);
		setWidgets(survivingWidgets);
		editModeChangeDispatch({
			type: 'change',
		});
	};

	const getWidgets = () => {
		return widgets.map(({ key, element, sensors, props, options, id }) => {
			const widgetObject = {
				id: key,
				options: options,
				content: createWidget(element, {
					id,
					widgetKey: key,
					sensors,
					props,
					options,
					changeSensorsCallback,
					properties: selectedProperties,
					currentDatetimeRange,
					currentAutoUpdateInterval,
					updateChildTitle,
					normalize,
					editMode,
				}),
			};
			return widgetObject;
		});
	};

	const getNextKey = () => {
		const maxCurrentKey = widgets.reduce((prev, current) => {
			const id = parseInt(current.key.match(/\d+$/)[0]);
			return Math.max(id, prev);
		}, 0);
		return maxCurrentKey + 1;
	};

	const fitNewWidgetIntoGrid = (widgets, newWidget) => {
		const { x, y, w, h } = newWidget.options;
		if (grid.current.willItFit(x, y, w, h, true)) return newWidget;

		const { minW, minH } = WIDGET_DEFAULT_OPTIONS[newWidget.element].options;
		if (!grid.current.willItFit(x, y, minW, minH, true)) return undefined;

		return {
			...newWidget,
			options: {
				...newWidget.options,
				w: minW,
				h: minH,
			},
		};
	};

	const addWidget = ({ element, props, options, sensors = [] }) => {
		const newWidget = fitNewWidgetIntoGrid(widgets, {
			key: `widget-${getNextKey()}`,
			id: null,
			element: element,
			includeInReport: includeInReport,
			props: props,
			sensors: sensors,
			options: options,
		});
		if (newWidget !== undefined) {
			setWidgets([...widgets, newWidget]);
			editModeChangeDispatch({
				type: 'change',
			});
		} else {
			setSnackbarInfo({
				open: true,
				message: t('charts.gridFull'),
				severity: 'error',
				duration: 7000,
				onClose: () => null,
			});
		}
	};

	const toggleEditMode = () => {
		if (editMode) {
			closeEditMode();
		} else {
			setEditMode(true);
		}
	};

	const toggleNormalize = () => {
		setNormalize(!normalize);
	};

	const saveIncludeInReport = useCallback(value => {
		updateTabCallback(tabIndex, {
			includeInReport: value,
		});
		// eslint-disable-next-line
	}, []);

	const delayedSaveIncludeToDB = useMemo(() => {
		return debounce(saveIncludeInReport, 1000);
	}, [saveIncludeInReport]);

	const toggleIncludeInReport = () => {
		setIncludeInReport(!includeInReport);
		delayedSaveIncludeToDB(!includeInReport);
	};

	const closeEditMode = () => {
		setEditMode(false);
		if (editModeChange.hasChanged) {
			saveOptions();
		} else {
			editModeChangeDispatch({
				type: 'reset',
			});
		}
	};

	const saveOptions = () => {
		const gridData = grid.current.save(false);
		const newWidgets = widgets.map(widget => {
			const widgetGridData = gridData.find(gridWidget => gridWidget.id === widget.key);
			widget.options = widgetGridData;
			return widget;
		});
		setWidgets(newWidgets);
		const saveData = newWidgets.map(widget => ({
			id: widget.id,
			charttabid: tab.id,
			widgetname: widget.element,
			includeinreport: true,
			columnindex: widget.options.x,
			rowindex: widget.options.y,
			width: widget.options.w,
			height: widget.options.h,
			sensors: widget.sensors.map(sensor => sensor.sensorid),
			widgetmetadata: JSON.stringify(widget.props),
		}));
		updateWidgets({
			variables: {
				widgets: saveData,
				tabId: tab.id,
			},
		});
	};

	if (widgetError) {
		return t('charts.fetchingError');
	}

	if (widgetLoading && tab.tabIndex === 0) {
		return (
			<Box sx={{ display: 'flex' }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<div
			role='tabpanel'
			hidden={selectedTabIdx !== tab.tabIndex}
			id={`tabpanel-${tab.tabIndex}`}
			aria-labelledby={`tab-${tab.tabIndex}`}
			{...other}
		>
			{selectedTabIdx === tab.tabIndex && (
				<Box>
					{
						<ChartToolbar
							addWidget={addWidget}
							widgets={widgets}
							autoUpdateInterval={currentAutoUpdateInterval}
							autoUpdateCallback={autoUpdateCallback}
							toggleEditMode={toggleEditMode}
							editMode={editMode}
							currentDatetimeRange={currentDatetimeRange}
							timeSelectCallback={timeSelectCallback}
							toggleFullscreen={toggleFullscreen}
							toggleIncludeInReportCallback={toggleIncludeInReport}
							includeInReport={includeInReport}
							toggleNormalizeCallback={toggleNormalize}
							normalize={normalize}
							onDataSelectionCallback={handlePropertiesSelection}
							selectedProperties={selectedProperties}
							changesMade={editModeChange}
						></ChartToolbar>
					}
					<div
						ref={fullscreenElementRef}
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: `calc(${CENTER_CONTENT_STYLE.width} + ${
								fullscreenElementRef?.current?.requestFullscreen ? '0' : '11rem'
							}`,
							height: `calc(100vh - 6rem)`,
							background: websiteBg,
						}}
					>
						{
							<Grid
								ref={grid}
								widgets={getWidgets()}
								editMode={editMode}
								deleteWidgetCallback={deleteWidgetCallback}
								changeMadeCallback={args => editModeChangeDispatch(args)}
							/>
						}
					</div>
					<Snackbar
						open={snackbarInfo.open}
						autoHideDuration={snackbarInfo.duration}
						onClose={() => {
							setSnackbarInfo({ ...snackbarInfo, open: false });
							snackbarInfo.onClose();
						}}
						style={{
							width: '100%',
						}}
					>
						<Alert
							onClose={() => {
								setSnackbarInfo({ ...snackbarInfo, open: false });
								snackbarInfo.onClose();
							}}
							severity={snackbarInfo.severity}
							variant='filled'
							style={{ minWidth: '60%' }}
						>
							{snackbarInfo.message}
						</Alert>
					</Snackbar>
				</Box>
			)}
		</div>
	);
}
