import React, { useEffect, useState } from 'react';
import AddRecipients from './AddRecipients';
import { Collapse, IconButton, Step, StepButton, Stepper } from '@material-ui/core';
import CreateCsvReport from './CreateCsvReport';
import { Alert } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import i18n from '../../i18n';

const STEPS = {
	create: 0,
	recipients: 1,
};
const PROGRESS_PROPS = [i18n.t('reports.createReport'), i18n.t('reports.chooseRecipients')];
const DEFAULT_PROPS = {
	sensors: [],
};

export default function CreateCsvWizard({ handleClose, handleSave, loadReport, userInfo }) {
	const [step, setStep] = useState(STEPS.create);
	const [reportName, setReportName] = useState(loadReport?.reportname);
	const [reportData, setReportData] = useState(loadReport?.reportdata || DEFAULT_PROPS);
	const [recipientData, setRecipientData] = useState(loadReport?.recipients || []);
	const [shouldSave, setShouldSave] = useState(false);

	const handleCreateCsvEvent = (sensors) => {
		setReportData({ sensors });
		setStep(STEPS.recipients);
	};

	const handleBack = ({ recipients, reportName }) => {
		setRecipientData(recipients);
		setReportName(reportName);
		setStep(STEPS.create);
	};

	const handleCsvSave = (value) => {
		const newReport = {
			reporttype: 'CSV',
			recipients: value.recipients,
			reportname: value.reportName,
			reportdata: reportData,
		};
		handleSave({ newReport, reportid: loadReport?.reportid });
	};

	const handleCsvSaveWithoutRecipients = (updatedSensors) => {
		const newReport = {
			reporttype: 'CSV',
			recipients: recipientData,
			reportname: reportName,
			reportdata: { sensors: updatedSensors },
		};

		handleSave({ newReport, reportid: loadReport?.reportid });
	};

	return (
		<>
			<Stepper activeStep={step} style={{ margin: '0 2.5rem 0 1rem' }}>
				{PROGRESS_PROPS.map((label, index) => (
					<Step key={label}>
						<StepButton color='inherit' onClick={() => null}>
							{label}
						</StepButton>
					</Step>
				))}
			</Stepper>
			<IconButton style={{ position: 'absolute', top: '0.8rem', right: '1rem' }} onClick={handleClose}>
				<Close />
			</IconButton>
			{step === STEPS.recipients ? (
				<AddRecipients
					reportType={'CSV'}
					loadReport={reportData}
					loadRecipients={recipientData}
					loadReportName={reportName}
					handleBack={handleBack}
					handleSave={handleCsvSave}
				// handleClose={handleClose}
				/>
			) : undefined}
			{step === STEPS.create ? (
				<CreateCsvReport
					loadReport={reportData}
					handleClose={handleClose}
					setShouldSave={setShouldSave}
					// Directly pass updated sensors to handleCsvSaveWithoutRecipients
					handleSave={shouldSave
						? (updatedSensors) => handleCsvSaveWithoutRecipients(updatedSensors)
						: (value) => handleCreateCsvEvent(value)}
					userInfo={userInfo}
				/>
			) : undefined}
		</>
	);
}
