import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Backdrop, LinearProgress } from '@material-ui/core';

import { getStateVariables, STORE } from '../redux/selectors';
import { setCOs, setCORegisters } from '../redux/actionCreators';
import { editObject } from '../utility-functions';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import CORegisterAdministration from './CORegisterAdministration';
import { PROP_SELECTION_TYPES } from '../constants';

const SET_COS = gql`
	mutation ($coids: [ID!]!, $locationid: ID, $name: String, $coaddress: String) {
		setCOs(coids: $coids, locationid: $locationid, name: $name, coaddress: $coaddress) {
			coid
			locationid
			name
			coaddress
		}
	}
`;
const ADD_CO = gql`
	mutation ($locationid: ID!, $name: String!, $coaddress: String) {
		addCO(locationid: $locationid, name: $name, coaddress: $coaddress) {
			coid
			locationid
			name
			coaddress
		}
	}
`;

const POPUP_TYPES = Object.freeze({ edit: 1, create: 2 });

/**
 * A table for Control Objects that allow them to be filtered and bulk-edited
 */
function COAdministration(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [selectedCOs, setSelectedCOs] = useState([]);
	const [cosUnderEdit, setCOsUnderEdit] = useState([]);
	const [editPopupType, setEditPopupType] = useState();
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const [setCOs] = useMutation(SET_COS, {
		onCompleted: ({ setCOs }) => {
			for (const newCO of setCOs)
				editObject(
					props.cos.find(oldCO => oldCO.coid === newCO.coid),
					newCO
				);
			props.setCOs([...props.cos]);
		},
	});
	const [addCO] = useMutation(ADD_CO, {
		onCompleted: ({ addCO }) => {
			Swal.fire(t('userAdmin.success'), t('coAdmin.coAdded'), 'success');
			props.setCOs([...props.cos, addCO])
		},
	});

	const filteredCOs = (
		selectedProperties.length ? props.cos.filter(co => selectedProperties.some(pro => String(pro.value) === co.locationid)) : props.cos
	).sort((a, b) => (a.locationid < b.locationid || (a.locationid === b.locationid && a.name < b.name) ? -1 : 1));
	const formattedProperties = props.properties
		.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));

	const { t } = useTranslation();

	//loading control objects
	useEffect(() => {
		setIsLoading(true);
		if (props.cos.length || props.properties.length || props.coRegisters.length){
			 setIsLoading(false);
		}
	}, [props.cos]);

	return (
		<>
			<div style={{ margin: '1rem 1rem 1.5rem 1rem', display: 'flex' }}>
				<SelectionTable
					localization={{ title: t('constants.controlObjects'), nRowsSelected: t('coAdmin.amountSelectedCOs') + '{0}' }}
					data={filteredCOs.map(co => ({
						...co,
						...(props.properties.find(pro => String(pro.locationid) === co.locationid) || {}),
						registerCount: props.coRegisters.filter(reg => reg.coid === co.coid).length,
					}))}
					dataId='coid'
					onSelectionChange={cos => setSelectedCOs(cos)}
					/* onRowClick={co => {
						setCOsUnderEdit([co]);
						setEditPopupType(POPUP_TYPES.edit);
						setEditPopupEnabled(!editPopupEnabled);
					}} */
					columns={[
						{
							title: t('generic.name'),
							field: 'name',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.name}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setCOs({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												coids: [rowData.coid],
												name: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{
							title: t('generic.city'),
							field: 'city',
						},
						{
							title: t('generic.address'),
							field: 'street',
						},
						{
							title: t('coAdmin.amountOfRegisters'),
							field: 'registerCount',
						},
						{
							title: t('coAdmin.ucAddress'),
							field: 'coaddress',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.coaddress}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setCOs({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												coids: [rowData.coid],
												coaddress: e.target.value,
											},
										});
									}}
								/>
							),
						},
					]}
					tableProps={{ maxColumnLength: 36 }}
				/>

				<SelectionSidebar
					buttons={[
						{
							label: t('coAdmin.createNewControlObject'),
							onClick: () => {
								setEditPopupType(POPUP_TYPES.create);
								setEditPopupEnabled(!editPopupEnabled);
							},
						},
						{
							label: t('genericAdmin.editSelected'),
							onClick: () => {
								setCOsUnderEdit(selectedCOs);
								setEditPopupType(POPUP_TYPES.edit);
								setEditPopupEnabled(!editPopupEnabled);
							},
							disabled: !selectedCOs.length,
						},
						/* {
							label: t('genericAdmin.archiveSelected'),
							onClick: () => null,
							disabled: !selectedCOs.length,
						}, */
					]}
					filters={formattedProperties}
					updateSelectedFilters={props => setSelectedProperties(props || [])}
					localization={{
						filterTitle: t('coAdmin.filterControlObjects'),
						filterPlaceholder: t('genericAdmin.selectPropsPrompt'),
					}}
					style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
				/>
			</div>

			<EditPopup
				text={
					editPopupType === POPUP_TYPES.edit
						? {
								title:
									cosUnderEdit.length === 1
										? t('coAdmin.editControlObjects') + ' ' + (cosUnderEdit[0].name || t('genericAdmin.withoutName'))
										: t('generic.edit') + ' ' + cosUnderEdit.length + ' ' + t('coAdmin.selectedControlObjects'),
								subtitle:
									t('genericAdmin.selectValuesFor') + ' ' + t('coAdmin.theSelectedCO', { count: cosUnderEdit.length }),
							}
						: {
								title: t('coAdmin.createNewControlObject') + '.',
								subtitle: t('coAdmin.selectValuesForNewCO'),
							}
				}
				fields={[
					{
						id: 'name',
						label: t('generic.name'),
						placeholder: editPopupType === POPUP_TYPES.edit && cosUnderEdit.length === 1 ? cosUnderEdit[0].name : '...',
						required: editPopupType === POPUP_TYPES.create,
					},
					{
						id: 'locationid',
						label: t('generic.property'),
						placeholder:
							editPopupType === POPUP_TYPES.edit && cosUnderEdit.length === 1
								? formattedProperties.find(pro => pro.value === cosUnderEdit[0].locationid).label
								: '...',
						options: formattedProperties,
						required: editPopupType === POPUP_TYPES.create,
					},
					{
						id: 'coaddress',
						label: t('coAdmin.ucAddress'),
						placeholder: editPopupType === POPUP_TYPES.edit && cosUnderEdit.length === 1 ? cosUnderEdit[0].coaddress : '...',
					},
				]}
				isOpen={editPopupEnabled}
				onClose={() => setEditPopupEnabled(!editPopupEnabled)}
				onSave={opts => {
					if (editPopupType === POPUP_TYPES.edit) {
						setCOs({
							variables: {
								coids: cosUnderEdit.map(co => co.coid),
								name: opts.name,
								locationid: opts.locationid,
								coaddress: opts.coaddress,
							},
						});
						Swal.fire(t('userAdmin.success'), t('coAdmin.coInfo'), 'success');
					} else {
						addCO({
							variables: {
								name: opts.name,
								locationid: opts.locationid,
								coaddress: opts.coaddress,
							},
						});
					}

					setEditPopupEnabled(!editPopupEnabled);
				}}

			/>

			{/* <CORegisterAdministration /> */}
			{(props.userInfo || {}).propertyAccess === PROP_SELECTION_TYPES.all.id && <CORegisterAdministration />}
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loadingCOs')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</>
	);
}

export default connect(getStateVariables(STORE.cos, STORE.coRegisters, STORE.properties, STORE.userInfo), { setCOs, setCORegisters })(COAdministration);
