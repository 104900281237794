import React from 'react';

import { NewReleases as NewIcon, Clear as ClearIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { withRouter } from 'react-router';
import { format as formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { PAGES, PROPERTY_TABS, QUERY_STRINGS, CENTER_CONTENT_STYLE } from '../constants';
import * as colors from '../colors';
import MaterialTable from './MaterialTableWrapper';
import TooltipWrapper from './TooltipWrapper';

const headerStyle = {
	height: '2.8rem',
	backgroundColor: colors.bgcolor,
	color: colors.text,
};

function NotificationList(props) {
	const { t } = useTranslation();
	return (
		<div style={{ width: CENTER_CONTENT_STYLE.width }}>
			<MaterialTable
				options={{
					pageSize: 10,
					pageSizeOptions: [],
					paginationType: 'stepped',
					headerStyle: headerStyle,
					rowStyle: { height: '2.4rem' },
					searchFieldStyle: { left: '-3.6rem' },
					actionsColumnIndex: -1,
				}}
				localization={{
					// To prevent bug of popups appearing below the window
					pagination: {
						previousTooltip: '',
						nextTooltip: '',
					},
				}}
				columns={[
					{
						title: t('notificationList.new'),
						render: rowData =>
							Number(rowData.notificationlogid) > Number(props.oldLastReadNotificationId) ? (
								<NewIcon style={{ color: '#f00' }} />
							) : null,
						customSort: (a, b) => (Number(a.notificationlogid) > Number(b.notificationlogid) ? -1 : 1),
					},
					{
						title: t('generic.name'),
						field: 'name',
						render: row =>
							row.name?.length <= 22 ? (
								<div>{row.name}</div>
							) : (
								<TooltipWrapper text={row.name}>
									<div>{row.name?.slice(0, 22) + '...'}</div>
								</TooltipWrapper>
							),
					},
					{
						title: t('generic.value'),
						field: 'sensorvalue',
						render: rowData =>
							Number((rowData.sensorvalue * (rowData.multiplier || 1)).toFixed(1)) + ' ' + (rowData.unit || ''),
					},
					{
						title: t('notificationList.thresholdValues'),
						render: rowData =>
							Number((rowData.lowerthreshold * (rowData.multiplier || 1)).toFixed(1)) +
							' : ' +
							Number((rowData.upperthreshold * (rowData.multiplier || 1)).toFixed(1)),
					},
					{ title: t('notificationList.timestamp'), field: 'formattedTimestamp' },
					{ title: t('generic.address'), field: 'street' },
					{ title: t('generic.city'), field: 'city' },
				]}
				data={props.data.map(datum => {
					return {
						...datum,
						formattedTimestamp: datum.timestamp ? formatDate(new Date(datum.timestamp), 'yyyy-MM-dd HH:mm') : '',
					};
				})}
				title={t('notificationList.notificationAlarmsTitle')}
				onRowClick={(e, rowData) => {
					props.history.push(
						`/${PAGES.properties.id}/${rowData.locationid}/${PROPERTY_TABS.meters.id}?${QUERY_STRINGS.sensorId.id}=${rowData.sensorid}&${QUERY_STRINGS.alarm.date}=${rowData.timestamp}&${QUERY_STRINGS.alarm.value}=${rowData.sensorvalue}`
					);
					props.closeList();
				}}
			/>

			<IconButton onClick={props.closeList} style={{ padding: '0.4rem', position: 'absolute', top: '0.7rem', right: '0.8rem' }}>
				<ClearIcon style={{ width: '1.8rem', height: '1.8rem', color: colors.text }} />
			</IconButton>
		</div>
	);
}

export default withRouter(NotificationList);
