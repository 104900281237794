import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AccumulatedChart, MultiLineChart } from './NivoCharts';
import { UNIT_CLASSIFICATIONS, PAGES } from '../constants';
import { combineTimeframeData } from '../utility-functions';
import i18n from '../i18n';
import * as colors from '../colors';

const GRAPH_OPTIONS = {
	perProperty: { id: 'perProperty', label: i18n.t('mostAlarmsChart.perProperty') },
	perSqm: { id: 'perSqm', label: i18n.t('mostAlarmsChart.perSquareMetre') },
};

/**
 * Fetches data from multiple sensors, formats the returned data, and displays it in a mixed line and bar-chart
 * @param {Array of Objects} sensors : Sensors to accumulate price-data from, contains data embedded as a key
 * @param {Object} interval : Info about the chosen time-interval
 * @param {Boolean} showOptions : Whether to show the settings for changing graph options
 * @param {Object} defaultGraphOptions : Default options applied to the graph when it is loaded, {perProperty: Boolean, perSqm: Boolean}
 * @param {Number} maxLegends : How many legends to allow before legends are disabled
 * @param {Number} maxLegendLength : The maximum length that a legend-string may have before it is truncated
 * @param {Object} size : The width and height of the chart's container, {width: CSS, height: CSS}
 * @param {Object} margin : Margins applied to the Nivo-chart, specifying the amount of spacing around it and room for legends, etc., {top, right, bottom, left: Number (pixels)}
 * @param {Object} chartProps : Additional props that will be passed directly to the chart
 */
function OverviewChart(props) {
	const [chartData, setChartData] = useState();
	// const [sumChartData, setSumChartData] = useState();
	const [graphOptions, setGraphOptions] = useState({
		perProperty: props.defaultGraphOptions?.perProperty || false,
		perSqm: props.defaultGraphOptions?.perSqm || false,
	});

	const routerHistory = useHistory();

	const { t } = useTranslation();

	useEffect(() => {
		let sensors = JSON.parse(JSON.stringify(props.sensors));

		// Normalize values before other computation happens
		if (graphOptions.perSqm) {
			sensors = sensors.filter(sen => sen.area);
			sensors.forEach(sen => {
				sen.data = sen.data.map(datum => {
					return {
						...datum,
						priceVal: datum.priceVal ? datum.priceVal / sen.area : datum.priceVal,
						unitVal: datum.unitVal ? datum.unitVal / sen.area : datum.unitVal,
					};
				});
			});
		}

		let chartData = [];

		if (!graphOptions.perProperty) {
			const electricityData = [],
				// waterData = [],
				districtHeatingData = [];

			sensors
				.filter(sen => sen.classification === UNIT_CLASSIFICATIONS.electricity.id)
				.forEach(sen =>
					combineTimeframeData(electricityData, sen.data, ['priceVal', 'unitVal'], props.interval.dateFormat, {
						unit: UNIT_CLASSIFICATIONS.electricity.unit,
					})
				);
			// sensors
			// 	.filter(sen => sen.classification === UNIT_CLASSIFICATIONS.water.id)
			// 	.forEach(sen =>
			// 		combineTimeframeData(waterData, sen.data, ['priceVal', 'unitVal'], props.interval.dateFormat, {
			// 			unit: UNIT_CLASSIFICATIONS.water.unit,
			// 		})
			// 	);
			sensors
				.filter(sen => sen.classification === UNIT_CLASSIFICATIONS.districtHeating.id)
				.forEach(sen =>
					combineTimeframeData(districtHeatingData, sen.data, ['priceVal', 'unitVal'], props.interval.dateFormat, {
						unit: UNIT_CLASSIFICATIONS.districtHeating.unit,
					})
				);

			// Data with earlier date might have been entered later and have been placed at the back of array
			electricityData.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));
			// waterData.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));
			districtHeatingData.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));

			// Save data with formatting adjusted for chart-rendering
			chartData = [
				{
					id: t('overviewChart.districtHeating'),
					data: districtHeatingData.map(obj => {
						return { x: obj.formattedDate, y: obj.unitVal, price: obj.priceVal, unit: obj.unit || '', date: obj.date };
					}),
				},
				// {
				// 	id: 'Vatten',
				// 	data: waterData.map(obj => {
				// 		return { x: obj.formattedDate, y: obj.unitVal, price: obj.priceVal, unit: obj.unit || '', date: obj.date };
				// 	}),
				// },
				// {
				// 	id: t('overviewChart.water'),
				// 	data: waterData.map(obj => {
				// 		return { x: obj.formattedDate, y: obj.value, unitY: obj.rawValue, unit: obj.unit || '', date: obj.date };
				// 	}),
				// },
				{
					id: t('overviewChart.electricity'),
					data: electricityData.map(obj => {
						return { x: obj.formattedDate, y: obj.unitVal, price: obj.priceVal, unit: obj.unit || '', date: obj.date };
					}),
				},
			];
		} else {
			// Group energy-sensors by property
			const properties = {};
			for (const sensor of sensors) {
				properties[sensor.locationid] = (properties[sensor.locationid] || []).concat(sensor);
			}

			// Combine values of sensors of same property
			chartData = Object.values(properties).map(sensors => {
				const combinedData = [];
				for (const sen of sensors)
					combineTimeframeData(combinedData, sen.data, ['unitVal'], props.interval.dateFormat, {
						unit: UNIT_CLASSIFICATIONS.electricity.unit, // TODO: Make sure that this is correct when it comes to formatting.
						location: sen.city + ': ' + sen.street,
						locationid: sen.locationid,
					});

				combinedData.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));

				let dataId;
				if (!props.maxLegendLength || props.maxLegendLength >= sensors[0].city.length + 1 + sensors[0].street.length)
					dataId = sensors[0].city + ': ' + sensors[0].street;
				else
					dataId =
						sensors[0].city.slice(0, 3) +
						'.: ...' +
						sensors[0].street.slice(sensors[0].street.length - Math.max(0, props.maxLegendLength - 7));

				return {
					id: dataId,
					data: combinedData,
				};
			});

			// Find and fix duplicates in chartData ids
			for (const data of chartData) {
				let dupCount = 0;
				chartData.forEach(otherData => {
					if (otherData !== data && otherData.id === data.id) {
						otherData.id += '_' + (dupCount + 2);
						++dupCount;
					}
				});

				if (dupCount) data.id += '_1';
			}

			// Save data with formatting adjusted for chart-rendering
			chartData.forEach(chartDatum => {
				chartDatum.data = chartDatum.data.map(datum => {
					return { ...datum, x: datum.formattedDate, y: datum.priceVal };
				});
			});
		}

		// Place the element with the earliest data-point first in the array, otherwise earlier data-points will be placed further right in the X-axis
		let earliestIndex, earliestDate;
		chartData.forEach((line, i) => {
			if (Array.isArray(line.data) && line.data.length)
				if (line.data[0].date < earliestDate || earliestDate === undefined) {
					earliestIndex = i;
					earliestDate = line.data[0].date;
				}
		});
		if (earliestIndex !== undefined) {
			const earliestLine = chartData.splice(earliestIndex, 1);
			chartData.unshift(...earliestLine);
		}

		setChartData(chartData);

		// Combine values from all energy-sensors for the trend-curve
		// const combinedData = [];
		// for (const sen of sensors) combineTimeframeData(combinedData, sen.data, ['priceVal'], props.interval.dateFormat);

		// combinedData.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 0));

		// setSumChartData([
		// 	{
		// 		id: 'Trend',
		// 		data: combinedData.map(datum => {
		// 			return { x: datum.formattedDate, y: datum.priceVal };
		// 		}),
		// 	},
		// ]);
		// eslint-disable-next-line
	}, [props.sensors, graphOptions]);

	return (
		<div style={{ display: 'flex', position: 'relative' }}>
			<div>
				<div
					style={{
						width: props.size?.width !== undefined ? props.size.width : '46.5rem',
						height: props.size?.height !== undefined ? props.size.height : '24rem',
					}}
				>
					{!graphOptions.perProperty ? (
						<AccumulatedChart data={chartData || []} chartProps={props.chartProps} />
					) : (
						<MultiLineChart
							data={chartData || []}
							maxLegends={props.maxLegends}
							margin={props.margin}
							chartProps={{
								onClick: point =>
									point?.data?.locationid && routerHistory.push(`/${PAGES.properties.id}/${point.data.locationid}`),
								...(props.chartProps || {}),
							}}
						/>
					)}
				</div>
				{/* <div style={{ width: '46.5rem', height: '4rem', marginTop: '-0.5rem' }}>
					<AccumulatedSumChart data={sumChartData || []} />
				</div> */}
			</div>

			{(props.showOptions || props.showOptions === undefined) && (
				<div
					style={{
						position: 'absolute',
						transform: 'scale(0.9)',
						right: '0',
						padding: '0 1.1rem 0px 0px',
						marginTop: '-0.8rem',
					}}
				>
					{Object.values(GRAPH_OPTIONS).map(opt => (
						<div key={opt.id}>
							<h3 style={{ fontWeight: '400', margin: '0.6rem 0 0.4rem 0.6rem' }}>{opt.label}</h3>
							<Switch
								checked={graphOptions[opt.id]}
								onChange={() => setGraphOptions({ ...graphOptions, [opt.id]: !graphOptions[opt.id] })}
								value={graphOptions[opt.id]}
								style={{color: colors.primary}}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export { OverviewChart };
