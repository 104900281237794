import React, { useEffect, useState } from 'react';
import CreatePdfReport from './CreatePdfReport';
import AddRecipients from './AddRecipients';
import { IconButton, Step, StepButton, Stepper } from '@material-ui/core';
import { REPORT_OPTIONS } from '../../constants';
import { Close } from '@material-ui/icons';
import i18n from '../../i18n';

const STEPS = {
	create: 0,
	recipients: 1,
};
const PROGRESS_PROPS = [i18n.t('reports.createReport'), i18n.t('reports.chooseRecipients')];
const DEFAULT_PROPS = {
	pages: Object.values(REPORT_OPTIONS).map(inter => inter.id),
	properties: [],
	sensors: [],
};

export default function CreatePdfWizard({ handleSave, handleClose, loadReport, userInfo }) {
	const [step, setStep] = useState(STEPS.create);
	const [reportName, setReportName] = useState(loadReport?.reportname);
	const [reportData, setReportData] = useState(loadReport?.reportdata || DEFAULT_PROPS);
	const [recipientData, setRecipientData] = useState(loadReport?.recipients || []);
	const [shouldSave, setShouldSave] = useState(false);

	const handleCreatePdfEvents = value => {
		if (value) {
			setReportData(value);
			setStep(STEPS.recipients);
		} else {
			handleClose(undefined);
		}
	};

	const handleBack = ({ recipients, reportName }) => {
		setRecipientData(recipients);
		setReportName(reportName);
		setStep(STEPS.create);
	};

	const handlePdfSave = value => {
		const newReport = {
			reporttype: 'PDF',
			recipients: value.recipients,
			reportname: value.reportName,
			reportdata: reportData,
		};
		handleSave({ newReport, reportid: loadReport?.reportid });
	};

	const handlePdfSaveWithoutRecipients = value => {
		const newReport = {
			reporttype: 'PDF',
			recipients: recipientData,
			reportname: reportName,
			reportdata: value,
		};
		
		handleSave({ newReport, reportid: loadReport?.reportid });
	};


	return (
		<>
			<Stepper activeStep={step} style={{ margin: '0 2.5rem 0 1rem' }}>
				{PROGRESS_PROPS.map((label, index) => (
					<Step key={label}>
						<StepButton color='inherit' onClick={() => null}>
							{label}
						</StepButton>
					</Step>
				))}
			</Stepper>
			<IconButton style={{ position: 'absolute', top: '0.8rem', right: '1rem' }} onClick={handleClose}>
				<Close />
			</IconButton>
			{step === STEPS.recipients ? (
				<AddRecipients
					reportType={'PDF'}
					loadReport={reportData}
					loadRecipients={recipientData}
					loadReportName={reportName}
					handleBack={handleBack}
					handleClose={handleClose}
					handleSave={handlePdfSave}
				/>
			) : undefined}
			{step === STEPS.create ? (
				<CreatePdfReport
					userInfo={userInfo}
					loadReport={reportData}
					handleClose={handleClose}
					setShouldSave={setShouldSave}
					handleSave={shouldSave ? handlePdfSaveWithoutRecipients : value => handleCreatePdfEvents(value)}
				//handleSave={ handlePdfSaves }
				/>
			) : undefined}
		</>
	);
}
