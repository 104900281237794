import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Backdrop, LinearProgress } from '@material-ui/core';

import { getStateVariables, STORE } from '../redux/selectors';
import { updateProperties, setProperties } from '../redux/actionCreators';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup, { INPUT_TYPE as POPUP_INPUT_TYPE } from './EditPopup';
import { ConfirmPopup } from './ConfirmPopup';
import Swal from 'sweetalert2';

const SET_PROPERTIES = gql`
	mutation ($locationids: [ID]!, $street: String, $city: String, $cadastral: String, $area: Int, $longitude: Float, $latitude: Float) {
		setSensorLocations(
			locationids: $locationids
			street: $street
			city: $city
			cadastral: $cadastral
			area: $area
			longitude: $longitude
			latitude: $latitude
		) {
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
		}
	}
`;
const ADD_PROPERTY = gql`
	mutation ($street: String!, $city: String!, $cadastral: String, $area: Int, $longitude: Float, $latitude: Float) {
		addSensorLocation(street: $street, city: $city, cadastral: $cadastral, area: $area, longitude: $longitude, latitude: $latitude) {
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
		}
	}
`;
const REMOVE_PROPERTIES = gql`
	mutation ($locationids: [ID]) {
		removeSensorLocations(locationids: $locationids) {
			locationid
		}
	}
`;

const POPUP_TYPES = Object.freeze({ edit: 1, add: 2 });

/**
 * A table for properties that allows them to be added, filtered, and bulk-edited
 */
function PropertyAdministration(props) {
	const [filteredCities, setFilteredCities] = useState([]);
	const [selectedProps, setSelectedProps] = useState([]);
	const [propsUnderEdit, setPropsUnderEdit] = useState([]);
	const [editPopupType, setEditPopupType] = useState();
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
	const [locationidsToDelete, setLocationidsToDelete] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [setProperties] = useMutation(SET_PROPERTIES, {
		onCompleted: ({ setSensorLocations }) => props.updateProperties(setSensorLocations),
	});
	const [addProperty] = useMutation(ADD_PROPERTY, {
		onCompleted: ({ addSensorLocation }) => {
			Swal.fire(t('userAdmin.success'), t('propertyAdmin.propertyAdded'), 'success');
			props.updateProperties([addSensorLocation])
		},
	});
	const [removeProperties] = useMutation(REMOVE_PROPERTIES, {
		onCompleted: ({ removeSensorLocations }) => {
			Swal.fire(t('userAdmin.success'), t('propertyAdmin.propertyDeleted'), 'success');
			props.setProperties(
				props.properties.filter(pro => !removeSensorLocations.map(loc => loc.locationid).includes(Number(pro.locationid)))
			);
		},
	});

	function onDeleteButtonClick() {
		setShowConfirmDeletePopup(true);
		setLocationidsToDelete(selectedProps.map(pro => pro.locationid));
	}

	// Prepare a list of cities from fetched sensors
	const cities = []; // [{value: Number, label: String}, ...]
	for (const prop of props.properties)
		if (!cities.some(city => city.value === prop.city)) cities.push({ value: prop.city, label: prop.city });
	cities.sort((a, b) => (a.label < b.label ? -1 : 1));

	const filteredProperties = filteredCities.length
		? props.properties.filter(prop => filteredCities.some(city => city.value === prop.city))
		: props.properties;

	const { t } = useTranslation();

	//loadig buildings
	useEffect(() => {
		if (props.properties.length > 0) {
			setIsLoading(false);
		} else {
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, [props.properties]);

	return (
		<div style={{ margin: '1rem 0 0 1rem' }}>
			<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
				<SelectionTable
					localization={{ title: t('constants.properties'), nRowsSelected: t('propertyAdmin.numberSelectedProperties') + '{0}' }}
					data={filteredProperties
						.map(pro => ({
							...pro,
							sensorCount: props.sensors.filter(sen => sen.locationid === pro.locationid).length,
						}))
						.sort((a, b) => (a.city < b.city || (a.city === b.city && a.street < b.street) ? -1 : 1))}
					dataId='locationid'
					onSelectionChange={props => setSelectedProps(props)}
					/* onRowClick={prop => {
						setPropsUnderEdit([prop]);
						setEditPopupType(POPUP_TYPES.edit);
						setEditPopupEnabled(!editPopupEnabled);
					}} */
					columns={[
						{
							title: t('generic.city'),
							field: 'city',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.city}
									style={{ cursor: 'pointer', border: 'none', width: '4cm' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setProperties({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												locationids: [rowData.locationid],
												city: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{ title: t('generic.address'), field: 'street' },
						{
							title: t('propertyAdmin.cadastral'),
							field: 'cadastral',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.cadastral}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setProperties({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												locationids: [rowData.locationid],
												cadastral: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{
							title: t('propertyAdmin.squareMetres'),
							field: 'area',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.area}
									style={{ cursor: 'pointer', border: 'none', width: '2cm' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setProperties({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												locationids: [rowData.locationid],
												area: parseInt(e.target.value),
											},
										});
									}}
								/>
							),
						},
						{ title: t('propertyAdmin.numberOfSensors'), field: 'sensorCount' },
					]}
					tableProps={{ maxColumnLength: 28 }}
				/>
				<SelectionSidebar
					buttons={[
						{
							label: t('propertyAdmin.createNewProp'),
							onClick: () => {
								setEditPopupType(POPUP_TYPES.add);
								setEditPopupEnabled(!editPopupEnabled);
							},
						},
						{
							label: t('propertyAdmin.editSelected'),
							onClick: () => {
								setPropsUnderEdit(selectedProps);
								setEditPopupType(POPUP_TYPES.edit);
								setEditPopupEnabled(!editPopupEnabled);
							},
							disabled: !selectedProps.length,
						},
						{
							label: t('propertyAdmin.deleteSelected'),
							onClick: () => onDeleteButtonClick(),
							disabled: !selectedProps.length,
						},
					]}
					filters={cities}
					updateSelectedFilters={cities => setFilteredCities(cities || [])}
					localization={{ filterTitle: t('propertyAdmin.filterProp'), filterPlaceholder: t('propertyAdmin.selectCitiesDot') }}
					style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
				/>
			</div>

			<EditPopup
				text={
					editPopupType === POPUP_TYPES.edit
						? {
							title:
								propsUnderEdit.length === 1
									? t('propertyAdmin.editProperies', { count: propsUnderEdit.length, street: propsUnderEdit[0].street }) // TODO: First time trying this, make sure it's correct.
									: t('propertyAdmin.editProperies', { count: propsUnderEdit.length }),
							subtitle:
								propsUnderEdit.length === 1
									? t('propertyAdmin.giveNewValuesProp_one')
									: t('propertyAdmin.giveNewValuesProp_any'),
							//t('propertyAdmin.giveNewValuesProp', { count: propsUnderEdit.length }),
						}
						: {
							title: t('propertyAdmin.createNewPropDot'),
							subtitle: t('propertyAdmin.giveNewValuesNewProp'),
						}
				}
				fields={
					editPopupType === POPUP_TYPES.edit
						? [
							{
								id: 'street',
								label: t('generic.address'),
								placeholder: propsUnderEdit.length === 1 ? propsUnderEdit[0].street : '...',
								disabled: propsUnderEdit.length > 1,
							},
							{
								id: 'city',
								label: t('generic.city'),
								placeholder: propsUnderEdit.length === 1 ? propsUnderEdit[0].city : '...',
								options: cities,
								creatable: true,
							},
							{
								id: 'cadastral',
								label: t('constants.cadastral'),
								placeholder: propsUnderEdit.length === 1 ? propsUnderEdit[0].cadastral : '...',
								disabled: propsUnderEdit.length > 1,
							},
							{
								id: 'area',
								label: t('propertyAdmin.squareMetres'),
								placeholder: propsUnderEdit.length === 1 ? propsUnderEdit[0].area : '...',
								type: POPUP_INPUT_TYPE.Int,
							},
							{
								id: 'longitude',
								label: t('propertyAdmin.longitude'),
								placeholder: propsUnderEdit.length === 1 ? propsUnderEdit[0].longitude : '...',
								type: POPUP_INPUT_TYPE.Float,
							},
							{
								id: 'latitude',
								label: t('propertyAdmin.latitude'),
								placeholder: propsUnderEdit.length === 1 ? propsUnderEdit[0].latitude : '...',
								type: POPUP_INPUT_TYPE.Float,
							},
						]
						: [
							{
								id: 'street',
								label: t('generic.address'),
								placeholder: '...',
								required: true,
							},
							{
								id: 'city',
								label: t('generic.city'),
								placeholder: '...',
								options: cities,
								required: true,
								creatable: true,
							},
							{
								id: 'cadastral',
								label: t('constants.cadastral'),
								placeholder: '...',
							},
							{
								id: 'area',
								label: t('propertyAdmin.squareMetres'),
								placeholder: '...',
								type: POPUP_INPUT_TYPE.Int,
							},
							{
								id: 'longitude',
								label: t('propertyAdmin.longitude'),
								placeholder: '...',
								type: POPUP_INPUT_TYPE.Float,
							},
							{
								id: 'latitude',
								label: t('propertyAdmin.latitude'),
								placeholder: '...',
								type: POPUP_INPUT_TYPE.Float,
							},
						]
				}
				isOpen={editPopupEnabled}
				onClose={() => setEditPopupEnabled(!editPopupEnabled)}
				onSave={opts => {
					if (editPopupType === POPUP_TYPES.edit) {
						setProperties({
							variables: {
								locationids: propsUnderEdit.map(prop => prop.locationid),
								street: opts.street,
								city: opts.city,
								cadastral: opts.cadastral,
								area: opts.area && Number(opts.area),
								longitude: opts.longitude && Number(opts.longitude),
								latitude: opts.latitude && Number(opts.latitude),
							},
						});
						Swal.fire(t('userAdmin.success'), t('propertyAdmin.propertyInfo'), 'success');
					} else {
						addProperty({
							variables: {
								street: opts.street,
								city: opts.city,
								cadastral: opts.cadastral,
								area: opts.area && Number(opts.area),
								longitude: opts.longitude && Number(opts.longitude),
								latitude: opts.latitude && Number(opts.latitude),
							},
						});
					}

					setEditPopupEnabled(!editPopupEnabled);
				}}

			/>

			<ConfirmPopup
				enabled={showConfirmDeletePopup}
				title={t('propertyAdmin.confirmDeleted')}
				text={t('propertyAdmin.confirmPropDelete', { count: locationidsToDelete.length })} // TODO: This one was very complex and really needs to be checked.
				onFinish={suc => {
					if (suc)
						removeProperties({
							variables: {
								locationids: locationidsToDelete,
							},
						});

					setShowConfirmDeletePopup(false);
					setLocationidsToDelete([]);
				}}
			/>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loadingBuildings')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</div>
	);
}

export default connect(getStateVariables(STORE.sensors, STORE.properties), { updateProperties, setProperties })(PropertyAdministration);
