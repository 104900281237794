import React, { useState, useEffect } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { Fullscreen as FsIcon, FullscreenExit as FsExitIcon } from '@material-ui/icons';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import useResizeObserver from 'use-resize-observer';
import './Dashboard.scss';

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

function Dashboard(props) {
	const [showDash, setShowDash] = useState(false);
	const [isFs, setIsFs] = useState(false);
	const [enableFauxFs, setEnableFauxFs] = useState(false);
	const fsHandle = useFullScreenHandle();
	const { width: windowWidth, height: windowHeight } = useWindowDimensions();
	const { ref, width: imageWidth = 1, height: imageHeight = 1 } = useResizeObserver();

	//set to scale based on aspect ratio so the image never grows beyond what it should.
	const scaleHeight = windowHeight / windowWidth < imageHeight / imageWidth;

	useEffect(() => {
		props.setIsFs(isFs);
		if (isFs && !fsHandle.active) fsHandle.enter();
		else if (!isFs && fsHandle.active) fsHandle.exit();
		// eslint-disable-next-line
	}, [isFs]);
	useEffect(() => {
		if (showDash === true && imageHeight !== 1) {
			props.setPercentageDifference(imageHeight / 712);
		}
	}, [imageHeight, imageWidth, isFs, showDash]);
	useEffect(() => {
		if (new URLSearchParams(window.location.search).has('fullscreen')) {
			setShowDash(true);
			setEnableFauxFs(true);
		}
	}, []);

	return (
		<>
			<div style={{ margin: '1rem 1rem 1rem 2rem' }}>
				<div
					onClick={() => setShowDash(true)}
					style={{
						width: '13rem',
						height: '9.1rem',
						cursor: 'pointer',
						margin: '0 1.4rem 1.4rem 0',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					<img
						src={props.thumbnail}
						alt='dashboard-thumbnail'
						style={{
							position: 'absolute',
							width: 'inherit',
							borderRadius: '0.8rem',
							boxShadow: '0 0 6px #aaa',
						}}
					/>
				</div>
			</div>

			{showDash && (
				<Dialog
					open={showDash}
					onClose={() => setShowDash(false)}
					PaperProps={{
						style: {
							maxWidth: 'none',
							overflow: 'hidden',
							background: 'none',
							boxShadow: '0 0 5rem #000',
						},
					}}
				>
					<FullScreen handle={fsHandle} onChange={state => setIsFs(state)}>
						<div
							style={{
								height: isFs
									? !scaleHeight
										? `${imageHeight}px`
										: '100%'
									: scaleHeight && (enableFauxFs ? '95vh' : '85vh'),
								width: isFs ? (scaleHeight ? `${imageWidth}px` : '100%') : !scaleHeight && (enableFauxFs ? '95vw' : '85vw'),
								position: 'relative',
							}}
						>
							<img
								ref={ref}
								src={props.background}
								alt='bg'
								style={{
									display: 'block',
									position: 'relative',
									width: !scaleHeight && '100%',
									height: scaleHeight && '100%',
								}}
							/>
							{props.children}
							<IconButton
								onClick={() => {
									setIsFs(!isFs);
								}}
								style={{
									position: 'absolute',
									top: '1.5%',
									right: '1.1%',
									width: '2.5rem',
									height: '2.5rem',
									color: '#fffc',
								}}
							>
								{isFs ? <FsExitIcon style={{ transform: 'scale(1.2)' }} /> : <FsIcon style={{ transform: 'scale(1.2)' }} />}
							</IconButton>
						</div>
					</FullScreen>
				</Dialog>
			)}
		</>
	);
}

export default Dashboard;
