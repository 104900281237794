import React, { useState, useEffect } from 'react';
import { IconButton, Dialog } from '@material-ui/core';
import {
	SettingsOutlined as SettingsIcon,
	FiberManualRecord as DotIcon,
	SpeakerNotesOutlined as NotificationIcon,
	SpeakerNotesOffOutlined as NotificationOffIcon,
	ThreeDRotation as ThreeDIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { materialTableIcons, getFormattedNumber, isValueInsideThresholds } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import { updateSensors, updateUserInfo } from '../redux/actionCreators';
import MaterialTable from './MaterialTableWrapper';
import AlarmEditor from './AlarmEditor';
import ThreeSixtyView from './ThreeSixtyView';
import { SensorGraph } from './SensorGraph2';
import DialogWrapper from './DialogWrapper';

const GET_DIGITALTWIN_TAGS = gql`
	query ($filter: DigitalTwinTagFilter!) {
		getDigitalTwinTags(filter: $filter) {
			digitaltwintagid
			digitaltwinid
		}
	}
`;
const GET_DIGITALTWIN_PERSPECTIVES = gql`
	query ($filter: DigitalTwinPerspectiveFilter!) {
		getDigitalTwinPerspectives(filter: $filter) {
			digitaltwinperspectiveid
			digitaltwinid
			sweep
			rotationx
			rotationy
			zoom
		}
	}
`;
const SET_SENSOR_SUBSCRIPTIONS = gql`
	mutation ($id: String!, $add: Boolean!, $sensorSubscriptions: [Int!]!) {
		addOrRemoveKeycloakUserAttributes(id: $id, add: $add, sensorSubscriptions: $sensorSubscriptions) {
			sensorSubscriptions
		}
	}
`;

const NO_REDIRECT_ID = 'no-redirect';

/**
 * Draws a table of sensors with their current status
 * @param {object[]} sensors : Sensors to list in the table
 * @param {string | undefined} selectedSensorId : Shows sensor-graph and highlights table-row for sensor
 * @param {function} onUpdate : Callback that should be called when sensors have been updated
 * @param {object} style : CSS applied to the root object
 */
function SensorList(props) {
	const [thresholdIdUnderEdit, setThresholdIdUnderEdit] = useState();
	const [showSensorGraph, setShowSensorGraph] = useState(false);
	const [selectedSensor, setSelectedSensor] = useState({});
	const [showAlarmEditor, setShowAlarmEditor] = useState();
	const [show360Popup, setShow360Popup] = useState(false);
	const [digitalTwinSelection, setDigitalTwinSelection] = useState(); // Format: {digitalTwin: object, tagId: number, perspective: object}

	const [getDigitalTwinTags] = useLazyQuery(GET_DIGITALTWIN_TAGS, {
		onCompleted: ({ getDigitalTwinTags }) => {
			if (!getDigitalTwinTags[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinTags[0].digitaltwinid),
				tagId: getDigitalTwinTags[0].digitaltwintagid,
			});
		},
		fetchPolicy: 'no-cache',
	});
	const [getDigitalTwinPerspectives] = useLazyQuery(GET_DIGITALTWIN_PERSPECTIVES, {
		onCompleted: ({ getDigitalTwinPerspectives }) => {
			if (!getDigitalTwinPerspectives[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinPerspectives[0].digitaltwinid),
				perspective: getDigitalTwinPerspectives[0],
			});
		},
		fetchPolicy: 'no-cache',
	});
	const [setSensorSubscriptions] = useMutation(SET_SENSOR_SUBSCRIPTIONS, {
		onCompleted: ({ addOrRemoveKeycloakUserAttributes }) => props.updateUserInfo(addOrRemoveKeycloakUserAttributes),
		onError: e => console.log(e),
	});

	const { t } = useTranslation();

	useEffect(() => {
		if (props.selectedSensorId) {
			setShowSensorGraph(true);
			setSelectedSensor(props.sensors.find(sen => sen.sensorid === props.selectedSensorId) || {});
		}
		// eslint-disable-next-line
	}, [props.selectedSensorId]);

	function toggleSensorSub(sensorId) {
		setSensorSubscriptions({
			variables: {
				id: props.userInfo.id,
				add: !(props.userInfo?.sensorSubscriptions || []).includes(Number(sensorId)),
				sensorSubscriptions: [Number(sensorId)],
			},
		});
	}

	if (props.sensors && props.sensors.length)
		return (
			<>
				<div style={props.style}>
					<MaterialTable
						style={{ overflow: 'hidden', borderRadius: '0.4rem' }}
						columns={[
							{
								title: t('sensorList.status'),
								field: 'status',
								customSort: (a, b) => {
									return a.value / (a.value < a.lowerthreshold ? a.lowerthreshold * 2 : a.upperthreshold) <
										b.value / (b.value < b.lowerthreshold ? b.lowerthreshold * 2 : b.upperthreshold)
										? -1
										: 1;
								},
								render: row => (
									<DotIcon
										style={{
											color: isValueInsideThresholds(row) ? '#1c3' : '#f00',
										}}
									/>
								),
							},
							{ title: t('generic.name'), field: 'name', maxLength: 60 },
							{ title: t('generic.value'), field: 'valueNUnit' },
							{
								title: t('sensorList.alarmThreshholds'),
								field: 'thresholds',
								customSort: (a, b) => {
									return a.lowerthreshold < b.lowerthreshold
										? -1
										: a.lowerthreshold > b.lowerthreshold
											? 1
											: a.upperthreshold < b.upperthreshold
												? -1
												: 1;
								},
								render: row => (
									<>
										{(typeof row.lowerthreshold === 'number' ? Number(row.lowerthreshold.toFixed(2)) : '-') +
											' : ' +
											(typeof row.upperthreshold === 'number' ? Number(row.upperthreshold.toFixed(2)) : '-')}
										<IconButton
											size='small'
											id={NO_REDIRECT_ID}
											onClick={() => {
												setThresholdIdUnderEdit(row.sensorid);
												setShowAlarmEditor(true);
											}}
											style={{
												width: '1.8rem',
												height: '1.75rem',
												zIndex: '999',
												margin: '-0.14rem 0 0 0',
												padding: '0 0',
											}}
										>
											<SettingsIcon style={{ color: '#555', width: '1.1rem' }} />
										</IconButton>
									</>
								),
							},
						]}
						actions={[
							row => {
								const isSubscribed = props.userInfo.sensorSubscriptions?.includes(Number(row.sensorid));
								const iconStyle = { width: '1.5rem', height: '1.5rem', padding: '0.16rem' };
								return {
									icon: () =>
										isSubscribed ? (
											<NotificationIcon style={{ ...iconStyle, color: '#444' }} />
										) : (
											<NotificationOffIcon style={{ ...iconStyle, color: '#bbb' }} />
										),
									tooltip: isSubscribed ? t('sensorList.stopSubscribing') : t('sensorList.startSubscribing'),
									onClick: () => toggleSensorSub(row.sensorid),
								};
							},
							row => ({
								icon: () => <ThreeDIcon style={{ width: '1.5rem', height: '1.5rem', padding: '0.16rem', color: '#444' }} />,
								tooltip: t('sensorList.show3dConnection'),
								onClick: () => {
									if (row.digitaltwintagid)
										getDigitalTwinTags({
											variables: { filter: { digitaltwintagids: [row.digitaltwintagid] } },
										});
									else if (row.digitaltwinperspectiveid)
										getDigitalTwinPerspectives({
											variables: {
												filter: { digitaltwinperspectiveids: [row.digitaltwinperspectiveid] },
											},
										});
								},
								hidden: !row.digitaltwintagid && !row.digitaltwinperspectiveid,
							}),
						]}
						data={props.sensors
							.map(sen => ({
								...sen,
								valueNUnit:
									sen.unit && sen.unit.toLowerCase() === 'kwh'
										? (sen.value % 1 !== 0 ? parseFloat(sen.value).toFixed(2) : sen.value) + ' ' + sen.unit
										: getFormattedNumber(sen.value) + ' ' + (sen.unit || ''),
							}))
							.sort((a, b) =>
								!isValueInsideThresholds(a) && isValueInsideThresholds(b)
									? -1
									: isValueInsideThresholds(a) && !isValueInsideThresholds(b)
										? 1
										: a.name < b.name
											? -1
											: 1
							)}
						icons={materialTableIcons}
						options={{
							toolbar: false,
							paging: false,
							actionsColumnIndex: -1,
							pageSize: props.sensors.length,
							pageSizeOptions: [],
							headerStyle: {
								color: '#000',
								background: '#fff',
								height: '2rem',
								fontWeight: '430',
								fontSize: '90%',
							},
							rowStyle: row => ({
								color: '#444',
								whiteSpace: 'nowrap',
								[row.sensorid === props.selectedSensorId && 'background']: '#ffb',
							}),
						}}
						onRowClick={(event, rowData) => {
							const noRedirectFlag = (event._dispatchInstances || []).some(
								dispatch => dispatch.stateNode?.id === NO_REDIRECT_ID
							);

							if (!noRedirectFlag) {
								setShowSensorGraph(true);
								setSelectedSensor(props.sensors.find(sen => sen.sensorid === rowData.sensorid) || {});
							}
						}}
						localization={{ header: { actions: '' } }}
						headerSelectColor={'#4761ae'}
					/>
				</div>

				<DialogWrapper
					dialogProps={{
						open: showSensorGraph,
						onClose: () => setShowSensorGraph(false),
					}}
				>
					<SensorGraph sensorInfo={selectedSensor} showTitle isVisible={showSensorGraph} />
				</DialogWrapper>

				<AlarmEditor
					sensor={{ ...(props.sensors.find(sen => sen.sensorid === thresholdIdUnderEdit) || {}) }}
					open={Boolean(showAlarmEditor)}
					onExit={() => setShowAlarmEditor(false)}
				/>

				<Dialog
					open={show360Popup}
					onClose={() => setShow360Popup(false)}
					onExited={() => setDigitalTwinSelection()}
					PaperComponent={props => <>{props.children}</>}
				>
					<ThreeSixtyView digitalTwin={digitalTwinSelection?.digitalTwin} selection={digitalTwinSelection} />
				</Dialog>
			</>
		);
	else {
		return <div style={{ textAlign: 'center', margin: '0.2rem 0 1rem 0', paddingLeft: '0.9rem' }}>{t('sensorList.sensorMissing')}</div>;
	}
}

export default connect(getStateVariables(STORE.currentProperty, STORE.userInfo, STORE.digitalTwins), { updateSensors, updateUserInfo })(
	SensorList
);
