import React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

/**
 * Dialog with a physical close-button in top-right corner
 * @param {string} title : Shown at the top
 * @param {object} dialogProps : Passed directly to the Dialog
 * @param {string} width : CSS-width of the Dialog
 * @param {boolean} noPadding : Disable padding around content in the dialog-container
 * @param {boolean} hideBg : Hide white background behind elements
 */
function DialogWrapper(props) {
	return (
		<Dialog
			PaperProps={{
				style: {
					position: 'relative',
					width: props.width || 'auto',
					minWidth: props.width ? 'auto' : '50rem',
					padding: props.noPadding ? '0' : '1.3rem 1.5rem',
					overflow: 'visible',
					[props.hideBg && 'background']: 'none',
					[props.hideBg && 'boxShadow']: 'none',
				},
			}}
			{...props.dialogProps}
		>
			{props.title && <h1 style={{ fontSize: '114%', margin: '0 0 0.2rem 0' }}>{props.title}</h1>}
			{props.children}
			<IconButton
				onClick={() => props.dialogProps?.onClose?.()}
				style={{ position: 'absolute', top: '-0.6rem', right: '-0.6rem', background: '#e8e8e8', padding: '0.5rem' }}
			>
				<CloseIcon />
			</IconButton>
		</Dialog>
	);
}

export default DialogWrapper;
