import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { TextField, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CO_CLASSIFICATIONS, PROP_SELECTION_TYPES } from '../constants';
import { getStateVariables, STORE } from '../redux/selectors';
import { setCORegisters } from '../redux/actionCreators';
import { editObject } from '../utility-functions';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import Select from './SelectWrapper';
import Dialog from './DialogWrapper';
import * as colors from '../colors';

const GET_SENSORS = gql`
	query ($filter: SensorFilter) {
		getSensors(filter: $filter) {
			sensorid
			sensorref
			name
		}
	}
`;
const SET_COREGISTERS = gql`
	mutation ($coregisterids: [ID!]!, $coid: ID, $name: String, $unit: String, $classification: String, $multiplier: Float) {
		setCORegisters(
			coregisterids: $coregisterids
			coid: $coid
			name: $name
			unit: $unit
			classification: $classification
			multiplier: $multiplier
		) {
			coregisterid
			coid
			name
			unit
			classification
			multiplier
		}
	}
`;
const ADD_SHAREDSENSORCOREGISTERS = gql`
	mutation ($refs: [String!]!) {
		addSharedSensorCoregisters(refs: $refs) {
			coregisterid
			coregisterref
			coid
			name
			latestvalue
			latesttimestamp
			unit
			classification
			multiplier
		}
	}
`;

/**
 * A table for Control Object registers that allows them to be filtered and bulk-edited
 */
function CORegisterAdministration(props) {
	const [selectedCOs, setSelectedCOs] = useState([]);
	const [selectedCORegisters, setSelectedCORegisters] = useState([]);
	const [coRegistersUnderEdit, setCORegistersUnderEdit] = useState([]);
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [sensorSelectionPopupEnabled, setSensorSelectionPopupEnabled] = useState(false);
	const [unregSensors, setUnregSensors] = useState([]);
	const [selectedSensors, setSelectedSensors] = useState(false);
	const [classSel, setClassSel] = useState([]);

	useQuery(GET_SENSORS, {
		variables: { filter: { locationids: [null] } },
		skip: props.userInfo?.propertyAccess !== PROP_SELECTION_TYPES.all.id || unregSensors.length || !sensorSelectionPopupEnabled,
		onCompleted: ({ getSensors }) => setUnregSensors(getSensors),
	});
	const [setCORegisters] = useMutation(SET_COREGISTERS, {
		onCompleted: ({ setCORegisters }) => {
			for (const newCOR of setCORegisters)
				editObject(
					props.coRegisters.find(oldCOR => oldCOR.coregisterid === newCOR.coregisterid),
					newCOR
				);
			props.setCORegisters([...props.coRegisters]);
		},
	});
	const [addSharedSensorCoregisters] = useMutation(ADD_SHAREDSENSORCOREGISTERS, {
		onCompleted: ({ addSharedSensorCoregisters }) => props.setCORegisters([...props.coRegisters, ...addSharedSensorCoregisters]),
	});

	const filteredCORegisters = (
		selectedCOs.length ? props.coRegisters.filter(cor => selectedCOs.map(co => co.value).includes(cor.coid)) : props.coRegisters
	).sort((a, b) =>
		a.coid && !b.coid
			? -1
			: !a.coid && b.coid
				? 1
				: a.coid < b.coid ||
					  (a.coid === b.coid && a.name < b.name) ||
					  (a.coid === b.coid && a.name === b.name && a.coregisterref < b.coregisterref)
					? -1
					: 1
	);
	const formattedCOs = props.cos
		.map(co => ({
			value: co.coid,
			label: `${(() => {
				const pro = props.properties.find(pro => String(pro.locationid) === co.locationid);
				return pro ? `${pro.city}: ${pro.street}` : '';
			})()} • ${co.name}`,
		}))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const classSelPlaceholder = classSel.length
		? []
		: [
				Object.values(CO_CLASSIFICATIONS).find(cla => cla.value === coRegistersUnderEdit[0]?.classification?.split(';')[0])?.label,
				Object.values(CO_CLASSIFICATIONS.curve.subClasses).find(
					cla => cla.value === coRegistersUnderEdit[0]?.classification?.split(';')[1]
				)?.label,
				coRegistersUnderEdit[0]?.classification?.split(';')[2],
			];

	const { t } = useTranslation();

	useEffect(() => {
		if (!editPopupEnabled) setClassSel([]);
	}, [editPopupEnabled]);

	return (
		<>
			<div style={{ margin: '1rem 1rem 1.5rem 1rem', display: 'flex' }}>
				<SelectionTable
					localization={{
						title: t('coRegisterAdmin.CORegister'),
						nRowsSelected: t('coRegisterAdmin.nSelectedRegisters') + ': {0}',
					}}
					data={filteredCORegisters.map(cor => ({
						...cor,
						coName: (props.cos.find(co => co.coid === cor.coid) || { name: '' }).name,
					}))}
					dataId='coregisterid'
					onSelectionChange={cors => setSelectedCORegisters(cors)}
					onRowClick={cor => {
						setCORegistersUnderEdit([cor]);
						setEditPopupEnabled(!editPopupEnabled);
					}}
					columns={[
						{ title: t('generic.name'), field: 'name' },
						{ title: t('constants.controlObjects'), field: 'coName' },
						{ title: t('generic.id'), field: 'coregisterref' },
					]}
					tableProps={{ maxColumnLength: 36 }}
				/>

				<SelectionSidebar
					buttons={[
						{
							label: t('coRegisterAdmin.importSensors'),
							onClick: () => setSensorSelectionPopupEnabled(true),
						},
						{
							label: t('genericAdmin.editSelected'),
							onClick: () => {
								setCORegistersUnderEdit(selectedCORegisters);
								setEditPopupEnabled(!editPopupEnabled);
							},
							disabled: !selectedCORegisters.length,
						},
						{
							label: t('genericAdmin.archiveSelected'),
							onClick: () => null,
							disabled: !selectedCORegisters.length,
						},
					]}
					filters={formattedCOs}
					updateSelectedFilters={cos => setSelectedCOs(cos || [])}
					localization={{
						filterTitle: t('coRegisterAdmin.filterRegisters') + ':',
						filterPlaceholder: t('generic.select') + ' ' + t('constants.controlObjects') + '...',
					}}
					style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
				/>
			</div>

			<EditPopup
				text={{
					title:
						coRegistersUnderEdit.length === 1
							? t('generic.edit') +
								' ' +
								t('coRegisterAdmin.register_lowercase') +
								' ' +
								(coRegistersUnderEdit[0].name || t('genericAdmin.withoutName'))
							: t('generic.edit') + ' ' + coRegistersUnderEdit.length + ' ' + t('coRegisterAdmin.selectedRegisters') + '.',
					subtitle:
						t('genericAdmin.selectValuesFor') +
						' ' +
						t('coRegisterAdmin.theSelectedRegister', { count: coRegistersUnderEdit.length }),
				}}
				fields={[
					{
						id: t('generic.name'),
						label: 'Namn',
						placeholder: coRegistersUnderEdit.length === 1 ? coRegistersUnderEdit[0].name : '...',
					},
					{
						id: 'coid',
						label: t('constants.controlObject'),
						placeholder:
							coRegistersUnderEdit.length === 1
								? formattedCOs.find(co => co.value === coRegistersUnderEdit[0].coid)?.label || '...'
								: '...',
						options: formattedCOs.concat({ value: null, label: '-- Inget styrobjekt --' }),
					},
					{
						id: 'unit',
						label: t('generic.unit'),
						placeholder: coRegistersUnderEdit.length === 1 ? coRegistersUnderEdit[0].unit : '...',
					},
					{
						id: 'classification',
						label: t('generic.class'),
						customRender: (
							<div style={{ display: 'flex' }}>
								<div style={{ width: '100%' }}>
									<Select
										selectProps={{
											placeholder: classSelPlaceholder[0],
											options: Object.values(CO_CLASSIFICATIONS),
											onChange: newSel => setClassSel([newSel?.value]),
										}}
									/>
								</div>
								{(classSel[0] === CO_CLASSIFICATIONS.curve.value || classSelPlaceholder[1]) && (
									<div style={{ width: '100%', marginLeft: '0.5rem' }}>
										<Select
											selectProps={{
												placeholder: classSelPlaceholder[1] || 'Axel',
												options: Object.values(CO_CLASSIFICATIONS.curve.subClasses),
												onChange: newSel => setClassSel([classSel[0], newSel?.value]),
											}}
										/>
									</div>
								)}
								{(classSel[1] || classSelPlaceholder[2]) && (
									<TextField
										type='number'
										margin='dense'
										variant='outlined'
										placeholder={classSelPlaceholder[2] || 'Index'}
										fullWidth
										onChange={e => setClassSel([classSel[0], classSel[1], e.target.value])}
										inputProps={{ style: { fontSize: '86%' } }}
										style={{ margin: '0 0 0 0.5rem' }}
									/>
								)}
							</div>
						),
					},
					{
						id: 'multiplier',
						label: t('generic.multiplier'),
						placeholder: coRegistersUnderEdit.length === 1 ? coRegistersUnderEdit[0].multiplier : '...',
					},
				]}
				isOpen={editPopupEnabled}
				canSave={classSel[0] === CO_CLASSIFICATIONS.setpoint.value || classSel[2] || undefined}
				onClose={() => setEditPopupEnabled(!editPopupEnabled)}
				onSave={opts => {
					setCORegisters({
						variables: {
							coregisterids: coRegistersUnderEdit.map(cor => cor.coregisterid),
							name: opts.name,
							coid: opts.coid,
							unit: opts.unit,
							classification:
								classSel[0] === CO_CLASSIFICATIONS.setpoint.value
									? classSel[0]
									: classSel[2]
										? classSel.filter(sel => sel).join(';')
										: undefined,
							multiplier: opts.multiplier && Number(opts.multiplier),
						},
					});
					setEditPopupEnabled(!editPopupEnabled);
				}}
			/>

			<Dialog noPadding dialogProps={{ open: sensorSelectionPopupEnabled, onClose: () => setSensorSelectionPopupEnabled(false) }}>
				<SelectionTable
					localization={{
						title: t('constants.sensors'),
						nRowsSelected: t('coRegisterAdmin.nSelectedSensors') + ': {0}',
					}}
					data={[...props.sensors, ...unregSensors]}
					dataId='sensorid'
					columns={[
						{ title: t('generic.id'), field: 'sensorref' },
						{ title: t('generic.name'), field: 'name' },
						{ title: t('generic.city'), field: 'city' },
						{ title: t('generic.address'), field: 'street' },
					]}
					onSelectionChange={sens => setSelectedSensors(sens)}
					pageSizeOptions={[10, 16, 22]}
					tableProps={{ maxColumnLength: 28 }}
					style={{ boxShadow: 'none' }}
				/>
				<div style={{ display: 'flex', margin: '0.5rem 0.8rem 0.5rem auto' }}>
					<Button color='primary' onClick={() => setSensorSelectionPopupEnabled(false)}>
						{t('generic.cancel')}
					</Button>
					<Button
						onClick={() => {
							setSensorSelectionPopupEnabled(false);
							addSharedSensorCoregisters({ variables: { refs: selectedSensors.map(sen => sen.sensorref) } });
						}}
						style={{ marginLeft: '0.5rem', colors: colors.primary}}
					>
						{t('generic.save')}
					</Button>
				</div>
			</Dialog>
		</>
	);
}

export default connect(getStateVariables(STORE.userInfo, STORE.cos, STORE.coRegisters, STORE.properties, STORE.sensors), {
	setCORegisters,
})(CORegisterAdministration);
