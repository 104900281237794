import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Paper, Tooltip, Dialog as Dialogs, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Lens as CircleIcon, Error as WarningCircleIcon, CheckBox as FavoriteIcon, CheckBoxOutlineBlank as NotFavoriteIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Dialog from '../components/DialogWrapper';
import RegistrationWizardAutomation from '../components/RegistrationWizardAutomation';
import { CENTER_CONTENT_STYLE } from '../constants';// Import RegistrationWizard component
import * as colors from '../colors';
import { getStateVariables, STORE } from '../redux/selectors';
import { updateUserInfo } from '../redux/actionCreators';
import MaterialTable from './MaterialTableWrapper';
import { CustomDivider } from '../components/CustomDivider';


const SET_PROPERTIES = gql`
	mutation ($locationids: [ID]!, $AI: Boolean,) {
		setSensorLocations(
			locationids: $locationids
			AI: $AI
		) {
			locationid
			AI
		}
	}
`;

function PropertyListAutomation(props) {
    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);
    const [openRegistrationDialog, setOpenRegistrationDialog] = useState(false); // State to control opening RegistrationWizard dialog

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const cities = [];
        for (const property of props.properties)
            if (!cities.some(city => city.name === property.city)) cities.push({ name: property.city, selected: true });

        setCities(cities);
    }, [props.properties]);


    const { t } = useTranslation();

    useEffect(() => {
        if (props.properties.length) {
            setIsLoading(false);
        }
    }, [props.properties]);

    function toggleCitySelection(cityName) {
        if (cityName) {
            for (const city of cities)
                if (city.name === cityName) {
                    city.selected = !city.selected;
                    break;
                }
        } else {
            const selectionState = cities.some(city => !city.selected);

            for (const city of cities) city.selected = selectionState;
        }

        setCities([...cities]);
        const selectedCities = cities.filter(city => city.selected).map(city => encodeURIComponent(city.name));
        const queryParams = `selectedCities=${selectedCities.join(',')}&search=${encodeURIComponent(searchValue)}`;
        history.push(`${location.pathname}?${queryParams}`);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const selectedCitiesParam = queryParams.get('selectedCities');
        if (selectedCitiesParam) {
            const selectedCities = selectedCitiesParam.split(',').map(city => decodeURIComponent(city));
            setCities(prevCities =>
                prevCities.map(city => ({
                    ...city,
                    selected: selectedCities.includes(city.name),
                }))
            );
        }
        const savedSearch = queryParams.get('search');
        if (savedSearch) {
            setSearchValue(savedSearch);
        }
    }, [location.search]);

    const handleSearchChange = value => {
        setSearchValue(value);

        const selectedCities = cities.filter(city => city.selected).map(city => encodeURIComponent(city.name));
        const queryParams = `selectedCities=${selectedCities.join(',')}&search=${encodeURIComponent(value)}`;
        history.push(`${location.pathname}?${queryParams}`);
    };

    const handleAddRemoveAIControl = (event, rowData) => {
        event.stopPropagation();
        const isFavorite = filteredPropertyAiIds.includes(rowData.locationid);
        const action = isFavorite ? 'remove' : 'add';
        setActionType(action);
        setSelectedPropertyId(rowData.locationid);
        setConfirmationDialogOpen(true);
    };

    const confirmAddRemoveAIControl = () => {
        if (actionType === 'add') {
            setConfirmationDialogOpen(false);
            setOpenRegistrationDialog(true); // Open RegistrationWizard dialog instead of adding property as favorite
        } else {
            // Remove AI control
        }
    };

    /* const [setProperties] = useMutation(SET_PROPERTIES, {
		onCompleted: ({ setSensorLocations }) => props.updateProperties(setSensorLocations),
	}); */

    const filteredPropertyAiIds = props.properties.filter(property => property.AI === true).map(property => property.locationid);

    const debounceDelay = 1500;

    return (
        <div style={{ width: CENTER_CONTENT_STYLE.width, height: CENTER_CONTENT_STYLE.height, display: 'flex' }}>
            <MaterialTable
                style={{ width: '100%', height: '38.5rem', overflow: 'hidden' }}
                title={t('constants.properties')}
                columns={[
                    {
                        title: 'AI',
                        render: rowData => {
                            const isFavorite = filteredPropertyAiIds.includes(rowData.locationid);
                            const iconStyle = { width: '1.5rem', height: '1.5rem' };
                            return (
                                <div onClick={(event) => handleAddRemoveAIControl(event, rowData)}>
                                    <Tooltip title={isFavorite ? 'Remove AI control' : 'Add AI control'}>
                                        {isFavorite ? (
                                            <FavoriteIcon style={{ ...iconStyle, color: '#33467E', filter: 'drop-shadow(0 0 1px #0004)' }} />
                                        ) : (
                                            <NotFavoriteIcon className='notFavoriteIcon' style={{ ...iconStyle, color: '#33467E' }} />
                                        )}
                                    </Tooltip>
                                </div>
                            );
                        },
                        sorting: false,
                    },
                    { title: t('generic.city'), field: 'city' },
                    { title: t('generic.address'), field: 'street' },
                    { title: t('propertyList.propertyDesignation'), field: 'cadastral' },
                    { title: t('propertyList.squareMetres'), field: 'area' },
                ]}
                data={props.properties
                    .filter(property => cities.find(city => city.name === property.city && city.selected))
                    .map(dat => ({
                        ...dat,
                        area: dat.area ? dat.area + ' m²' : undefined,
                    }))
                    .sort((a, b) => {
                        const aIsFav = filteredPropertyAiIds.includes(a.locationid);
                        const bIsFav = filteredPropertyAiIds.includes(b.locationid);
                        if (aIsFav && !bIsFav) return -1;
                        else if (!aIsFav && bIsFav) return 1;
                        else if (a.city < b.city) return -1;
                        else if (a.city > b.city) return 1;
                        else if (a.street < b.street) return -1;
                        else if (a.street > b.street) return 1;
                        else return a.locationid < b.locationid ? -1 : 1;
                    })}
                localization={{
                    toolbar: { searchTooltip: t('propertyList.search'), searchPlaceholder: t('propertyList.search') },
                    header: { actions: '' },
                    pagination: {
                        labelDisplayedRows: t('propertyList.displayedRows', { from: '{from}', to: '{to}', count: '{count}' }),
                        labelRowsSelect: t('propertyList.rows'),
                        firstTooltip: t('propertyList.firstPage'),
                        previousTooltip: t('propertyList.previousPage'),
                        nextTooltip: t('propertyList.nextPage'),
                        lastTooltip: t('propertyList.lastPage'),
                    },
                }}
                maxColumnLength={26}
                options={{
                    pageSize: 14,
                    pageSizeOptions: [],
                    headerStyle: {
                        backgroundColor: colors.primary0,
                        color: '#fff',
                        height: '3.2rem',
                        fontWeight: '430',
                        fontSize: '98%',
                    },
                    debounceInterval: debounceDelay,
                    search: true,
                    searchText: searchValue,
                }}
                onRowClick={(_event, rowData) => {
                    if (filteredPropertyAiIds.includes(rowData.locationid)) {
                        props.onPropertySelect(rowData);
                    }
                }}
                onSearchChange={handleSearchChange}
            />

            <Dialogs
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
                aria-labelledby="confirmation-dialog-title"
            >
                <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    {actionType === 'add' ? "Activating AI control for this property will result in an additional charge." : "To remove AI control for this property, please contact us."}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    {/* <Button onClick={confirmAddRemoveAIControl} color="primary" autoFocus>
                        Confirm
                    </Button> */}
                    {actionType === 'add' && (
                        <Button onClick={confirmAddRemoveAIControl} color="primary" autoFocus>
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialogs>

            <Dialog
                dialogProps={{
                    open: actionType === 'add' && openRegistrationDialog,
                    onClose: () => setOpenRegistrationDialog(false),
                }}
            >
                <RegistrationWizardAutomation closeWizard={() => {
                    setOpenRegistrationDialog(false);
                    setActionType('');
                }}
                    selectedPropertyId={selectedPropertyId} />
            </Dialog>

            <Paper style={{ marginLeft: '1rem', height: '38.5rem', display: 'flex', flexDirection: 'column' }}>
                <h2 style={{ fontWeight: '400', margin: '1.8rem 0 0.2rem 2.2rem' }}>{t('generic.city_other')}</h2>
                <List style={{ paddingBottom: '0' }}>
                    <ListItem button dense onClick={() => toggleCitySelection()} style={{ padding: '0 4rem 0 1.5rem' }}>
                        <ListItemIcon>
                            <Checkbox checked={cities.every(city => city.selected)} style={{color:colors.primary}} />
                        </ListItemIcon>
                        <ListItemText primary={t('propertyList.selectAll')} />
                    </ListItem>
                    <CustomDivider marginOffset={1.5} marginSideOffset={-2} />
                </List>
                <List style={{ overflowY: 'auto', paddingTop: '0' }}>
                    {cities
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map(city => {
                            return (
                                <ListItem
                                    button
                                    dense
                                    key={city.name}
                                    onClick={() => toggleCitySelection(city.name)}
                                    style={{ padding: '0 3.5rem 0 1.5rem' }}
                                >
                                    <ListItemIcon>
                                        <Checkbox disableRipple checked={city.selected} style={{color:colors.primary}} />
                                    </ListItemIcon>
                                    <ListItemText primary={city.name} />
                                </ListItem>
                            );
                        })}
                </List>
            </Paper>
        </div>
    );
}

export default connect(getStateVariables(STORE.sensors, STORE.externalControlPanels, STORE.userInfo, STORE.properties), { updateUserInfo })(PropertyListAutomation);
