import React, { useState, useEffect, useRef } from 'react';
import { LinearProgress, Radio, RadioGroup, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import { addDays, addHours, differenceInHours, differenceInMinutes, format as formatDate } from 'date-fns';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GRAPH_TYPES, GRAPH_INTERVAL_TYPES, INTERVAL_VALUES, QUERY_STRINGS } from '../constants';
import { HistoryLineChart, HistoryBarCharts, CustomLegends } from './NivoCharts';
import { getLocalDateString, getFormattedNumber } from '../utility-functions';
import { CustomDivider } from './CustomDivider';
import * as colors from '../colors';

const GET_SENSOR_DATA = gql`
	query ($filter: SensorDataFilter!) {
		getSensorData(filter: $filter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

const UNIT_GRAPH_TYPES = Object.freeze({
    default: GRAPH_TYPES.line,
    C: GRAPH_TYPES.line,
    kWh: GRAPH_TYPES.bar,
    MWh: GRAPH_TYPES.bar,
    Wh: GRAPH_TYPES.bar,
    m3: GRAPH_TYPES.bar,
});
const ENDTIME_CHANGED_OFFSET_MINS = 15;

// Returns the index of the graph-type, in the GRAPH_TYPES-object, corresponding to the unit-parameter
function getGraphTypeForUnit(unit) {
    let graphType = UNIT_GRAPH_TYPES['default'];

    if (unit)
        Object.keys(UNIT_GRAPH_TYPES).forEach(key => {
            if (key.toLowerCase() === unit.toLowerCase()) graphType = UNIT_GRAPH_TYPES[key];
        });

    return graphType;
}

// Convert raw data object from database to data object formatted for compatibility with Nivo barcharts
function formatData(rawData, graphType, compare, timeOptions, endtimePoint) {
    const comparisonStartDate = addDays(endtimePoint, -2 * timeOptions.days);

    let formattedData = rawData[0] ? rawData : [{ x: comparisonStartDate, y: graphType === GRAPH_TYPES.bar ? 0 : null }]; // Replace empty data with something usable

    // Number of timespan-interval difference between when the comparison should start and where the first datapoint lies
    const timespanStartDiff = Math.round(
        differenceInHours(new Date(formattedData[0].x), comparisonStartDate) / timeOptions.intervalHours.get(graphType)
    );

    if (timespanStartDiff > 0) {
        const adjustedComparisonStartDate = addHours(
            new Date(formattedData[0].x),
            -timespanStartDiff * timeOptions.intervalHours.get(graphType)
        );

        const missingTimestamps = [];
        for (let timespanI = 0; timespanI < timespanStartDiff; ++timespanI)
            missingTimestamps.push({
                x: addHours(adjustedComparisonStartDate, timeOptions.intervalHours.get(graphType) * timespanI).toISOString(),
                y: null,
            });

        formattedData = missingTimestamps.concat(formattedData);
    }

    const dataEndDate = new Date(formattedData[formattedData.length - 1].x);
    const timespanEndDate = addDays(comparisonStartDate, 2 * timeOptions.days);
    // Number of timespan-interval difference between when the comparison should end and where the last datapoint lies
    const timespanEndDiff = Math.round(differenceInHours(timespanEndDate, dataEndDate) / timeOptions.intervalHours.get(graphType));

    if (timespanEndDiff > 0) {
        const missingTimestamps = [];
        for (let timespanI = 0; timespanI < timespanEndDiff; ++timespanI)
            missingTimestamps.push({
                x: addHours(dataEndDate, timeOptions.intervalHours.get(graphType) * (timespanI + 1)).toISOString(),
                y: null,
            });

        formattedData = formattedData.concat(missingTimestamps);
    }

    // If there is an uneven number of data entries; remove the first entry
    if (formattedData.length % 2 !== 0) formattedData.shift();

    // Format timestamp according to the setting in INTERVAL_VALUES and save the formatted date of the comparison value separately
    formattedData = formattedData.map(element => {
        return {
            // x: formatDate(new Date(element.x), timeOptions.dateFormat),
            x: element.x,
            formattedDate: formatDate(new Date(element.x), 'yyyy-MM-dd HH:mm'), // no timezone
            y: element.y,
            comparisonDate: formatDate(addDays(new Date(element.x), -timeOptions.days), timeOptions.dateFormat),
        };
    });

    const dataCutIndex = Math.floor(formattedData.length / 2);
    let comparisonData = formattedData.slice(0, dataCutIndex);
    let recentData = formattedData.slice(dataCutIndex);

    if (graphType === GRAPH_TYPES.line) {
        comparisonData = {
            id: GRAPH_INTERVAL_TYPES.former,
            data: comparisonData.map((element, elementI) => {
                return { x: recentData[elementI].x, y: element.y, comparisonDate: recentData[elementI].comparisonDate };
            }),
        };
        recentData = { id: GRAPH_INTERVAL_TYPES.current, data: recentData };

        if (compare) formattedData = [comparisonData, recentData];
        else formattedData = [recentData];
    } else if (graphType === GRAPH_TYPES.bar) {
        if (!compare)
            formattedData = recentData.map(element => {
                return { Datum: element.x, [GRAPH_INTERVAL_TYPES.current]: element.y };
            });
        else
            formattedData = recentData.map((element, elementI) => {
                return {
                    Datum: element.x,
                    [GRAPH_INTERVAL_TYPES.current]: element.y,
                    [GRAPH_INTERVAL_TYPES.former]: comparisonData[elementI].y,
                    comparisonDate: element.comparisonDate,
                };
            });
    }

    return formattedData;
}

/**
 * Retrieves alarm value and date from query parameters
 * @param {string} sensorId unique identifier for sensor
 * @returns {{date: Date, value: Number}}
 */
function getAlarm(sensorId) {
    const searchParams = new URLSearchParams(window.location.search);
    const sensorIdFromUrl = searchParams.get(QUERY_STRINGS.sensorId.id);
    if (sensorId === sensorIdFromUrl) {
        const alarm = {
            date: new Date(searchParams.get(QUERY_STRINGS.alarm.date)),
            value: Number(searchParams.get(QUERY_STRINGS.alarm.value)),
        };
        if (alarm.date && !isNaN(alarm.value)) return alarm;
    }
}

const iconStyle = {
    width: '1.2rem',
    height: '1.2rem',
};

const iconButtonStyle = {
    border: 'solid',
    borderWidth: '1px',
    borderColor: '#0004',
    color:colors.primary
};

/**
 * Draws a graph for the historical values of a sensor, with options for changing time intervals and toggling time-comparison
 * @param {object} sensorInfo : Information about the sensor in question
 * @param {boolean} isVisible : Is the parent component showing the graph
 * @param {([minY: number, maxY: number])} updateDataRange : Called with the minimum and and maximum data-points that are displayed in the graph
 * @param {() => void} onLoaded : Called when the graph has finished loading
 * @param {[min: number, max: number]} yRange : Override the default yScale by forcing the specific range of Y-values to in focus
 * @param {boolean} yRange : Override the default yScale by forcing the specific range of Y-values to in focus, [min, max]
 * @param {boolean} showTitle : Whether to show a title with sensor-name and latest-value above the graph
 */
function SensorGraphResults(props) {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [intervalRadioValue, setIntervalRadioValue] = useState(Object.keys(INTERVAL_VALUES)[getAlarm(props.sensorInfo.sensorid) ? 0 : 1]); // First key ('none') in the object
    const [comparisonSwitch, setComparisonSwitch] = useState(false);
    const [rawData, setRawData] = useState();
    const [graphType] = useState(getGraphTypeForUnit(props.sensorInfo.unit));
    const [getSensorData, sensorDataQuery] = useLazyQuery(GET_SENSOR_DATA);
    const [alarm, setAlarm] = useState(getAlarm(props.sensorInfo.sensorid));
    const isMounted = useRef(true);
    const [endtimeRefPoint, setEndtimeRefPoint] = useState(() => {
        const alarmData = getAlarm(props.sensorInfo.sensorid);
        const now = new Date();
        if (alarmData) {
            const refPointOffset = addHours(alarmData.date, 12);
            return refPointOffset > now ? now : refPointOffset;
        } else return now;
    });
    const [hasBrowsed, setHasBrowsed] = useState(false);


    const chartProps = { animate: false, isInteractive: false, useMesh: false };


    const { t } = useTranslation();

    /* 	useEffect(() => {
            return () => (isMounted= false);
        }, []); */

    // Called when the graph becomes visible for the first time
    useEffect(() => {
        if (props.isVisible && !sensorDataQuery.called) getData();
        // eslint-disable-next-line
    }, [props.isVisible]);

    // Fetches different data when the intervalRadioValue was changed
    useEffect(() => {
        if (props.isVisible && sensorDataQuery.called) getData();
        // eslint-disable-next-line
    }, [intervalRadioValue]);

    // Fetches different data when endtimeRefPoint changes
    useEffect(() => {
        const alarmParam = getAlarm(props.sensorInfo.sensorid);
        if (alarmParam) {
            const start = addDays(endtimeRefPoint, -INTERVAL_VALUES[intervalRadioValue].days);
            if (start <= alarmParam.date && alarmParam.date <= endtimeRefPoint) setAlarm(alarmParam);
            else setAlarm(undefined);
        }
        if (props.isVisible && sensorDataQuery.called) getData();
        // eslint-disable-next-line
    }, [endtimeRefPoint]);

    // Calls the props.updateDataRange([lower, upper]) with the new min/max values when the graph-data is updated
    useEffect(() => {
        if (typeof props.updateDataRange === 'function') {
            let range = [undefined, undefined];
            const updateRange = value => {
                if (typeof value !== 'number') return;
                if (value < range[0] || range[0] === undefined) range[0] = value;
                if (value > range[1] || range[1] === undefined) range[1] = value;
            };

            if (graphType === GRAPH_TYPES.bar) {
                for (const dat of data) {
                    if (comparisonSwitch) updateRange(dat[GRAPH_INTERVAL_TYPES.former]);

                    updateRange(dat[GRAPH_INTERVAL_TYPES.current]);
                }
            } else {
                if (comparisonSwitch && data[0] && data[0].data) for (const dat of data[0].data) updateRange(dat.y);

                if (data[1] && data[1].data) for (const dat of data[1].data) updateRange(dat.y);
            }

            if (range[0] !== undefined && range[1] !== undefined) props.updateDataRange(range);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (!sensorDataQuery.loading && sensorDataQuery.data) {
            if (isMounted.current) {
                const newRawData = sensorDataQuery.data.getSensorData[0].sensordata.map(item => ({
                    x: item.x.replace(' ', 'T'),
                    y: item.y && item.y * (props.sensorInfo.multiplier || 1),
                }));

                if (alarm?.date && graphType === GRAPH_TYPES.line && intervalRadioValue === 'day')
                    newRawData.reduce((res, cur) => {
                        const oldDiff = Math.abs(differenceInMinutes(new Date(res.x), alarm.date));
                        const newDiff = Math.abs(differenceInMinutes(new Date(cur.x), alarm.date));
                        return oldDiff < newDiff ? res : cur;
                    }, newRawData[0]).y = alarm.value;

                setRawData(newRawData);
                setLoaded(true);
                setData(formatData(newRawData, graphType, comparisonSwitch, INTERVAL_VALUES[intervalRadioValue], endtimeRefPoint));
                if (typeof props.onLoaded === 'function') props.onLoaded();
            }
        }
        // eslint-disable-next-line
    }, [sensorDataQuery.data]);

    // Getting sensorvalues based on sensorid
    function getData() {
        getSensorData({
            variables: {
                filter: {
                    sensorids: [Number(props.sensorInfo.sensorid)],
                    timestamp_gte: getLocalDateString(
                        // Get twice the amount of days that the longest radio option supports, to allow comparison with the timeframe before
                        addDays(endtimeRefPoint, -2 * INTERVAL_VALUES[intervalRadioValue].days)
                    ),
                    timestamp_lte: getLocalDateString(endtimeRefPoint),
                    timestamp_interval: INTERVAL_VALUES[intervalRadioValue].intervalHours.get(graphType) * 60,
                },
            },
        });
    }

    function getBrowseFormat(date) {
        if (intervalRadioValue === 'day') return formatDate(date, 'd LLL');
        else if (intervalRadioValue === 'week') return `v. ${formatDate(date, 'w')}`;
        else if (intervalRadioValue === 'month') return formatDate(date, 'LLL');
        else if (intervalRadioValue === 'year') return formatDate(date, 'yyyy');
        else return `${date}`;
    }

    function formatDateForGraph(newValue, now) {
        console.log('in', newValue, now);
        if (newValue === 'day') {
            now.setHours(0, 0, 0, 0);
        }
        if (newValue === 'week') {
            const current_day = now.getDay();
            now.setDate(now.getDate() - current_day);
            now.setHours(0, 0, 0, 0);
        }
        if (newValue === 'month') {
            const current_day = now.getDate();
            now.setDate(now.getDate() - current_day);
            now.setHours(0, 0, 0, 0);
        }
        if (newValue === 'year') {
            const current_month = now.getMonth();
            now.setMonth(now.getMonth() - current_month);
            now.setHours(0, 0, 0, 0);
        }
        console.log('out', newValue, now);
        return now;
    }

    function onIntervalChange(newValue) {
        if (!hasBrowsed) {
            const now = formatDateForGraph('month', new Date());
            setEndtimeRefPoint(now);
        }
        // going to smaller intervals, zooming in
        else if (INTERVAL_VALUES[intervalRadioValue].days > INTERVAL_VALUES[newValue].days) {
            const dayOffset = Math.floor(-INTERVAL_VALUES[intervalRadioValue].days / 2 + INTERVAL_VALUES[newValue].days / 2);
            let proposedEndtimeRef = addDays(endtimeRefPoint, dayOffset);
            proposedEndtimeRef = formatDateForGraph(newValue, proposedEndtimeRef);
            setEndtimeRefPoint(proposedEndtimeRef);
        }
        // zooming out
        else if (INTERVAL_VALUES[intervalRadioValue].days < INTERVAL_VALUES[newValue].days) {
            const dayOffset = Math.ceil(-INTERVAL_VALUES[intervalRadioValue].days / 2 + INTERVAL_VALUES[newValue].days / 2);
            let proposedEndtimeRef = addDays(endtimeRefPoint, dayOffset);
            proposedEndtimeRef = formatDateForGraph(newValue, proposedEndtimeRef);
            setEndtimeRefPoint(proposedEndtimeRef);
        }
        setIntervalRadioValue(newValue);
    }

    function onComparisonToggle() {
        setData(formatData(rawData, graphType, !comparisonSwitch, INTERVAL_VALUES[intervalRadioValue], endtimeRefPoint));
        setComparisonSwitch(!comparisonSwitch);
    }

    function browse(increment) {
        const now = new Date();
        setHasBrowsed(true);

        // User presses forward
        if (increment === true) {
            const newPoint = Math.min(addDays(endtimeRefPoint, INTERVAL_VALUES[intervalRadioValue].days), now);
            setEndtimeRefPoint(newPoint);
            if (differenceInMinutes(new Date(), newPoint) < ENDTIME_CHANGED_OFFSET_MINS) setHasBrowsed(false);
        } else {
            setEndtimeRefPoint(addDays(endtimeRefPoint, -INTERVAL_VALUES[intervalRadioValue].days));
        }
    }

    if (loaded) {
        return (
            <>
                {props.showTitle && (
                    <>
                        <div style={{ display: 'flex' }}>
                            <h1 style={{ fontSize: '148%', fontWeight: '400', color: '#000d', margin: 'auto auto 0 0.3rem' }}>
                                {props.sensorInfo.name}
                            </h1>
                            { props.showValue !== true && (<h1 style={{ fontSize: '135%', fontWeight: '400', color: '#000b', margin: 'auto 0.5rem 0 auto' }}>
                                {/* {getFormattedNumber(props.sensorInfo.value) + ' ' + (props.sensorInfo.unit || '')} */}
                                {props.sensorInfo.unit && props.sensorInfo.unit.toLowerCase() === 'kwh' ? (props.sensorInfo.value % 1 !== 0 ? parseFloat(props.sensorInfo.value).toFixed(2) : props.sensorInfo.value) + ' ' + props.sensorInfo.unit : getFormattedNumber(props.sensorInfo.value) + ' ' + (props.sensorInfo.unit || '')}
                            </h1>)}
                        </div>
                        <CustomDivider disableEndMargin />
                    </>
                )}

                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ height: '8.5rem', width: '100%', margin: '0 1rem 0 0' }}>
                        {graphType === GRAPH_TYPES.line ? (
                            <HistoryLineChart
                                data={data}
                                timeOptions={INTERVAL_VALUES[intervalRadioValue]}
                                sensorInfo={props.sensorInfo}
                                yRange={props.yRange}
                                alarm={alarm}
                                maxLegends={0}
                                margin={{ right: 0 }}
                                height={'8.5rem'}
                            />
                        ) : (
                            <HistoryBarCharts
                                data={data}
                                timeOptions={INTERVAL_VALUES[intervalRadioValue]}
                                sensorInfo={props.sensorInfo}
                                yRange={props.yRange}
                                maxLegends={0}
                                margin={{ right: 0 }}
                                chartProps={chartProps}
                                height={'8.5rem'}
                               /*  chartProps={{
                                    [!comparisonSwitch && 'keys']: [GRAPH_INTERVAL_TYPES.current],
                                    [comparisonSwitch && INTERVAL_VALUES[intervalRadioValue]?.days > 24 && 'padding']: 0.05,
                                }}
                                alarm={alarm?.date && alarm?.value ? alarm : undefined}
                             *//>
                        )}
                    </div>
                    {graphType === GRAPH_TYPES[3] && (
                        <div style={{ margin: '0 0 0 -10.0rem', zIndex: '999', width: '9rem' }}>
                            <h3 style={{ fontWeight: '400', margin: '0.6rem 0 0.4rem 0.8rem' }}>{t('sensorGraph.timePeriod')}</h3>
                            <RadioGroup value={intervalRadioValue} onChange={event => onIntervalChange(event.target.value)}>
                                {Object.entries(INTERVAL_VALUES).map(([key, value]) => (
                                    <FormControlLabel
                                        value={key}
                                        control={<Radio />}
                                        label={value.label}
                                        key={key}
                                        style={{ margin: '-0.2rem 0', color:colors.primary}}
                                    />
                                ))}
                            </RadioGroup>

                            <h3 style={{ fontWeight: '400', margin: '1.6rem 0 0.4rem 0.8rem' }}>{t('sensorGraph.moveTimePeriod')}</h3>
                            <div style={{ marginLeft: '0.75rem', display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                    size='small'
                                    style={iconButtonStyle}
                                    onClick={() => {
                                        browse(false);
                                    }}
                                >
                                    <ArrowBackIcon style={iconStyle} />
                                </IconButton>
                                <span style={{ width: '3rem', textAlign: 'center', color: '#0008', fontSize: '85%' }}>
                                    {getBrowseFormat(addDays(endtimeRefPoint, -INTERVAL_VALUES[intervalRadioValue].days / 2))}
                                </span>
                                <IconButton
                                    size='small'
                                    style={iconButtonStyle}
                                    onClick={() => {
                                        browse(true);
                                    }}
                                    disabled={differenceInMinutes(new Date(), endtimeRefPoint) < ENDTIME_CHANGED_OFFSET_MINS}
                                >
                                    <ArrowForwardIcon style={iconStyle} />
                                </IconButton>
                                <IconButton
                                    size='small'
                                    onClick={() => {
                                        setHasBrowsed(false);
                                        setEndtimeRefPoint(new Date());
                                    }}
                                    disabled={differenceInMinutes(new Date(), endtimeRefPoint) < ENDTIME_CHANGED_OFFSET_MINS}
                                    style={{ ...iconButtonStyle, marginLeft: '0.25rem' }}
                                >
                                    <RefreshIcon style={iconStyle} />
                                </IconButton>
                            </div>

                            <div style={{ margin: '1.3rem 0 0 0.8rem' }}>
                                <h3 style={{ display: 'inline', fontWeight: '400', margin: '0.5rem 0 0.4rem 0' }}>
                                    {t('sensorGraph.compare')}
                                </h3>
                                <Switch
                                    checked={comparisonSwitch}
                                    onChange={onComparisonToggle}
                                    value={comparisonSwitch}
                                    style={{color:colors.primary}}
                                />
                            </div>

                            <CustomLegends
                                legends={[{ label: t('sensorGraph.alarmThreshhold'), color: '#FF5014' }]}
                                style={{ margin: '1.7rem 0 0 11px' }}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    } else {
        // Use id instead of style because duplicate keys are required
        return <LinearProgress style={{ margin: '0.6rem', height: '0.35rem' }} id='linearProgressBar' />;
    }
}

export { SensorGraphResults };
