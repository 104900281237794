import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStateVariables, STORE } from '../redux/selectors';
import { SensorGraphResults } from './SensorGraphResults';
import GaugeComponent from 'react-gauge-component'
import { gql, useQuery } from '@apollo/client';
import { getFormattedNumber } from '../utility-functions';

const GET_AI_CONTROL = gql`
  query GetAiControls($filter: AiControlFilter) {
    getAiControls(filter: $filter) {
      index
      locationid
      system
      ducid
      name
      sensorid
      communicationtype
      sensortype
      value
      minimum
      maximum
    }
  }
`;


function Results(props) {

  const AiControlQuery = useQuery(GET_AI_CONTROL, {
    variables: {
      filter: {
        locationid: [props.currentProperty],
        // Add other filters if needed
      },
    },
  });
  const filteredAiControl = AiControlQuery.data?.getAiControls.filter(control => control.sensortype !== 'indoortemp' && control.sensortype !== 'electricity') || [];
  const _propertySensors = props.sensors.filter(sen => sen.locationid === props.currentProperty);
  const matchedSensors = _propertySensors.filter(propertySensor =>
    filteredAiControl.some(aiControl => aiControl.sensorid === propertySensor.sensorid)
  );
 

const filteredAiControls = AiControlQuery.data?.getAiControls.filter(control => control.sensortype === 'indoortemp' && control.locationid === props.currentProperty.toString()) || [];
const matchedSensorss = filteredAiControls.map(control => props.sensors.find(sensor => sensor.sensorid === control.sensorid)).filter(sensor => sensor);

const average = matchedSensorss.length > 0
    ? getFormattedNumber(matchedSensorss.reduce((sum, sensor) => sum + sensor.value, 0) / matchedSensorss.length)
    : undefined;

  const electricitySensor = AiControlQuery.data?.getAiControls.filter(sensor => sensor.sensortype === 'electricity' && sensor.locationid === props.currentProperty.toString() ) || [];
  const propertyElectricitySensors = props.sensors.filter(sen => sen.locationid === props.currentProperty);
  const matchedElectricitySensors = propertyElectricitySensors.filter(propertySensor =>
    electricitySensor.some(aiControl => aiControl.sensorid === propertySensor.sensorid)
  );

 const adjustedSensorValue = average > 25 ? average : 25;

  return (
    props.currentProperty === props.currentProperty ? (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {matchedElectricitySensors.map((sensor) => (
          <div
            key={sensor.sensorid}
            style={{
              flex: '0 0 30%',
              maxWidth: '100%',
              height: '100%'
            }}
          >
            <SensorGraphResults sensorInfo={sensor} showTitle showValue isVisible={true} />
            

          </div>
        ))}
        <div>
          <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: 'black' }}>
            Energy Performance
          </div>
          <GaugeComponent
            type="radial"
            arc={{
              width: 0.2,
              padding: 0.005,
              cornerRadius: 1,
              gradient: true,
              subArcs: [
                {
                  limit: 25,
                  color: '#EA4228',
                  showTick: true
                },
                /* {
                  limit: 40,
                  color: '#EA4228',
                  showTick: true
                }, */
                {
                  limit: 75,
                  color: '#F5CD19',
                  showTick: true
                },
                /*  {
                   limit: 80,
                   color: '#F5CD19',
                   showTick: true
                 }, */
                {
                  limit: 100,
                  color: '#5BE12C',
                  showTick: true
                },
              ]
            }}
            pointer={{
              color: '#345243',
              length: 0.80,
              width: 15,
              // elastic: true,
            }}
            labels={{
              valueLabel: { formatTextValue: value => value + '%'/* 'ºC' */ },
              tickLabels: {
                type: 'outer',
                valueConfig: { formatTextValue: value => value + '%'/* 'ºC' */, fontSize: 10 },
                ticks: [
                  { value: 72},
                ],
              }
            }}
            value={72}
            style={{ width: '250px', height: '200px' }}
          />
        </div>
        <div /* style={{ display: 'flex', flexWrap: 'wrap',  backgroundColor: '#505050',  margin: '30px' }} */>
        <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: 'black' }}>
        Average Indoor temp 
          </div>
          <GaugeComponent
            type="semicircle"
            arc={{
              width: 0.29,
              padding: 0.001,
              cornerRadius: 1,
              // gradient: true,
              subArcs: [
                {
                  limit: 19,
                  color: '#EA4228',
                  showTick: true,
                  tooltip: {
                    text: 'Too low temperature!'
                  },
                },
                {
                  limit: 20.5,
                  color: '#F5CD19',
                  showTick: true,
                  tooltip: {
                    text: 'Low temperature!'
                  }
                },
                {
                  limit: 22.5,
                  color: '#5BE12C',
                  showTick: true,
                  tooltip: {
                    text: 'Normal temperature!'
                  }
                },
                {
                  limit: 24, color: '#F5CD19', showTick: true,
                  tooltip: {
                    text: 'High temperature!'
                  }
                },
                {
                  color: '#EA4228',
                  tooltip: {
                    text: 'Too high temperature!'
                  }
                }
              ]
            }}
            pointer={{
              color: '#345243',
              length: 0.80,
              width: 15,
              // elastic: true,
            }}
            labels={{
              valueLabel: { formatTextValue: value => value + 'ºC' },
              tickLabels: {
                type: 'outer',
                valueConfig: { formatTextValue: value => value + 'ºC', fontSize: 10 },
                ticks: [
                  { value: 18 },
                  { value: average },
                  { value: 23 }
                ],
              }
            }}
            value={average || 21}
            minValue={17.5}
            maxValue={adjustedSensorValue}
          /*  style={{ height: '200px', width: '200px' }} */
          />
        </div>
        {matchedSensors.map((sensor, index) => (
          <div
            key={sensor.sensorid}
            style={{
              flex: index === 10 ? '0 0 70%' : '0 0 50%',
              maxWidth: index === 10 ? '70%' : '50%',
              height: index === 10 ? 'auto' : '10%'
            }}
          >
            <SensorGraphResults sensorInfo={sensor} showTitle isVisible={true} />

          </div>
        ))}

      </div>
    ) : null

  );

}

export default connect(getStateVariables(STORE.sensors, STORE.currentProperty))(Results);
