import React, { useEffect, useState } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';
import { Divider, Paper, CircularProgress, Checkbox } from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { CENTER_CONTENT_STYLE, PAGES, PROPERTY_TABS, QUERY_STRINGS } from '../constants';
import { isValueInsideThresholds } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import houseGreen from '../assets/house-green.png';
import houseRed from '../assets/house-red.png';
import * as colors from '../colors';
import i18n from '../i18n';

const GET_ALARM_VIEW = gql`
	query ($filter: AlarmFilter) {
		getAlarmView(filter: $filter) {
			sensorid
			locationid
			name
			earliestvalue
			earliesttimestamp
			maxvalue
			maxtimestamp
			unit
			upperthreshold
			multiplier
		}
	}
`;
const GET_ECP_ALARMS = gql`
	query ($filter: EcpAlarmFilter) {
		getEcpAlarms(filter: $filter) {
			ecpalarmid
			externalcontrolpanelid
			label
			earliesttimestamp
			confirmtimestamp
		}
	}
`;

const MAX_POPUP_ALARM_COUNT = 3;
const DEFAULT_COORDS = { lat: 56.879005, lng: 14.805852 };

let onPopupClick; // Reference to member-function of Maps

const PopupMarker = ({ position, icon, street, city, alarms, ecpAlarms, locationid }) => {
	return (
		<Marker position={position} icon={icon}>
			<Popup>
				<div
					onClick={() => onPopupClick(locationid)}
					style={{ color: '#025', fontSize: '110%', fontWeight: 700, lineHeight: 1.6, margin: '0.5rem 0', cursor: 'pointer' }}
				>
					{street}
					<br />
					{city}
				</div>
				{alarms.length ? (
					<div>
						<Divider style={{ margin: '0 -0.25rem', height: '0.06rem' }} />
						<div style={{ color: '#222', fontSize: '108%', fontWeight: 700, margin: '0.5rem 0' }}>{i18n.t('maps.alarms')}</div>
					</div>
				) : null}
				{[...alarms, ...ecpAlarms]
					.sort((a, b) => ((a.maxtimestamp || a.earliesttimestamp) > (b.maxtimestamp || a.earliesttimestamp) ? -1 : 1))
					.slice(0, MAX_POPUP_ALARM_COUNT)
					.map(alarm => (
						<Paper
							elevation={0}
							key={alarm.sensorid || `ecp-${alarm.ecpalarmid}`}
							onClick={() => onPopupClick(locationid, alarm)}
							style={{
								padding: '0.3rem 0.4rem',
								margin: '0.7rem -0.3rem',
								backgroundColor: '#f4f4f4',
								lineHeight: 1.5,
								cursor: 'pointer',
								...(alarm.ecpalarmid ? { border: 'dashed', borderWidth: '1px', borderColor: '#000a' } : {}),
							}}
						>
							<div style={{ color: '#025', fontWeight: 700 }}>{alarm.name || alarm.label}</div>
							{!alarm.ecpalarmid && (
								<div>
									{Number((alarm.maxvalue * (alarm.multiplier || 1)).toFixed(2)) + ' ' + (alarm.unit || '')}
									<br />
								</div>
							)}
							{alarm.maxtimestamp || alarm.earliesttimestamp
								? (alarm.maxtimestamp || alarm.earliesttimestamp)
										.substring(0, String(alarm.maxtimestamp || alarm.earliesttimestamp).length - 3)
										.replace('T', ' ')
								: ''}
						</Paper>
					))}
				{alarms.length > MAX_POPUP_ALARM_COUNT && (
					<div style={{ color: '#222', fontSize: '108%', fontWeight: 700 }}>
						+ {alarms.length - 3} {i18n.t('maps.additionalAlarms')}
					</div>
				)}
			</Popup>
		</Marker>
	);
};

const greenIcon = new Leaflet.icon({
	iconUrl: houseGreen,
	iconSize: [24, 24],
	iconAnchor: [12, 12],
	popupAnchor: [0, -20],
});

const redIcon = new Leaflet.icon({
	iconUrl: houseRed,
	iconSize: [24, 24],
	iconAnchor: [12, 12],
	popupAnchor: [0, -20],
});

function Maps(props) {
	const [hasLoaded, setHasLoaded] = useState(false);
	const [location, setLocation] = useState(DEFAULT_COORDS);
	const [alarms, setAlarms] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [filteredMarkers, setFilteredMarkers] = useState([]);
	const [zoomLevel] = useState(6); // Leaflet (Map) throws error if the map is unloaded while the zoom-changing animation is still playing
	const [showAlarmsOnly, setShowAlarmsOnly] = useState(false);
	const [locationidsWAlarms, setLocationidsWAlarms] = useState([]);
	const [ecpAlarms, setEcpAlarms] = useState([]);

	const { t } = useTranslation();

	useQuery(GET_ALARM_VIEW, {
		onCompleted: response => {
			setHasLoaded(true);
			setAlarms(response.getAlarmView || []);
		},
	});
	useQuery(GET_ECP_ALARMS, {
		skip: !props.externalControlPanels.length,
		variables: { filter: { externalcontrolpanelids: props.externalControlPanels.map(ecp => ecp.externalcontrolpanelid) } },
		onCompleted: ({ getEcpAlarms }) => setEcpAlarms(getEcpAlarms),
	});

	useEffect(() => {
		setLocationidsWAlarms(
			[
				...props.sensors.filter(sen => !isValueInsideThresholds(sen)),
				...props.externalControlPanels.filter(ecp =>
					ecpAlarms.some(ala => !ala.confirmtimestamp && ala.externalcontrolpanelid === Number(ecp.externalcontrolpanelid))
				),
			].map(obj => Number(obj.locationid))
		);
		// eslint-disable-next-line
	}, [props.sensors, ecpAlarms]);

	useEffect(() => {
		if (props.properties.length) setLocation({ lng: props.properties?.[0]?.longitude, lat: props.properties?.[0]?.latitude });
	}, [props.properties]);

	// Filter locations to draw on map based on alarm filter level
	useEffect(() => {
		setFilteredMarkers(showAlarmsOnly ? markers.filter(mark => locationidsWAlarms.includes(mark.locationid)) : markers);
		// eslint-disable-next-line
	}, [markers, showAlarmsOnly]);

	onPopupClick = (locationid, alarm) => {
		props.history.push(
			`/${PAGES.properties.id}/${locationid}/` +
				(alarm?.ecpalarmid
					? PROPERTY_TABS.externalControlPanels.id
					: `${PROPERTY_TABS.meters.id}${
							alarm
								? `?${QUERY_STRINGS.sensorId.id}=${alarm.sensorid}&${QUERY_STRINGS.alarm.date}=${alarm.earliesttimestamp}&${QUERY_STRINGS.alarm.value}=${alarm.earliestvalue}`
								: ''
						}`)
		);
	};

	useEffect(() => {
		setMarkers(
			props.properties
				.filter(prop => prop.latitude && prop.longitude)
				.map(prop => ({
					...prop,
					alarms: alarms.filter(alarm => alarm.locationid === prop.locationid),
					ecpAlarms: ecpAlarms.filter(
						ecpA =>
							props.externalControlPanels.find(ecp => ecp.externalcontrolpanelid === String(ecpA.externalcontrolpanelid))
								?.locationid === String(prop.locationid)
					),
					icon: locationidsWAlarms.includes(prop.locationid) ? redIcon : greenIcon,
					position: [prop.latitude, prop.longitude],
					key: prop.locationid,
				}))
		);
	}, [props.properties, props.externalControlPanels, alarms, ecpAlarms, locationidsWAlarms]);

	return (
		<div style={{ position: 'relative' }}>
			<Map
				center={[location.lat || DEFAULT_COORDS.lat, location.lng || DEFAULT_COORDS.lng]}
				zoom={zoomLevel}
				style={{
					height: '42rem',
					width: CENTER_CONTENT_STYLE.width,
					boxShadow: '0 0 6px #aaa',
					borderRadius: '0.25rem',
				}}
			>
				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>
				{filteredMarkers.map(({ key, ...props }) => (
					<PopupMarker key={key} {...props} />
				))}
			</Map>

			<Paper
				elevation={5}
				style={{
					position: 'absolute',
					width: '11rem',
					height: '4.2rem',
					top: '0.6rem',
					right: '0.6rem',
					opacity: '0.9',
					zIndex: '400',
				}}
			>
				<h3 style={{ marginTop: '0.4rem', textAlign: 'center' }}>{t('maps.propertyFilter')}</h3>
				<div style={{ display: 'flex', alignItems: 'center', margin: '0.3rem auto', width: 'max-content' }}>
					<div>{t('maps.onlyAlarmed')}</div>
					<Checkbox
						checked={showAlarmsOnly}
						onChange={(e, val) => setShowAlarmsOnly(val)}
						style={{ padding: '0', marginLeft: '0.3rem', color: colors.primary}}
					/>
				</div>
			</Paper>

			<div
				style={{
					visibility: hasLoaded ? 'hidden' : 'visible',
					position: 'absolute',
					width: '18rem',
					top: '11.5rem',
					left: '21.5rem',
					zIndex: '400',
				}}
			>
				<CircularProgress style={{ width: '100%', height: '100%' }} />
			</div>
		</div>
	);
}

export default withRouter(connect(getStateVariables(STORE.sensors, STORE.properties, STORE.externalControlPanels))(Maps));
