import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CENTER_CONTENT_STYLE } from '../constants';
import { IconButton, Button } from '@material-ui/core';
import { Fullscreen as FsIcon, FullscreenExit as FsExitIcon, OpenInNew as NewTabIcon } from '@material-ui/icons';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import * as colors from '../colors';

/**
 * Embedded external control-panel view
 * @param {object} ecp : The external control-panel to be displayed
 * @returns
 */
function ExternalControlPanel(props) {
	const [isFs, setIsFs] = useState(false);
	const fsHandle = useFullScreenHandle();
	useEffect(() => {
		if (isFs && !fsHandle.active) fsHandle.enter();
		else if (!isFs && fsHandle.active) fsHandle.exit();
		// eslint-disable-next-line
	}, [isFs]);

	const ecpSrc =
		window.location.host.includes('meliox.') && props.ecp.url.includes('melioxdev.')
			? props.ecp.url.replace('melioxdev.', 'meliox.')
			: window.location.host.includes('melioxdev.') && props.ecp.url.includes('meliox.')
				? props.ecp.url.replace('meliox.', 'melioxdev.')
				: props.ecp.url;
	const isAbelko = ecpSrc.includes('/login.fcgi');

	const { t } = useTranslation();

	return (
		<div
			style={{
				width: `calc(${CENTER_CONTENT_STYLE.width} - 4rem)`,
				height: isAbelko ? '17rem' : '40rem',
				margin: '0 auto 2rem',
				position: 'relative',
			}}
		>
			<FullScreen handle={fsHandle} onChange={state => setIsFs(state)}>
				{isAbelko && (
					<div
						style={{
							position: 'absolute',
							background: '#fff',
							borderRadius: '.5rem',
							padding: '1.5rem',
							top: '4.6rem',
							left: '18rem',
							boxShadow: '0 0 0.5rem #0003',
						}}
					>
						<div>{t('externalControlPanel.bmsOpenNewWindowPromt')}</div>
						<Button
							variant='outlined'
							onClick={() => window.open(ecpSrc, '_blank').focus()}
							style={{ margin: '0.8rem auto auto 5.0rem', color: colors.primary }}
						>
							{t('externalControlPanel.openBms')}
						</Button>
					</div>
				)}

				<IconButton
					onClick={() => window.open(ecpSrc, '_blank').focus()}
					style={{
						position: 'absolute',
						top: '2.1rem',
						right: '2.5rem',
						width: '2.5rem',
						height: '2.5rem',
						color: '#000c',
					}}
				>
					<NewTabIcon style={{ transform: 'scale(0.9)' }} />
				</IconButton>
				<IconButton
					onClick={() => setIsFs(!isFs)}
					style={{
						position: 'absolute',
						top: '2.1rem',
						right: '0.0rem',
						width: '2.5rem',
						height: '2.5rem',
						color: '#000c',
					}}
				>
					{isFs ? <FsExitIcon style={{ transform: 'scale(1.2)' }} /> : <FsIcon style={{ transform: 'scale(1.2)' }} />}
				</IconButton>

				<iframe
					title={`External Control-Panel ${props.ecp.externalcontrolpanelid}`}
					src={ecpSrc}
					sandbox='allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'
					style={{
						width: '100%',
						height: isFs ? '100%' : isAbelko ? '17rem' : '40rem',
						boxShadow: '0 0 0.5rem #0003',
						borderRadius: '0.3rem',
					}}
					frameBorder='0'
					allowFullScreen
				></iframe>
			</FullScreen>
		</div>
	);
}

export default ExternalControlPanel;
