import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getStateVariables, STORE } from '../redux/selectors';
import Table from '../components/MaterialTableWrapper';
import * as colors from '../colors';

const ADD_IMPORTEDREGISTERS = gql`
	mutation ($externalcontrolpanelid: ID!, $registers: [ImportedRegisterReg!]!) {
		addImportedRegisters(externalcontrolpanelid: $externalcontrolpanelid, registers: $registers) {
			address
		}
	}
`;
const SET_IMPORTEDREGISTERS = gql`
	mutation ($externalcontrolpanelids: [ID!]!, $registers: [ImportedRegisterMutable!]!) {
		setImportedRegisters(externalcontrolpanelids: $externalcontrolpanelids, registers: $registers) {
			externalcontrolpanelid
			address
			read
			write
		}
	}
`;

const ADD_SENSOR = gql`
	mutation ($name: String, $locationid: Int, $sensorref: String) {
		addSensor(name: $name, locationid: $locationid, sensorref: $sensorref) {
			sensorref
			name
			locationid
		}
	}
`;

const SET_SENSORS = gql`
	mutation (
		$sensorref: String
		$sensorids: [ID]!
		$name: String
		$locationid: Int
		$sensorgroupid: Int
		$classification: String
		$unit: String
		$multiplier: Float
		$alarmgraceminutes: Int
	) {
		setSensors(
			sensorref: $sensorref
			sensorids: $sensorids
			name: $name
			locationid: $locationid
			sensorgroupid: $sensorgroupid
			classification: $classification
			unit: $unit
			multiplier: $multiplier
			alarmgraceminutes: $alarmgraceminutes
		) {
			sensorref
			sensorid
			name
			locationid
			sensorgroupid
			classification
			unit
			multiplier
			alarmgraceminutes
		}
	}
`;

/**
 * A table of imported registers with options for editing/adding/viewing.
 * @param {'add' | 'edit'} type : Adjusts the functionality/purpose of the component
 * @param {number} ecpId : Id of ECP to which registers belong
 * @param {object[]} data : Imported registers
 * @param {boolean} canSave : Override save-button state
 * @param {() => void} onSave : Called when the save-button is pressed
 * @param {({}) => void} onNewData : Called with new data when it arrives
 * @param {CSS} style : Applied to surrounding container
 */
function ImportedRegisterList(_props) {
	const props = {
		..._props,
		data: _props.data || [],
		onSave: typeof _props.onSave === 'function' ? _props.onSave : () => null,
		onNewData: typeof _props.onNewData === 'function' ? _props.onNewData : () => null,
		disableButton: _props.disableButton ?? false,
	};
	//update the sensors data
	/* const updateSensors = () => {
		window.location.reload();
	}; */

	const [addImportedRegisters] = useMutation(ADD_IMPORTEDREGISTERS, {
		onError: () => console.error('Failed to add registers. Likely because some or all of them already exist in the database.'),
	});
	const [setImportedRegisters] = useMutation(SET_IMPORTEDREGISTERS, {
		onCompleted: ({ setImportedRegisters }) => props.onNewData(setImportedRegisters),
		//update: updateSensors,
		onError: () => console.error('Failed to set registers.'),
	});

	const [addSensor] = useMutation(ADD_SENSOR, {
		onError: () => console.error('Failed to add sensor.'),
	});
	const { t } = useTranslation();

	const [setSensors] = useMutation(SET_SENSORS, {
		onError: () => null,
	});

	return (
		<div style={props.style}>
			<Table
				title={ `${ props.showTitle === false ? 'Choose Curve X1,..Xn || Y1,..Yn' : props.type === 'add' ? t('importedRegisterList.registerToImport') : t('importedRegisterList.editRegister')}${props.selectedReadRegs.length || props.selectedWriteRegs.length
					? ` (${props.selectedReadRegs.length + props.selectedWriteRegs.length} valda)`
					: ''
					}`}
				columns={[
					{ title: t('generic.object'), field: 'objectname' },
					{ title: t('importedRegisterList.group'), field: 'groupname' },
					{ title: t('importedRegisterList.parameter'), field: 'parametername' },
				]}
				actions={[
					row => ({ icon: () => <div />, disabled: true }), // Only for left-padding
					row => {
						const isDisabled = !row.canread;
						const isChecked = props.selectedReadRegs.includes(row.address);
						return {
							icon: () => (
								<Checkbox
									disabled={isDisabled}
									checked={isChecked}
									onChange={() =>
										isChecked
											? props.setSelectedReadRegs(props.selectedReadRegs.filter(add => add !== row.address))
											: props.setSelectedReadRegs([...props.selectedReadRegs, row.address])
									}
									style={{ padding: '0', color: colors.primary}}
								/>
							),
							disabled: isDisabled,
						};
					},
					row => {
						const isDisabled = !row.canwrite;
						const isChecked = props.selectedWriteRegs.includes(row.address);
						return {
							icon: () => (
								<Checkbox
									disabled={isDisabled}
									checked={isChecked}
									onChange={() =>
										isChecked
											? props.setSelectedWriteRegs(props.selectedWriteRegs.filter(add => add !== row.address))
											: props.setSelectedWriteRegs([...props.selectedWriteRegs, row.address])
									}
									style={{ padding: '0', color: colors.primary }}
								/>
							),
							disabled: isDisabled,
						};
					},
				]}
				data={props.data.sort((a, b) => (a.address < b.address ? -1 : 1))}
				maxColumnLength={30}
				localization={{ header: { actions: t('importedRegisterList.readWrite') } }}
			/>
			{!props.disableButton && (
				<div style={{ marginTop: '1rem', textAlign: 'right' }}>
					<Button
						variant='outlined'
						disabled={!props.canSave}
						onClick={() => {
							props.type === 'add'
								? addImportedRegisters({
									variables: {
										externalcontrolpanelid: props.ecpId,
										registers: props.data.map(she => ({
											objectname: she.objectname,
											groupname: she.groupname,
											parametername: she.parametername,
											address: she.address,
											functions: she.functions,
											canread: she.canread,
											canwrite: she.canwrite,
											read: props.selectedReadRegs.includes(she.address),
											write: props.selectedWriteRegs.includes(she.address),
											automation:false,
										})),
									},
								}).then(result => {
									if (result.data) {
										for (const she of props.data) {
											if (props.selectedReadRegs.includes(she.address)) {
												const locationId = Number(
													props.externalControlPanels.find(ecp => ecp.externalcontrolpanelid === props.ecpId)?.locationid
												);
												const sensorRef = `ducid | ${props.ecpId} | ${she.address}`;

												try {
													addSensor({
														variables: {
															name: she.groupname,
															locationid: locationId,
															sensorref: sensorRef,
														},
													});
												} catch (error) {
													console.error('Error adding sensor:', error);
												}
											}
										}
									}
								}).catch(() => {
									// Do nothing
									
								})

								: setImportedRegisters({
									variables: {
										externalcontrolpanelids: [props.ecpId],
										registers: props.data.map(reg => ({
											address: reg.address,
											read: props.selectedReadRegs.includes(reg.address),
											write: props.selectedWriteRegs.includes(reg.address),
										})),
									},
								}).then(result => {
									if (result.data) {
										props.data.forEach(async (reg) => {
											if (reg.read) {
												const locationId = Number(
													props.externalControlPanels.find(ecp => ecp.externalcontrolpanelid === props.ecpId)?.locationid
												);
												const sensorRef = `ducid | ${props.ecpId} | ${reg.address}`;

												try {
													await addSensor({
														variables: {
															name: reg.groupname,
															locationid: locationId,
															sensorref: sensorRef,
														},
													});
												} catch (error) {
													console.error('Error adding sensor:', error);
												}
											} else {
												const sensorRef = `ducid | ${props.ecpId} | ${reg.address}`;

												const sensor = props.sensors.find(sen => sen.sensorref === sensorRef);

												if (sensor) {
													const date = new Date();
													const timestamp = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
													try {
														await setSensors({
															variables: {
																sensorref: sensorRef + '_removed_' + props.userInfo.username + timestamp,
																sensorids: [sensor.sensorid],
																// name: props.userInfo.username || 'unknown',
																locationid: null,
																sensorgroupid: null,
															},
														});
													} catch (error) {
														console.error('Error setting sensors:', error);
													}
												}
											}
										});
									}
								});
							props.onSave();
							const locationid = Number(
								props.externalControlPanels.find(ecp => ecp.externalcontrolpanelid === props.ecpId)?.locationid
							);
							if (!isNaN(locationid)) props.callbacks['on-update-importedregisters'].forEach(cb => cb.callback(locationid));
						}}
						style={{ background: '#fff', color: colors.primary}}
					>
						{props.type === 'add' ? t('importedRegisterList.importRegister') : t('importedRegisterList.saveChanges')}
					</Button>
				</div>
			)}
		</div>
	);
}

export default connect(getStateVariables(STORE.externalControlPanels, STORE.callbacks, STORE.sensorGroups, STORE.sensors, STORE.userInfo))(ImportedRegisterList);
