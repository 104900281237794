import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { STORE, getStateVariables } from '../redux/selectors';
import { setPage, updateUserInfo } from '../redux/actionCreators';
import { PAGES } from '../constants';
import MaterialTable from '../components/MaterialTableWrapper';
import { Button, Dialog, LinearProgress, makeStyles } from '@material-ui/core';
import { Add, Delete, Email, Unsubscribe } from '@material-ui/icons';

import CreatePdfWizard from '../components/reports/CreatePdfWizard';
import CreateCsvWizard from '../components/reports/CreateCsvWizard';
import { useMutation, useQuery } from '@apollo/client';
import {
	MUTATION_DELETE_REPORT,
	MUTATION_SET_KC_OPTIONS,
	MUTATION_SET_KC_PROPERTIES,
	MUTATION_SET_KC_RECIPIENTS,
	MUTATION_SET_KC_SENSORS,
	MUTATION_SET_REPORT,
	QUERY_REPORTS,
} from '../utility-functions/gqlQueries';
import DeleteReportPopup from '../components/reports/DeleteReportPopup';
import { useTranslation } from 'react-i18next';

const PDF_COLOR = '#eb0112';
const PDF_COLOR_DARKER = '#b2000e';
const CSV_COLOR = '#1e6d41';

const useStyles = makeStyles(theme => ({
	tableTitle: {
		marginTop: '0.2rem',
	},
	pdfButton: {
		margin: '0 0.5rem 0 2rem',
		color: PDF_COLOR,
		borderColor: PDF_COLOR,
		overflow: 'hidden',
		//backgroundColor: PDF_COLOR,
		// fontWeight: 'bold',
		fontSize: '0.8rem',
	},
	csvButton: {
		margin: '0 0 0 0.5rem',
		color: CSV_COLOR,
		borderColor: CSV_COLOR,
		overflow: 'hidden',
		//fontWeight: 'bold',
		fontSize: '0.8rem',

		//backgroundColor: CSV_COLOR,
	},
}));

function debounce(func, wait) {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

function Reports(props) {
	useEffect(() => {
		props.setPage(PAGES.reports.id);
		// eslint-disable-next-line
	}, []);
	const [openCreatePdf, setOpenCreatePdf] = useState(false);
	const [openCreateCsv, setOpenCreateCsv] = useState(false);
	const [openDeleteReport, setOpenDeleteReport] = useState(false);

	const [selectedReport, setSelectedReport] = useState({});
	const [reports, setReports] = useState([]);

	// ---------------------------------------------------
	//       CONVERTING OLD REPORTS TO NEW REPORTS
	// ---------------------------------------------------

	const [setReportRecipients] = useMutation(MUTATION_SET_KC_RECIPIENTS, {
		onCompleted: ({ addOrRemoveKeycloakUserAttributes }) => props.updateUserInfo(addOrRemoveKeycloakUserAttributes),
	});
	const [setReportOptions] = useMutation(MUTATION_SET_KC_OPTIONS, {
		onCompleted: ({ setKeycloakUsers }) => {
			props.callbacks['on-update-report-options'].forEach(cb => cb.callback());
			props.updateUserInfo(setKeycloakUsers[0]);
		},
	});
	const [setReportSensors] = useMutation(MUTATION_SET_KC_SENSORS, {
		onCompleted: ({ setKeycloakUsers }) => {
			props.callbacks['on-update-report-options'].forEach(cb => cb.callback());
			props.updateUserInfo(setKeycloakUsers[0]);
		},
	});

	const [setReportProperties] = useMutation(MUTATION_SET_KC_PROPERTIES, {
		onCompleted: ({ setKeycloakUsers }) => {
			props.callbacks['on-update-report-options'].forEach(cb => cb.callback());
			props.updateUserInfo(setKeycloakUsers[0]);
		},
	});

	const removeKeycloakReport = () => {
		setReportSensors({
			variables: {
				ids: [props.userInfo?.id],
				reportSensors: [],
			},
		});
		setReportOptions({
			variables: {
				ids: [props.userInfo?.id],
				reportOptions: [],
			},
		});
		setReportProperties({
			variables: {
				ids: [props.userInfo?.id],
				reportProperties: [],
			},
		});
		setReportRecipients({
			variables: {
				id: props.userInfo?.id,
				add: false,
				reportRecipients: props.userInfo?.reportRecipients,
			},
		});
	};

	// To prevent multiple calls to the same function
	const [keyCloakUserSaved, setKeyCloakUserSaved] = useState(false);

	useEffect(() => {
		if (!keyCloakUserSaved && props.userInfo.id && props.userInfo.reportRecipients) {
			const { reportRecipients, reportOptions, reportSensors, reportProperties, customerName } = props.userInfo;
			const newReport = {
				reportname: customerName || 'meliox',
				reporttype: 'PDF',
				reportdata: {
					properties: reportProperties || [],
					pages: reportOptions || ['DRAW_OVERVIEW_PAGE'],
					sensors: reportSensors || [],
				},
				shoulddeliver: reportRecipients?.length ? true : false,
				recipients:
					reportRecipients?.map(recipient => {
						const [email, interval] = recipient.split(';');
						return {
							email,
							intervals: [interval],
						};
					}) || [],
			};
			handleSaveReport({ newReport });
			removeKeycloakReport();
			setKeyCloakUserSaved(true);
		}
	}, [keyCloakUserSaved, props.userInfo]);

	// ---------------------------------------------------

	const { t } = useTranslation();

	const handleSafeClose = () => {
		setSelectedReport({});
		setOpenCreatePdf(false);
		setOpenCreateCsv(false);
		setOpenDeleteReport(false);
	};

	const [deleteReport] = useMutation(MUTATION_DELETE_REPORT, {
		onCompleted: ({ deleteReport: deletedId }) => {
			if (deletedId === -1) return handleSafeClose();
			const newReports = reports.filter(report => report.reportid !== deletedId);
			setReports(newReports);
			handleSafeClose();
		},
	});

	const [updateReport] = useMutation(MUTATION_SET_REPORT, {
		onCompleted: ({ setReport: data }) => {
			const { reportid, reportname, reporttype, shoulddeliver, recipients, properties, pages, lastedit, sensors } = data;
			const alteredReport = reports.find(report => report.reportid === reportid) || {};
			const newReports = reports.filter(report => report.reportid !== reportid);
			// Use old value if new values are not sent back (no change was made)
			setReports(
				[
					{
						...alteredReport,
						...(reportid == null ? {} : { reportid }),
						...(reportname == null ? {} : { reportname }),
						...(reporttype == null ? {} : { reporttype }),
						...(shoulddeliver == null ? {} : { shoulddeliver }),
						...(recipients == null
							? {}
							: {
									recipients: recipients.map(({ email, intervals }) => ({
										email,
										intervals,
									})),
								}),
						...(lastedit == null ? {} : { lastedit }),
						reportdata: {
							...alteredReport.reportdata,
							...(properties == null ? {} : { properties }),
							...(pages == null ? {} : { pages }),
							...(sensors == null ? {} : { sensors }),
						},
					},
					...newReports,
				].sort((a, b) => new Date(b.lastedit) - new Date(a.lastedit))
			);
			handleSafeClose();
		},
	});
	const { loading: reportLoading } = useQuery(QUERY_REPORTS, {
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			const reports = data.getReports.map(report => {
				const { reportid, reportname, reporttype, shoulddeliver, recipients, lastedit } = report;
				return {
					reportid,
					reportname,
					reporttype,
					shoulddeliver,
					recipients: recipients.map(({ email, intervals }) => ({
						email,
						intervals,
					})),
					lastedit,
					reportdata: { properties: report.properties, pages: report.pages, sensors: report.sensors },
				};
			});
			setReports(reports.sort((a, b) => new Date(b.lastedit) - new Date(a.lastedit)));
		},
	});
	const handleSaveReport = ({ newReport, reportid }) => {
		if (newReport === undefined) {
			return handleSafeClose();
		}

		updateReport({
			variables: {
				reportFilter: {
					reportid: reportid,
					reportname: newReport.reportname,
					reporttype: newReport.reporttype,
					properties: newReport.reportdata.properties,
					pages: newReport.reportdata.pages,
					sensors: newReport.reportdata.sensors,
					shoulddeliver: newReport.shoulddeliver,
					recipients: newReport.recipients.map(({ email, intervals }) => ({
						email,
						intervals,
					})),
				},
			},
		});
	};

	const updateReportDebounce = debounce(updateReport, 1000);

	const handleDeleteReport = report => {
		setSelectedReport(report);
		setOpenDeleteReport(true);
	};

	const handleLoadReport = editReport => {
		setSelectedReport(editReport);
		if (editReport.reporttype === 'PDF') {
			setOpenCreatePdf(true);
		} else {
			setOpenCreateCsv(true);
		}
	};

	const classes = useStyles();
	return (
		<>
			<div style={{ left: '20rem', position: 'fixed', width: 'calc(100% - 24rem)' }}>
				<div style={{ display: 'flex', margin: '5rem 0 0 0' }}>
					<MaterialTable
						title={
							<div style={{ display: 'flex' }}>
								<div className={classes.tableTitle}>{t('reports.reportsTableTitle')}</div>
								<Button
									startIcon={<Add />}
									onClick={() => setOpenCreatePdf(true)}
									variant='outlined'
									className={classes.pdfButton}
								>
									{t('reports.createPdfButton')}
								</Button>
								<Button
									startIcon={<Add />}
									variant='outlined'
									className={classes.csvButton}
									onClick={() => setOpenCreateCsv(true)}
								>
									{t('reports.createCsvButton')}
								</Button>
							</div>
						}
						data={reports?.map(report => ({
							...report,
							recipients: report.recipients.map(({ email }) => email).join(', '),
						}))}
						columns={[
							{ title: t('generic.reportName'), field: 'reportname', width: '35%', maxLength: 48 },
							{ title: t('generic.type'), field: 'reporttype', width: '15%', maxLength: 5 },
							{ title: t('generic.recipients'), field: 'recipients', width: '45%', maxLength: 48 },
						]}
						style={{
							width: '100%',
						}}
						options={{
							actionsColumnIndex: -1,
							maxBodyHeight: 'calc(100vh - 19rem)',
							minBodyHeight: 'calc(100vh - 19rem)',
							pageSize: 10,
						}}
						actions={[
							rowData => ({
								icon: rowData.shoulddeliver ? Email : Unsubscribe,
								tooltip: rowData.shoulddeliver ? t('reports.turnOffDeliver') : t('reports.turnOnDeliver'),
								onClick: (event, rowData) => {
									if (!rowData.recipients?.length && !rowData.shoulddeliver) {
										return;
									}
									updateReportDebounce({
										variables: {
											reportFilter: {
												reportid: rowData.reportid,
												shoulddeliver: !rowData.shoulddeliver,
											},
										},
									});
								},
							}),
							{
								icon: Delete,
								onClick: (event, rowData) => {
									handleDeleteReport(reports.find(report => report.reportid === rowData.reportid));
								},
								tooltip: t('automations.delete'),
							},
						]}
						localization={{ header: { actions: '' } }}
						onRowClick={(event, rowData) => {
							handleLoadReport(reports.find(report => report.reportid === rowData.reportid));
						}}
					/>
				</div>
				{reportLoading ? <LinearProgress style={{ zIndex: 2, width: '100%' }} /> : undefined}
			</div>
			<Dialog open={openCreatePdf} onClose={() => handleSafeClose()} PaperProps={{ style: { minWidth: '90%' } }}>
				<CreatePdfWizard
					handleSave={value => handleSaveReport(value)}
					loadReport={selectedReport}
					handleClose={() => handleSafeClose()}
					userInfo={props.userInfo}
				/>
			</Dialog>
			<Dialog open={openCreateCsv} onClose={() => handleSafeClose()} PaperProps={{ style: { minWidth: '90%' } }}>
				<CreateCsvWizard
					loadReport={selectedReport}
					handleSave={value => handleSaveReport(value)}
					handleClose={() => handleSafeClose()}
					userInfo={props.userInfo}
				/>
			</Dialog>
			{/* PaperProps={{ style: { minWidth: '90%' } }} */}
			<Dialog open={openDeleteReport} onClose={() => handleSafeClose()}>
				<DeleteReportPopup
					reportName={selectedReport.reportname}
					onClose={() => handleSafeClose()}
					onDelete={() => {
						if (selectedReport.reportid) {
							deleteReport({ variables: { reportid: selectedReport.reportid } });
						} else {
							handleSafeClose();
						}
					}}
				/>
			</Dialog>
		</>
	);
}

export default connect(getStateVariables(STORE.userInfo, STORE.sensors, STORE.callbacks), { setPage, updateUserInfo })(Reports);
