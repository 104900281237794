import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, Paper, Tabs, Tab, makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { t } from 'i18next';
import SelectionTable from '../../SelectionTable';
import i18n from '../../../i18n';

const GET_PROPERTIES = gql`
	query {
		getSensorLocations {
			locationid
			city
			street
			cadastral
			area
		}
	}
`;

const CONTAINER_SHADOW = '0 0.1rem 0.5rem #0005';

const TABS = {
	cities: { label: i18n.t('generic.city_other'), value: 'cities' },
	properties: { label: i18n.t('constants.properties'), value: 'properties' },
};

export function PropertySelectorTable({ selectedProperties, onDataSelectionCallback, closeCallback, widgets }) {
	const [selections, setSelections] = useState(selectedProperties);
	const [selectionCounts, setSelectionCounts] = useState({});
	const [selectedTabId, setSelectedTabId] = useState(TABS.cities.value);
	const [cities, setCities] = useState([]);
	const { loading, data } = useQuery(GET_PROPERTIES, {
		fetchPolicy: 'network-only',
	});
	const properties = !loading && data ? data.getSensorLocations : [];

	const sensorsInUse = widgets.reduce((agg, { sensors }) => [...agg, ...sensors], []);
	const locationsInUse = [...new Set(sensorsInUse.map(({ locationid }) => locationid))];
	const citiesInUse = [...new Set(selections.filter(({ locationid }) => locationsInUse.includes(locationid)).map(({ city }) => city))];

	useEffect(() => {
		if (data) {
			const cities = [];
			data.getSensorLocations.forEach(prop => {
				const city = cities.find(cit => cit.city === prop.city);
				if (city) city.properties.push(prop);
				else cities.push({ ...prop, properties: [prop] });
			});
			setCities(cities);
		}
	}, [data]);

	useEffect(() => {
		setSelectionCounts({
			[TABS.cities.value]: [...new Set(selections.map(sel => sel.city))].length,
			[TABS.properties.value]: [...new Set(selections.map(sel => sel.locationid))].length,
		});
	}, [selections]);

	const handleDataSelection = () => {
		onDataSelectionCallback(selections);
		closeCallback(true);
	};

	function updateSelection(diff, type) {
		let sel = [...selections];
		let removeFun = () => true;
		let addFun = () => false;
		let removedDiff = diff.removed.filter(({ locationid }) => !locationsInUse.includes(locationid));
		if (type === TABS.cities.value) {
			removedDiff = diff.removed.filter(({ city }) => !citiesInUse.includes(city));
			removeFun = sel => !removedDiff.map(cit => cit.city).includes(sel.city);
			addFun = sen => diff.added.map(cit => cit.city).includes(sen.city);
		} else if (type === TABS.properties.value) {
			removeFun = sel => !removedDiff.map(pro => pro.locationid).includes(sel.locationid);
			addFun = sen => diff.added.map(pro => pro.locationid).includes(sen.locationid);
		}

		if (diff.removed.length) sel = sel.filter(removeFun);
		if (diff.added.length)
			sel = sel.concat(
				properties.filter(addFun).map(sen => ({
					city: sen.city,
					locationid: sen.locationid,
					street: sen.street,
				}))
			);
		setSelections(sel);
	}

	const cityTableProps = {
		data: cities.map(cit => ({
			...cit,
			propertyCount: cit.properties.length,
		})),
		dataId: 'city',
		selectedIds: selections.map(sel => sel.city),
		disabledIds: citiesInUse,
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{ title: t('constants.properties'), field: 'propertyCount' },
		],
	};

	const propTableProps = {
		data: properties.map(pro => ({
			...pro,
		})),
		dataId: 'locationid',
		selectedIds: selections.map(sel => sel.locationid),
		disabledIds: locationsInUse,
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{ title: t('generic.address'), field: 'street', maxLength: 64 },
		],
	};

	return (
		<>
			<div style={{ display: 'grid' }}>
				{locationsInUse.length ? (
					<MuiAlert style={{ marginBottom: '10px' }} elevation={6} severity='info'>
						{t('charts.locationsInUse')}
					</MuiAlert>
				) : undefined}
				<div style={{ display: 'flex' }}>
					<SelectionTable
						{...(selectedTabId === TABS.cities.value ? cityTableProps : propTableProps)}
						localization={{ title: '' }}
						pageSizeOptions={[10, 15, 20]}
						showPagination
						onSelectionChangeDiff={diff => updateSelection(diff, selectedTabId)}
						style={{
							minWidth: '40rem',
							maxWidth: '40rem',
							minHeight: '29rem',
							overflow: 'hidden',
							boxShadow: CONTAINER_SHADOW,
						}}
						tableProps={{
							maxColumnLength: 25,
							toolbarHeight: '48px',
							options: { pageSize: 10 },
						}}
					/>
					<div style={{ marginLeft: '1rem', position: 'relative' }}>
						<Paper style={{ boxShadow: CONTAINER_SHADOW }}>
							<Tabs
								orientation='vertical'
								indicatorColor='primary'
								value={selectedTabId}
								onChange={(e, i) => setSelectedTabId(i)}
								classes={makeStyles({
									indicator: {
										width: '6px',
										height: '38px !important',
										margin: '5px 0 5px 0',
										borderRadius: '0 9px 9px 0',
										right: 'unset',
									},
								})()}
							>
								{Object.values(TABS).map(tab => (
									<Tab {...tab} label={`${tab.label} (${selectionCounts[tab.value] || 0})`} key={tab.value} />
								))}
							</Tabs>
						</Paper>
						<Paper
							style={{
								width: '100%',
								position: 'absolute',
								bottom: '0',
								padding: '0.6rem',
								display: 'grid',
								gap: '0.5rem',
								boxShadow: CONTAINER_SHADOW,
							}}
						>
							<Button
								disabled={!Boolean(selectedProperties.length)}
								onClick={() => closeCallback(false)}
								style={{ color: '#0008', boxShadow: '0 1px 0.2rem #0004' }}
							>
								{t('generic.cancel')}
							</Button>
							<Button
								disabled={!selections.length}
								onClick={handleDataSelection}
								color='primary'
								style={{ boxShadow: '0 1px 0.2rem #0004' }}
							>
								{t('generic.save')}
							</Button>
						</Paper>
					</div>
				</div>
			</div>
		</>
	);
}

export { GET_PROPERTIES };
